import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b7fddb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "modalContainerOuterElement",
  class: "modal-container-outer-element w-100 bg-transparent tw-flex tw-items-center"
}
const _hoisted_2 = { class: "tw-h-full tw-relative" }
const _hoisted_3 = {
  class: "tw-h-full",
  style: {"overflow-y":"auto"}
}
const _hoisted_4 = {
  key: 0,
  class: "tw-px-6 tw-pb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "modal",
    "after-leave": _ctx.afterLeave
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "modal-mask",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMaskClick && _ctx.handleMaskClick(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            ref: "modalContainerInnerElement",
            class: "w-100 modal-container-inner-element box",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_modal_header, {
                        title: _ctx.title,
                        "show-close-button": _ctx.showCloseButton,
                        "show-border": _ctx.showHeaderBorder,
                        onClose: _ctx.close
                      }, null, 8, ["title", "show-close-button", "show-border", "onClose"])
                    ]))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ])
          ], 512)
        ], 512)
      ], 512), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 3
  }, 8, ["after-leave"]))
}