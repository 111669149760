import {
  DataForCircleTransactionsView,
  DataForUserCirclesView,
  FiltersForUserCirclesView,
  State,
  FinalMemberCircle,
  FiltersForCircleTransactionsView,
} from "@/store/modules/circles/types/state";
import {Beneficiary} from "@/store/modules/circles/types/models";

export enum MutationTypes {
  RESET_ALL_CIRCLES_STATE = "RESET_ALL_CIRCLES_STATE",
  SET_DATA_FOR_USER_CIRCLES_VIEW = "SET_DATA_FOR_USER_CIRCLES_VIEW",
  CLEAR_DATA_FOR_USER_CIRCLES_VIEW =
    "CLEAR_DATA_FOR_USER_CIRCLES_VIEW",
  SET_USER_CIRCLE = "SET_USER_CIRCLE",
  REMOVE_USER_CIRCLE = "REMOVE_USER_CIRCLE",
  SET_CIRCLE_MEMBERS_COUNT = "SET_CIRCLE_MEMBERS_COUNT",
  SET_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW =
    "SET_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW",
  CLEAR_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW  =
    "CLEAR_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW",
  SET_CIRCLE_MEMBERS = "SET_CIRCLE_MEMBERS",
  CLEAR_CIRCLE_MEMBERS = "CLEAR_CIRCLE_MEMBERS",
  SET_CIRCLE_MEMBER = "SET_CIRCLE_MEMBER",
  SET_BENEFICIARY = "SET_BENEFICIARY",
  SET_BENEFICIARIES = "SET_BENEFICIARIES",
  CLEAR_BENEFICIARIES = "CLEAR_BENEFICIARIES",
  SET_CIRCLE_STATS = "SET_CIRCLE_STATS",
}

export type Mutations<S = State> = {

  [MutationTypes.RESET_ALL_CIRCLES_STATE]:
    (state: S, payload: {

      keysNotToReset?: Array<string>

    }) => void;

  [MutationTypes.SET_DATA_FOR_USER_CIRCLES_VIEW]:
    (state: S, payload: {

      data: DataForUserCirclesView;
    }) => void;

  [MutationTypes.CLEAR_DATA_FOR_USER_CIRCLES_VIEW]:
    (state: S, payload: {

      filters: FiltersForUserCirclesView
    }) => void;

  [MutationTypes.SET_USER_CIRCLE]:
    (state: S, payload: {

      data: FinalMemberCircle;
    }) => void;

  [MutationTypes.REMOVE_USER_CIRCLE]:
    (state: S, payload: {

      memberCircle: FinalMemberCircle;
    }) => void;

  [MutationTypes.SET_CIRCLE_MEMBERS_COUNT]:
    (state: S, payload: {

      circleId: string;
      count: number;
    }) => void;

  [MutationTypes.SET_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW]:
    (state: S, payload: {

      data: DataForCircleTransactionsView;
    }) => void;

  [MutationTypes.CLEAR_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW]:
    (state: S, payload: {

      filters: FiltersForCircleTransactionsView
    }) => void;

  [MutationTypes.SET_CIRCLE_MEMBERS]:
    (state: S, payload: {

      circleId: string;
      data: Array<FinalMemberCircle>;
    }) => void;

  [MutationTypes.CLEAR_CIRCLE_MEMBERS]:
    (state: S, payload: {

      circleId: string;
    }) => void;

  [MutationTypes.SET_CIRCLE_MEMBER]:
    (state: S, payload: {

      circleId: string;
      memberCircle: FinalMemberCircle;
    }) => void;
  [MutationTypes.SET_BENEFICIARY]:
    (state: S, payload: {

      beneficiary: Beneficiary;
    }) => void;

  [MutationTypes.SET_BENEFICIARIES]:
    (state: S, payload: {

      circleId: string;
      beneficiaries: Array<Beneficiary>;
    }) => void;

  [MutationTypes.CLEAR_BENEFICIARIES]:
    (state: S, payload: {

      circleId: string;
    }) => void;
}
