

import {defineComponent, onBeforeUnmount, ref} from "vue";
import {useRouter} from "vue-router";
import BaseModal from "@/views/components/BaseModal.vue";
import Confirmation from "@/views/components/Confirmation.vue";
import {ActionTypes} from "@/store/modules/authorization/types";
import {MutationTypes as UsersMutationTypes} from "@/store/modules/users/types";
import {MutationTypes as CirclesMutationsTypes} from "@/store/modules/circles/types/mutations";
import emitter from "@/event-bus/eventBus";
import {useStore} from "@/hooks/useStore";
import authService from "@/services/authorization/AuthService";

export default defineComponent({

  components: {Confirmation, BaseModal},

  setup() {

    const store = useStore();
    const router = useRouter();
    const showModal = ref(false);

    function close() {

      showModal.value = false;
    }

    function logout() {

      store.dispatch(ActionTypes.LOGOUT_USER);

      showModal.value = false;

      const currentRoute = router.currentRoute.value.fullPath;

      localStorage.setItem('previousRoute', currentRoute);

      store.commit(UsersMutationTypes.RESET_USERS_STATE);
      store.commit(CirclesMutationsTypes.RESET_ALL_CIRCLES_STATE);

      authService.singleton().userLogout('');
    }

    function toggleModal(show = true) {

      showModal.value = show;
    }

    emitter.on('logout', toggleModal);

    onBeforeUnmount(() => {

      emitter.off('logout', toggleModal);
    });

    return {

      close,
      logout,
      showModal
    }
  }
});

