
import {computed, defineComponent, PropType} from "vue";
import {LastExpenseClaim} from "@/store/modules/last-expense/types/models";
import {useRouter} from "vue-router";
import ChevronRight from "@/icons/chevron-right.vue";
import {
    useLastExpenseClaimsUtilities
} from "@/views/pages/insurances/last-expense/claims/useLastExpenseClaimsUtilities";

export default defineComponent({
  components: {ChevronRight},
  props: {

    claim: {

      type: Object as PropType<LastExpenseClaim>,
      required: true
    },
    isUserDashboard: {

      type: Boolean as PropType<boolean>,
      required: true
    }
  },

  setup(props) {

    const router = useRouter();
    const { formatClaim } = useLastExpenseClaimsUtilities();

    const formattedClaim = computed(() => {

      return formatClaim(props.claim);
    });

    function viewRecord() {

      if(props.claim.lastExpenseClaimId) {

        router.push({
          name: 'last-expense-claim',
          params: {claimId: props.claim.lastExpenseClaimId}
        });
      }
    }

    return {

      viewRecord,
      formattedClaim,
    };
  }
});
