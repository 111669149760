
import {defineComponent, PropType} from "vue";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";

export default defineComponent({

  props: {

    premium: {

      type: Number as PropType<number>,
      required: true
    },
    benefit: {

      type: Number as PropType<number>,
      required: true
    }
  },

  setup() {

    const { formatCurrency } = useCurrencyUtilities();

    return {

      formatCurrency
    }
  }
});
