

import {computed, defineComponent, PropType} from "vue";
import {
  LastExpensePlan,
  LastExpensePolicy,
  LastExpensePolicyPayment,
  PolicyPaymentStatus
} from "@/store/modules/last-expense/types/models";
import CheckCircle from "@/icons/solid/CheckCircle.vue";

export default defineComponent({
  components: {CheckCircle},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    },
    payments: {

      type: Array as PropType<Array<LastExpensePolicyPayment>>,
      required: true
    }
  },

  setup(props) {

    const remainingWaitingDays = computed(() => {

      const policyPayments = props.payments.slice();

      policyPayments.sort((a, b) =>
          b.createdOn.getTime() - a.createdOn.getTime()
      );

      const lastActivePayment = policyPayments.find(payment =>
          payment.policyPaymentStatus === PolicyPaymentStatus.RECEIVED
      );

      if(! lastActivePayment) {

        return 90;
      }

      const oneDay = 24 * 60 * 60 * 1000;
      const days = ((new Date().getTime()) - lastActivePayment.createdOn.getTime()) / oneDay;
      return (90 - Math.ceil(days));
    });

    const isMultiplePackagePlan = computed(() => {

      return checkIfMultiplePackagePlan(props.policy.lastExpensePlan);
    });

    const isInWaitingPeriod = computed(() => {

      return remainingWaitingDays.value > 0;
    });

    function checkIfMultiplePackagePlan(plan: LastExpensePlan) {

      let isMultiplePackagePlan = false;
      plan.name.split(" ").forEach(word => {

        if (word.toLowerCase() === 'multiple') {

          isMultiplePackagePlan = true;
        }
      });

      return isMultiplePackagePlan;
    }

    return {

      isInWaitingPeriod,
      remainingWaitingDays,
      isMultiplePackagePlan
    }
  }
});

