import { MutationTree } from "vuex";
import { Mutations, State } from "@/store/modules/users/types";
import {useObjectUtilities} from "@/hooks/useObjectUtilities";
import {state as initialState} from "@/store/modules/users/state";
import {storeUtilities} from "@/store/storeUtilities";

const { updateOrCreateRecord } = useObjectUtilities();
const { refreshState } = storeUtilities();

export const mutations: MutationTree<State> & Mutations = {

    RESET_USERS_STATE: (state, payload = {}) => {

        const keysNotToReset = payload.keysNotToReset ? payload.keysNotToReset : [];

        refreshState(state, initialState(), keysNotToReset);
    },
    SET_USER_INFO: (state, userInfo) => state.userInfo = userInfo,
    SET_USER_WALLET_INFO: (state, walletInfo) => state.userWalletInfo = walletInfo,
    CLEAR_USER_WALLET_INFO: (state) => state.userWalletInfo = null,
    SET_USER_PAYMENT_CARDS: (state, payload) => state.userPaymentCards = payload.data,
    CLEAR_USER_PAYMENT_CARDS: (state) => state.userPaymentCards = null,
    SET_USER_SUPPORTED_BANKS: (state, payload) => state.supportedBanks = payload.data,
    SET_USER_BANK_ACCOUNTS: (state, payload) => state.userBankAccounts = payload.data,
    CLEAR_USER_BANK_ACCOUNTS: (state) => state.userBankAccounts = null,
    SET_DATA_FOR_USER_TRANSACTIONS_VIEW: (state, payload) => {

        payload.data.items.forEach(item => {

            updateOrCreateRecord(
                item, state.transactions,
                (recordA, recordB) =>
                    recordA.transactionRef === recordB.transactionRef
            )
        });

        const record = Object.assign(payload.data, {
            items: payload.data.items.map(item => item.transactionRef)
        });

        updateOrCreateRecord(
            record, state.dataForUserTransactionsView,
            () => true
        );
    },

    CLEAR_DATA_FOR_USER_TRANSACTIONS_VIEW: (state) => {

        state.dataForUserTransactionsView = [];
    },
};