import {useDateUtilities} from "@/hooks/useDateUtilities";
import {
    AccountStatus,
    AccountType,
    Authority,
    CardStatus,
    CardType,
    PaymentCard,
    UserInfo,
    WalletInfo
} from "@/store/modules/users/types";

const { parseServerDateTime, stripTimezoneFromISODateTime } = useDateUtilities();

export type RawWalletInfo = Omit<WalletInfo,
    'accountStatus' | 'accountType' | 'actualBalance' |
    'availableBalance' | 'createdOn' | 'updatedOn'> & {

    accountStatus: string;
    accountType: string;
    actualBalance: string;
    availableBalance: string;
    createdOn: string;
    updatedOn: string;
}

export type RawUserInfo = Omit<UserInfo, 'authorities' | 'createdOn' | 'updatedOn'> & {

    authorities: Array<string>;
    createdOn: string;
    updatedOn: string;
}
export type RawPaymentCard = Omit<PaymentCard,
    'cardStatus' | 'cardType' | 'createdOn' | 'updatedOn' | 'user'> & {

    cardStatus: string;
    cardType: string;
    createdOn: string;
    updatedOn: string;
    user: RawUserInfo
}

export const utilities = () => {

    const mapToTypedUserInfo = (userInfo: RawUserInfo): UserInfo => {

        const {

            authorities, createdOn, updatedOn, ...otherProps

        } = userInfo;

        return {
            authorities: authorities.map(authority => authority as Authority),
            createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
            updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
            ...otherProps
        }
    }

    const mapToTypedWalletInfo = (walletInfo: RawWalletInfo): WalletInfo => {

        const {

            availableBalance, actualBalance, accountStatus, accountType,
            createdOn, updatedOn, ...otherProps

        } = walletInfo;

        return {
            accountStatus: accountStatus as AccountStatus,
            accountType: accountType as AccountType,
            availableBalance: parseInt(availableBalance + "", 10),
            actualBalance: parseInt(actualBalance + "", 10),
            createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
            updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
            ...otherProps
        }
    }

    const mapToTypedPaymentCard = (paymentCard: RawPaymentCard): PaymentCard => {

        const {

            cardStatus, cardType, createdOn, updatedOn, user, ...otherProps

        } = paymentCard;

        return {

            cardStatus: cardStatus as CardStatus,
            cardType: cardType as CardType,
            createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
            updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
            user: mapToTypedUserInfo(user),
            ...otherProps
        }
    }

    return {

        mapToTypedUserInfo,
        mapToTypedWalletInfo,
        mapToTypedPaymentCard
    };
}