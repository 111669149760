import {AxiosResponse} from "axios";

export const storeUtilities = () => {

    const refreshState = (
        state: Record<string, unknown>,
        freshStateData: Record<string, unknown>,
        keysNotToRefresh: Array<string> = []) => {

        Object.entries(freshStateData).forEach(([k, v]) => {

            if(Object.prototype.hasOwnProperty.call(freshStateData, k) &&
                ! keysNotToRefresh.includes(k)) {

                Object.assign(state, {[k]: v});
            }
        });
    }

    const fetchMoreViewData = <T, R>(data: {

        viewData: {

            items: Array<T>,
            nextPage: number,
            totalPages: number,
        } | null,
        defaultPageSize: number,
        mapper: (rawItem: R) => T,
        comparator: (firstItem: T, secondItem: T) => boolean,
        service: (pageToFetch: number) => Promise<AxiosResponse>
    }) => {

        const defaultPageSize = data.defaultPageSize;

        let nextPage = 1;
        let totalPages = 1;
        let existingItems: Array<T> = [];

        if(data.viewData) {

            nextPage = data.viewData.nextPage;
            totalPages = data.viewData.totalPages;
            existingItems = data.viewData.items.slice();
        }

        let fetchedItems: Array<T> = [];

        return (async () => {

            while(fetchedItems.length < defaultPageSize && nextPage <= totalPages) {

                const response = await data.service(nextPage);

                totalPages = response.data.totalPages;

                const items: Array<T> = response.data.items.map(
                    (item: R) => {

                        return data.mapper(item);
                    });

                const totalExistingItems = existingItems ?
                    existingItems.concat(fetchedItems) :
                    fetchedItems;

                for (let i = items.length - 1; i >= 0; i--) {

                    const itemExists = totalExistingItems.find((item: T) => {

                        return data.comparator(items[i], item);
                    });

                    if (itemExists) {

                        items.splice(i, 1);
                    }
                }

                fetchedItems = fetchedItems.concat(items);

                nextPage += 1;

            }

            if(fetchedItems.length > defaultPageSize) {

                fetchedItems = fetchedItems.slice(defaultPageSize - 1);
                nextPage -= 1;
            }

            return {

                items: existingItems.concat(fetchedItems),
                nextPage,
                totalPages
            };

        })();
    };

    return {

        refreshState,
        fetchMoreViewData
    }
}
