
import {defineComponent, PropType} from "vue";

export default defineComponent ({

  props: {
    title: {
      type: String as PropType<string>,
      default: ''
    },
    subtitle: {
      type: String as PropType<string>,
      default: ''
    },
    btnText: {
      type: String as PropType<string>,
      default: 'View'
    },
      icon: {
          type: String as PropType<string>,
          default: 'LifeInsurance'
      },
  }
})
