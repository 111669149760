

import {computed, defineComponent, PropType} from "vue";

export default defineComponent({

  props: {

    id: {

      type: String as PropType<string>,
      default: new Date().getUTCMilliseconds().toString()
    },

    label: {

      type: String as PropType<string>,
      default: ''
    },

    options: {

      type: [Object, Array] as PropType<
          Record<string | number, string | number> | Array<string | number>>,
      required: true
    },

    modelValue: {

      type: [Number, String] as PropType<number | string>,
    },

    inputClasses: {

      type: Array as PropType<Array<string>>,
      default: () => []
    }
  },

  setup(props, { emit }) {

    const selectedItem = computed({

      get() {

        return props.modelValue;
      },

      set(value) {

        emit('update:modelValue', value);
      }
    });

    function isArray(obj: unknown) {

      return Object.prototype.toString.call(obj) === '[object Array]';
    }

    return {

      isArray,
      selectedItem
    };
  }
});

