

import {computed, defineComponent, ref} from "vue";
import Spinner from "@/views/components/Spinner.vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/users/types";
import LogoutModal from "@/views/components/layout/LogoutModal.vue";

export default defineComponent({

  components: { LogoutModal, Spinner},

  setup() {

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const pageIsLoading = ref(true);

    const showSpinner = ref(false);

    const appRefresh = computed( () => {

      return store.getters.getRefreshToken
    })

    const appAccess = computed( () => {

      return store.getters.getAccessToken
    })

    setTimeout(() => showSpinner.value = true, 2000);

    router.isReady().then(() => {

      if(appAccess.value || appRefresh.value) {

        store.dispatch(ActionTypes.FETCH_USER_INFO, {

          successCallback: () => {

            pageIsLoading.value = false;
          },

          errorCallback: () => {

            pageIsLoading.value = false;
          }
        });
      } else {

        pageIsLoading.value = false
      }

    });

    return {

      route,
      showSpinner,
      pageIsLoading,
    };
  }
})

