import {FiltersForCircleWithdrawalsView} from "@/store/modules/circles/types/state";

export const allCirclesUtilities = () => {

    const checkFiltersForCircleWithdrawalsViewAreEqual =
        (filtersA: FiltersForCircleWithdrawalsView,
         filtersB: FiltersForCircleWithdrawalsView): boolean => {

            return filtersA.circleId === filtersB.circleId;
        }

    return {

        checkFiltersForCircleWithdrawalsViewAreEqual
    };
}
