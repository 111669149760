

import {defineComponent, PropType} from "vue";
import TaskAlt from "@/icons/outlined/task-alt.vue";
export default defineComponent({
  components: {TaskAlt},
  props: {

    invert: {

      type: Boolean as PropType<boolean>,
      default: false
    },
    title: {

      type: String as PropType<string>,
      required: true
    },
    subtitle: {

      type: String as PropType<string>,
      required: true
    },
    id: {

      type: String as PropType<string>,
      required: true
    },
  },

  setup(props, {emit}) {

    function selectedChamas() {

      emit('selected-chama', props.id);
    }

    return {

      selectedChamas
    }
  }
});
