
import {computed, defineComponent, nextTick, PropType, ref} from "vue";
import {useStore} from "@/hooks/useStore";
import {useRoute, useRouter} from "vue-router";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import {LastExpensePlan, LastExpensePolicyType} from "@/store/modules/last-expense/types/models";
import Spinner from "@/views/components/Spinner.vue";
import NewPolicyModal from "@/views/pages/insurances/last-expense/new-policy/new-policy-modal/NewPolicyModal.vue";
import PlanList2 from "@/views/pages/insurances/last-expense/new-policy/PlanList2.vue";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";

export default defineComponent({
  components: {PlanList2, NewPolicyModal, Spinner,},

  props: {

    policyType: {

      type: String as PropType<LastExpensePolicyType>,
      required: true
    },
    circleId: {

      type: String as PropType<string>,
      default: ''
    },
  },

  setup(props) {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const experienceId = route.params.experienceId as string;
    const showNewPolicyModal = ref(false);
    const selectedPlan = ref<LastExpensePlan | null>(null);
    const packageType = ref<'SINGLE' | 'MULTIPLE'>('SINGLE');

    const plans = computed(() => {

      return store.getters.getExperiencePlans(experienceId);
    });

    const selectedPackagePlans = computed(() => {

      const allPlans = plans.value;

      if(! allPlans) {

        return [];
      }

      return allPlans;
    });

    function selectPlan(planId: string) {

      const policyPlans = plans.value

      if(! policyPlans) {
        return;
      }

      const matchingPlan = policyPlans.find(
          plan => plan.lastExpensePlanId === planId
      );

      if(! matchingPlan) {
        return;
      }

      selectedPlan.value = matchingPlan;
      nextTick(() => {
        toggleNewPolicyModal(true);
      });
    }

    function toggleNewPolicyModal(show: boolean) {

      showNewPolicyModal.value = show;
    }

    function fetchPlans() {

      store.dispatch(ActionTypes.FETCH_EXPERIENCE_PLANS_WITHOUT_AUTH, {

        experienceId: experienceId
      });
    }

    const dashboardId = computed( () => {

      if(props.circleId){
        return props.circleId
      }

      return 'user'
    })

    const policiesRoute = computed(() => {

      return {name: 'last-expense-policies', params:  {dashboardId:dashboardId.value}};

    });

    function handleNewPolicesCreated() {

      store.commit(MutationTypes.CLEAR_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW, {

        filters: {circleId: props.circleId ? props.circleId : ''}
      });

      router.replace(policiesRoute.value);
    }

    function initialize() {

      if(! plans.value) {

        fetchPlans();
      }
    }

    initialize();

    return {

      plans,
      selectPlan,
      packageType,
      selectedPlan,
      showNewPolicyModal,
      toggleNewPolicyModal,
      selectedPackagePlans,
      handleNewPolicesCreated
    };
  }
});
