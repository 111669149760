import { State } from "@/store/modules/users/types";

export const state = (): State => ({

    userInfo: null,
    userWalletInfo: null,
    transactions: [],
    dataForUserTransactionsView: [],
    userPaymentCards: null,
    supportedBanks: [],
    userBankAccounts: null,
});