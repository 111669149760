import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "tw-text-sm" }
const _hoisted_2 = { class: "sm:tw-pr-2" }
const _hoisted_3 = { class: "tw-flex tw-items-center" }
const _hoisted_4 = { class: "tw-text-sm tw-font-semibold" }
const _hoisted_5 = {
  key: 0,
  class: "tw-flex-shrink-0 tw-text-white"
}
const _hoisted_6 = { class: "tw-mt-1 tw-text-xs tw-leading-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task_alt = _resolveComponent("task-alt")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw-m-4 tw-p-4 tw-rounded-lg tw-shadow-sm hover:tw-shadow-lg tw-cursor-pointer", [_ctx.invert ? 'tw-text-white' : 'tw-text-gray-600']]),
    style: _normalizeStyle({background: _ctx.invert ? '#2653d7' : '#DBEAFE'}),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedChamas()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          (_ctx.invert)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_task_alt, { class: "tw-ml-3 tw-w-6 tw-h-6" })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subtitle), 1)
    ])
  ], 6))
}