

import { defineComponent, onMounted, onUnmounted, ref} from "vue";
import MalipoLogo from "@/icons/malipo-logo.vue";
import {useRouter} from "vue-router";
import {useStore} from "@/hooks/useStore";
import {useAuth} from "@/hooks/useAuth";
import SignUpModal from "@/views/pages/authenticate/sign-up-modal/SignUpModal.vue";

export default defineComponent({
  components: {SignUpModal, MalipoLogo},

  setup() {

    const router = useRouter();
    const store = useStore();
    const { pushToServerLoginPage } = useAuth();

    const isSigningIn = ref(false);
    const showSignUpModal = ref(false);

    function toggleSignUpModal(show: boolean) {

      if(isSigningIn.value) { return; }

      showSignUpModal.value = show;
    }

    function signIn() {

      if(isSigningIn.value) { return; }

      isSigningIn.value = true;

      pushToServerLoginPage();
    }

    if(store.getters.isUserLoggedIn) {

        router.push({name: 'home-view'});
    }

    onMounted( () => {
      window.scrollTo({top: 0, behavior: 'instant'})
      document.body.classList.add("login")
    })

    onUnmounted( () => {
      document.body.classList.remove("login")
    })

    return {
      signIn,
      showSignUpModal,
      toggleSignUpModal
    };
  }
});

