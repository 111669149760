

import {computed, defineComponent, PropType, ref} from "vue";
import CustomInput from "./CustomInput.vue";
import Eye from "@/icons/solid/eye.vue";
import EyeOff from "@/icons/solid/eye-off.vue";

export default defineComponent({

  components: {EyeOff, Eye, CustomInput},

  props: {

    currentInputValue: {

      type: String as PropType<string>,
      required: true
    },

    error: {

      type: String as PropType<string>,
      default: ""
    },
  },

  setup(props, {emit}) {

    const showPassord = ref(false);

    const inputValue = computed({

      get(): string {

        return props.currentInputValue
      },

      set(newValue: string): void {

        emit('new-value', newValue);
      }
    });

    function handleKeyDown() {

      emit('key-down')
    }

    function togglePasswordVisibility() {

      showPassord.value = ! showPassord.value;
    }

    return {

      inputValue,
      showPassord,
      handleKeyDown,
      togglePasswordVisibility
    }
  }
});

