

import {computed, defineComponent} from "vue";
import {useStore} from "@/hooks/useStore";
import NavbarLink from "@/views/components/layout/navbar/NavbarLink.vue";
import MemberAvatar from "@/views/pages/insurances/shared/member-elements/MemberAvatar.vue";
import DotsVertical from "@/icons/solid/dots-vertical.vue";
export default defineComponent({

  components: {DotsVertical, MemberAvatar, NavbarLink},

  setup() {

    const store = useStore();

    const userInfo = computed(() => {

      return store.getters.getUserInfo;
    });

    const userFirstName = computed(() => {

      return userInfo.value ? userInfo.value?.firstName : 'User';
    });

    const userAvatar = computed(() => {

      return userInfo.value ? userInfo.value?.avatarURL : '';
    });

    return {

      userAvatar,
      userFirstName,
    };
  }
})
