
import {computed, defineComponent, ref} from "vue";
import {useStore} from "@/hooks/useStore";
import {Circle, CircleType} from "@/store/modules/circles/types/models";
import { ActionTypes } from "@/store/modules/circles/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import {useRouter} from "vue-router";
import InsuranceChamaCard from "@/views/components/insurance-elements/InsuranceChamaCard.vue";
import HealthAndSafety from "@/icons/outlined/health-and-safety.vue";
import HistoryEducation from "@/icons/outlined/history-education.vue";
import CustomHeader from "@/views/components/layout/CustomHeader.vue";
import LastExpenseDetails2 from "@/views/pages/insurances/last-expense/last-expense-view/LastExpenseDetails2.vue";
import Plus from "@/icons/solid/plus.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
    components: {
      CustomNavbar,
        Plus,
        CustomHeader,
        LastExpenseDetails2,
        InsuranceChamaCard,
        Spinner},

    setup() {

        const dashboardId = ref("user");
        const store = useStore();
        const router = useRouter();
        const USER_DASHBOARD_ID = "user";
        const selectedChamaId = ref("")
        const filters = ref({

            type: CircleType.CHAMA
        });

        const isUserDashboard = computed(() => {

            return dashboardId.value === USER_DASHBOARD_ID;
        });

        const hideDashboards = ref(true);

        const paginatedChamas = computed(() => {

            return store.getters.getDataForUserCirclesView(filters.value);
        });

        const hasFetchedChamas = computed(() => {

            return !!paginatedChamas.value;
        });

        const canLoadMore = computed(() => {

            const data = paginatedChamas.value;

            if(! data) { return false; }

            return data.nextPage <= data.totalPages;
        });

        const groups = computed(() => {

            const groups = paginatedChamas.value?.items;

            if(! groups) {

                return [];
            }

            const groupsData: Array<{
                title: string,
                subtitle: string,
                id: string}> = [];

            groups.forEach(group => {

                const circle = group.circle as Circle;

                groupsData.push({

                    title: circle.name,
                    subtitle: `Group ${circle.circleId}`,
                    id: circle.circleId
                })
            });

            return groupsData;
        });

        function createNewGroup() {

            window.open(process.env.VUE_APP_CLIENT_MAIN_WEB_APP + `/my-circles/chama`, '_blank');
        }

        function fetchUserChamas() {

            store.dispatch(ActionTypes.FETCH_DATA_FOR_USER_CIRCLES_VIEW, {

                filters: filters.value,
            });
        }

        function checkChamas() {

            if(! hasFetchedChamas.value) {

                fetchUserChamas();
            }
        }

        function extendedHeader(value:boolean) {

            hideDashboards.value  = value ;
            if (value) {
                dashboardId.value = 'user'
            } else {
                dashboardId.value = selectedChamaId.value;
                checkChamas();
            }
        }

        function selectedChama(value:string) {

            dashboardId.value  = value;
            selectedChamaId.value = value;
        }

        function viewGroupPolicies() {

            if (selectedChamaId.value)
                router.push(policiesRoute.value);
        }

        function viewGroupClaims() {

            if (selectedChamaId.value)
                router.push(claimsRoute.value);
        }

        const policiesRoute = computed(() => {

            return {name: 'last-expense-policies', params:  {dashboardId:selectedChamaId.value}};

        });

        const claimsRoute = computed(() => {

            return {name: 'last-expense-claims', params:  {dashboardId:selectedChamaId.value}};

        });

        const menuItems = computed( () => {

            return [
                {icon: HealthAndSafety, title: "Policies", route: policiesRoute.value},
                {icon: HistoryEducation, title: "Claims", route: {name: 'last-expense-claims', params:  {dashboardId:dashboardId.value}}},
            ]
        });

        return {

            groups,
            menuItems,
            canLoadMore,
            dashboardId,
            selectedChama,
            hideDashboards,
            extendedHeader,
            createNewGroup,
            isUserDashboard,
            selectedChamaId,
            viewGroupClaims,
            hasFetchedChamas,
            viewGroupPolicies,
        }
    }
});
