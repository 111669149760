import axios, {AxiosResponse} from "axios";

class AuthService {

    private static baseUrl = `${process.env.VUE_APP_BASE_URL}/auth/v1`;
    private static instance: AuthService;

    static singleton = () => {

        if (!AuthService.instance) {

            AuthService.instance = new AuthService();
        }

        return AuthService.instance;
    };

    private static generateQueryString(record: Record<string, string>) {

        return Object.entries(record)
            .map(([key, value]) => `${key}=${value}`).join('&');
    }

    async getAuthTokens(queryString: string): Promise<AxiosResponse> {

        return await axios.post(
            process.env.VUE_APP_ACCESS_TOKEN_URI, queryString, {

                auth: {

                    username: process.env.VUE_APP_CLIENT_ID,
                    password: process.env.VUE_APP_CLIENT_SECRET
                },

                headers: {

                    "Content-Type": "application/x-www-form-urlencoded",
                }
            });
    }

    async refreshAuthTokens(
        grantType: string, refreshToken: string
    ): Promise<AxiosResponse> {

        return await this.getAuthTokens(AuthService.generateQueryString(
            {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }
        ));
    }

    async getAuthTokensUsingAuthorizationCode(
        grantType: string, code: string, redirectUri: string): Promise<AxiosResponse> {

        return await this.getAuthTokens(AuthService.generateQueryString(
            {
                grant_type: grantType,
                redirect_uri: encodeURIComponent(redirectUri),
                code: code
            }
        ));
    }

    async getAuthTokenUsingClientIdAndSecret(): Promise<AxiosResponse> {

        return await this.getAuthTokens(AuthService.generateQueryString(
            {
                grant_type: 'client_credentials',
            }
        ));
    }

    async requestRegistration(
        data: unknown, clientAccessToken: string
    ): Promise<AxiosResponse> {

        return await axios.post(
            AuthService.baseUrl + `/users/register/request`, data,
            {
                headers: {Authorization: `Bearer ${clientAccessToken}`}
            });
    }

    async validateRegistration(
        email: string, otp: string, clientAccessToken: string
    ): Promise<AxiosResponse> {

        return await axios.post(
            AuthService.baseUrl + `/users/register/validate`,
            {email, otp},
            {
                headers: {Authorization: `Bearer ${clientAccessToken}`}
            });
    }

    async resendVerificationCodeDuringRegistration(
        email: string, otpChannel: "SMS | EMAIL", clientAccessToken: string
    ): Promise<AxiosResponse> {

        return await axios.post(
            AuthService.baseUrl + `/users/register/resendCredentials`,
            {email, otpChannel},
            {
                headers: {Authorization: `Bearer ${clientAccessToken}`}
            });
    }

  async userLogout(previousRoute=""):Promise<void> {

    const redirectUrl = process.env.VUE_APP_CLIENT_BASE_URL +  previousRoute;

    window.location.href = `${process.env.VUE_APP_BASE_URL}/auth/logout` + '?redirect_uri=' + encodeURIComponent(redirectUrl);
  }
}

export default AuthService;
