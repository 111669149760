export const useAuth = () => {

    const pushToServerLoginPage = () => {

        const clientId = process.env.VUE_APP_CLIENT_ID;
        const redirectUrl = process.env.VUE_APP_CLIENT_BASE_URL + '/login';

        location.href = process.env.VUE_APP_AUTHORIZATION_TOKEN_URI +
            '?client_id=' + clientId +
            '&redirect_uri=' + encodeURIComponent(redirectUrl) +
            '&response_type=code';
    }

    return {

        pushToServerLoginPage
    };
}
