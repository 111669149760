

import {computed, defineComponent, PropType, ref} from "vue";
import Spinner from "@/views/components/Spinner.vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import {
  LastExpensePolicy,
  LastExpensePolicyStatus
} from "@/store/modules/last-expense/types/models";
import MakeClaimModal from "@/views/pages/insurances/last-expense/policy/policy-claims/MakeClaimModal.vue";
import PolicyClaimsTable from "@/views/pages/insurances/last-expense/policy/policy-claims/PolicyClaimsTable.vue";
import Plus from "@/icons/solid/plus.vue";

export default defineComponent({
  components: {Plus, PolicyClaimsTable, MakeClaimModal, Spinner},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },
  setup(props) {

    const store = useStore();
    const showMakeClaimModal = ref(false);

    const claims = computed(() => {

      return store.getters.getPolicyClaims(props.policy.lastExpensePolicyId);
    });

    const showMakeClaimButton = computed(() => {

      return props.policy.policyStatus === LastExpensePolicyStatus.ACTIVE;
    });

    function toggleMakeClaimModal(show: boolean) {

      showMakeClaimModal.value = show;
    }

    function fetchPolicyClaims() {

      store.dispatch(ActionTypes.FETCH_POLICY_CLAIMS, {

        policyId: props.policy.lastExpensePolicyId
      });
    }

    function initialize() {

      if(! claims.value) {

        fetchPolicyClaims();
      }
    }

    initialize();

    return {

      claims,
      showMakeClaimModal,
      showMakeClaimButton,
      toggleMakeClaimModal,
    }
  }
});

