import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xs tw-text-center tw-py-3 tw-px-6", _ctx.statusFormatting.classes]),
    style: _normalizeStyle(_ctx.statusFormatting.styles)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass({'tw-truncate': _ctx.truncate})
    }, _toDisplayString(_ctx.status), 3)
  ], 6))
}