import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tw-w-full tw-relative tw-rounded-md tw-h-1.5",
    style: _normalizeStyle({backgroundColor: _ctx.bgColor, maxWidth: _ctx.maxWidth + 'px'})
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tw-absolute tw-left-0 tw-inset-y-0 tw-rounded-l-md", { 'tw-rounded-r-md' : _ctx.percentage === 100}]),
      style: _normalizeStyle({backgroundColor: _ctx.fgColor, right: (100 - _ctx.percentage) + '%'})
    }, null, 6)
  ], 4))
}