import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-2 mp-text-sm text-muted" }
const _hoisted_2 = { class: "tw-text-gray-700 tw-font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation = _resolveComponent("confirmation")!

  return (_openBlock(), _createBlock(_component_confirmation, {
    title: 'Success',
    "primary-action": "OK",
    onPrimaryAction: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("template", null, [
          _createTextVNode(" Policy "),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.policyId), 1),
          _createTextVNode(" has been successfully cancelled. ")
        ])
      ])
    ]),
    _: 1
  }, 8, ["onPrimaryAction"]))
}