import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "min-vh-100 d-flex align-items-center justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_logout_modal = _resolveComponent("logout-modal")!

  return (_ctx.pageIsLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showSpinner)
          ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_router_view),
        (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
          _createVNode(_component_logout_modal)
        ]))
      ], 64))
}