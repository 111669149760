import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw-pl-0 tw-pr-4 tw-list-none" }
const _hoisted_2 = { class: "tw-ml-4 tw-text-sm tw-text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_circle = _resolveComponent("check-circle")!
  const _component_confirmation = _resolveComponent("confirmation")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    "fullscreen-mode-below-sm-active": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_confirmation, {
        title: "Member requirements",
        "primary-action": "Close",
        onPrimaryAction: _ctx.close
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requirements, (requirement, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "tw-flex tw-items-start tw-mt-5",
                key: index
              }, [
                _createVNode(_component_check_circle, { class: "tw-flex-shrink-0 tw-h-6 tw-w-6 text-primary" }),
                _createElementVNode("div", _hoisted_2, _toDisplayString(requirement), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["onPrimaryAction"])
    ]),
    _: 1
  }, 8, ["show"]))
}