

import {defineComponent, PropType} from "vue";
import {AxiosError} from "axios";
import SubmissionErrorHandler from "@/views/components/SubmissionErrorHandler.vue";

export default defineComponent({
    components: {SubmissionErrorHandler},

    props: {

        title: {

            type: String as PropType<string>,
            required: true
        },

        body: {

            type: String as PropType<string>,
            default: "",
        },

        isLoading: {

            type: Boolean as PropType<boolean>,
            default: false
        },

        secondaryAction: {

            type: String as PropType<string>,
            default: ""
        },

        primaryAction: {

            type: String as PropType<string>,
            required: true
        },

        error: {

            type: [Object, String, null] as PropType<AxiosError | string | null>,
            default: null
        },
    },

    setup(props, {emit}) {

        function handlePrimaryAction() {

            emit('primary-action');
        }

        function handleSecondaryAction() {

            emit('secondary-action');
        }

        return {

            handlePrimaryAction,
            handleSecondaryAction,
        };
    }
})

