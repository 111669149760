
import {computed, defineComponent, ref} from "vue";
import {useRouter} from "vue-router";
import HealthAndSafety from "@/icons/outlined/health-and-safety.vue";
import HistoryEducation from "@/icons/outlined/history-education.vue";
import CustomHeader from "@/views/components/layout/CustomHeader.vue";
import GroupsView from "@/views/pages/insurances/last-expense/private-insurance/GroupsView.vue";
import InsurancesView from "@/views/pages/insurances/last-expense/private-insurance/InsurancesView.vue";
import BackButton from "@/views/components/BackButton.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
    components: {
      CustomNavbar,
        BackButton,
        InsurancesView,
        GroupsView,
        CustomHeader
    },

    setup() {

        const router = useRouter();
        const selectedGroupId = ref('')

        function updateSelectedGroup(value: string) {

            selectedGroupId.value = value;
        }

        function viewPolicies() {

            if (selectedGroupId.value)
                router.push(policiesRoute.value);
        }

        const policiesRoute = computed(() => {

            return {name: 'last-expense-policies', params:  {dashboardId:selectedGroupId.value}};

        });

        const menuItems = computed( () => {

            return [
                {icon: HealthAndSafety, title: "Policies", route: policiesRoute.value},
                {icon: HistoryEducation, title: "Claims", route: {name: 'last-expense-claims', params:  {dashboardId:selectedGroupId.value}}},
            ]
        });

        return {

            menuItems,
            viewPolicies,
            selectedGroupId,
            updateSelectedGroup
        }
    }
});
