
import {computed, defineComponent, PropType} from "vue";
import {
  LastExpenseClaimPayment
} from "@/store/modules/last-expense/types/models";
import {useDateUtilities} from "@/hooks/useDateUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import ChevronRight from "@/icons/chevron-right.vue";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {
    useClaimPaymentsUtilities
} from "@/views/pages/insurances/last-expense/claim/claim-payments/useClaimPaymentsUtilities";
import BaseTable from "@/views/components/BaseTable.vue";

export default defineComponent({
  components: {BaseTable, ChevronRight},
  props: {

    payments: {

      type: Array as PropType<Array<LastExpenseClaimPayment>>,
      required: true
    }
  },

  setup(props, {emit}) {

    const {formatToRelativeDate} = useDateUtilities();
    const { ucfirst } = useStringUtilities();
    const { formatCurrency } = useCurrencyUtilities();
    const { computeStatusColor } = useClaimPaymentsUtilities();

    const formattedPayments = computed(() => {

      return props.payments.map(payment => {

        return {

          paymentId: payment.claimPaymentId,
          amount: 'Ksh ' + formatCurrency(payment.amount, 0),
          createdOn: formatToRelativeDate(payment.createdOn),
          status: payment.claimPaymentStatus,
          formattedStatus: ucfirst(payment.claimPaymentStatus),
          statusColor: computeStatusColor(payment.claimPaymentStatus),
          original: payment
        }
      });
    });

    function viewPayment(payment: LastExpenseClaimPayment) {

      emit('view-payment', payment);
    }

    return {

      viewPayment,
      formattedPayments
    }
  }
});
