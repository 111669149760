import {useStore} from "@/hooks/useStore";
import {useRoute} from "vue-router";
import {computed} from "vue";
import {Circle} from "@/store/modules/circles/types/models";
import {ActionTypes} from "@/store/modules/circles/types/actions";

export default function useInsuranceViewInfo() {

    const store = useStore();
    const route = useRoute();
    const USER_DASHBOARD_ID = "user";

    const dashboardId = route.params.dashboardId as string;

    const dashboardInfo = computed(() => {

        const info: { type: 'INDIVIDUAL' | 'CIRCLE'; id: string; name: string; } = {
            type: 'INDIVIDUAL',
            id: dashboardId,
            name: ''
        }

        if(dashboardId === USER_DASHBOARD_ID) {

            const userInfo = store.getters.getUserInfo;
            info.type = 'INDIVIDUAL';
            info.name = userInfo ? userInfo.firstName + " " + userInfo.lastName : ""

        } else {

            info.type = 'CIRCLE';

            const circle = store.getters.getUserCircle(dashboardId);

            if(circle) {

                const circleInfo = circle.circle as Circle;
                info.name = circleInfo.name
            }
        }

        return info;
    });

    function fetchChama() {

        store.dispatch(ActionTypes.FETCH_CIRCLE_STATISTICS, {
            circleId: dashboardId,
        });
    }

    function initialize() {

        if(dashboardId !== USER_DASHBOARD_ID && ! dashboardInfo.value) {

            fetchChama();
        }
    }

    initialize();

    return {

        dashboardInfo
    };
}
