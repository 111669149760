export enum NormalizedStatus {

    FAILED = "FAILED", PENDING = "PENDING", SUCCESS = "SUCCESS",
    ACTION = "ACTION", INACTIVE = "INACTIVE"
}

export const useNormalizedStatusUtilities = () => {

    const computeNormalizedStatusColors = (status: NormalizedStatus) => {

        const colors = {

            color: 'tw-text-yellow-700',
            backgroundColor: 'tw-bg-yellow-100',
            borderColor: 'tw-border-yellow-400'
        };

        if(status === NormalizedStatus.INACTIVE) {

            colors.color = 'tw-text-gray-700';
            colors.backgroundColor = 'tw-bg-gray-100';
            colors.borderColor = 'tw-border-gray-400';

        } else if(status === NormalizedStatus.FAILED) {

            colors.color = 'tw-text-red-700';
            colors.backgroundColor = 'tw-bg-red-100';
            colors.borderColor = 'tw-border-red-400';

        } else if(status === NormalizedStatus.SUCCESS) {

            colors.color = 'tw-text-green-700';
            colors.backgroundColor = 'tw-bg-green-100';
            colors.borderColor = 'tw-border-green-400';

        } else if(status === NormalizedStatus.ACTION) {

            colors.color = 'tw-text-white';
            colors.backgroundColor = 'tw-bg-indigo-400 ';
            colors.borderColor = 'tw-border-indigo-400'
        }

        return colors;
    };

    return {

        computeNormalizedStatusColors
    }
}