import { Getters, State } from "@/store/modules/authorization/types";
import { GetterTree } from "vuex";
import { RootState } from "@/store";

export const getters: GetterTree<State, RootState> & Getters = {
  getAccessToken: state => state.authorization.accessToken,
  getRefreshToken: state => state.authorization.refreshToken,
  isUserLoggedIn: (state, getters, rootState, rootGetters) =>
    ( !! getters.getAccessToken || !! getters.getRefreshToken ) &&  !! rootGetters.getUserInfo,
  getAppAuthToken: state => state.appAuthToken,
};
