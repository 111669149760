import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "tw-text-sm" }
const _hoisted_2 = { class: "tw-pt-7 sm:tw-pr-12" }
const _hoisted_3 = { class: "tw-flex tw-items-center" }
const _hoisted_4 = { class: "tw-flex-shrink-0" }
const _hoisted_5 = { class: "tw-pl-4" }
const _hoisted_6 = { class: "tw-text-gray-800" }
const _hoisted_7 = { class: "sm:tw-hidden tw-text-gray-600" }
const _hoisted_8 = { class: "tw-pt-7 tw-hidden sm:tw-table-cell tw-text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task_alt = _resolveComponent("task-alt")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (itemValue, itemTitle) => {
      return (_openBlock(), _createElementBlock("tr", { key: itemTitle }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_task_alt, { class: "tw-text-gray-500 tw-w-6 tw-h-6" })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(itemTitle), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(itemValue), 1)
            ])
          ])
        ]),
        _createElementVNode("td", _hoisted_8, _toDisplayString(itemValue), 1)
      ]))
    }), 128))
  ]))
}