import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_policy_member = _resolveComponent("view-policy-member")!
  const _component_edit_policy_member = _resolveComponent("edit-policy-member")!
  const _component_remove_policy_member = _resolveComponent("remove-policy-member")!
  const _component_base_form_modal = _resolveComponent("base-form-modal")!

  return (_openBlock(), _createBlock(_component_base_form_modal, { show: _ctx.show }, {
    default: _withCtx(() => [
      (_ctx.viewType === _ctx.ViewType.VIEW)
        ? (_openBlock(), _createBlock(_component_view_policy_member, {
            key: 0,
            member: _ctx.member,
            policy: _ctx.policy,
            onEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateViewType(_ctx.ViewType.EDIT))),
            onRemove: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateViewType(_ctx.ViewType.REMOVE))),
            onClose: _ctx.close
          }, null, 8, ["member", "policy", "onClose"]))
        : (_ctx.viewType === _ctx.ViewType.EDIT)
          ? (_openBlock(), _createBlock(_component_edit_policy_member, {
              key: 1,
              member: _ctx.member,
              policy: _ctx.policy,
              onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateViewType(_ctx.ViewType.VIEW))),
              onClose: _ctx.close
            }, null, 8, ["member", "policy", "onClose"]))
          : (_openBlock(), _createBlock(_component_remove_policy_member, {
              key: 2,
              member: _ctx.member,
              onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateViewType(_ctx.ViewType.VIEW))),
              onClose: _ctx.close
            }, null, 8, ["member", "onClose"]))
    ]),
    _: 1
  }, 8, ["show"]))
}