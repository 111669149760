

import {computed, defineComponent, nextTick, PropType, ref} from "vue";
import {
  LastExpensePolicy,
  LastExpensePolicyPayment,
  LastExpensePolicyStatus, PolicyPaymentStatus,
} from "@/store/modules/last-expense/types/models";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import Plus from "@/icons/solid/plus.vue";
import PolicyPaymentsTable from "@/views/pages/insurances/last-expense/policy/policy-payments/PolicyPaymentsTable.vue";
import ViewPaymentModal from "@/views/pages/insurances/last-expense/policy/policy-payments/ViewPaymentModal.vue";
import MakePolicyPaymentModal
    from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/MakePolicyPaymentModal.vue";
import MakePolicyPaymentRequest
  from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/MakePolicyPaymentRequest.vue";

export default defineComponent({
  components: {MakePolicyPaymentRequest, MakePolicyPaymentModal, ViewPaymentModal, PolicyPaymentsTable, Plus, Spinner},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },

  setup(props) {

    const store = useStore();
    const showMakePaymentModal = ref(false);
    const showViewPaymentModal = ref(false);
    const paymentToView = ref<LastExpensePolicyPayment | null>(null);
    const showPolicyRequestModal = ref(false);

    const payments = computed(() => {

      return store.getters.getPolicyPayments(props.policy.lastExpensePolicyId);
    });

    const policyIsPendingSubmission = computed(() => {

      return props.policy.policyStatus === LastExpensePolicyStatus.PENDING;
    });

    const showPaymentButton = computed(() => {

      const paymentRecords = payments.value;

      if(! paymentRecords) {

        return false;
      }

      const totalPayments = paymentRecords.reduce((total, payment) => {
        return total + (payment.policyPaymentStatus === PolicyPaymentStatus.RECEIVED ?
            payment.amount : 0);
      }, 0);

      return totalPayments < props.policy.lastExpensePlan.premium &&
          (props.policy.policyStatus === LastExpensePolicyStatus.SUBMITTED ||
          props.policy.policyStatus === LastExpensePolicyStatus.CONFIRMED);
    });

    function toggleViewPaymentModal(
        show: boolean, payment: LastExpensePolicyPayment | null = null
    ) {

      if(payment) {

        paymentToView.value = payment
      }

      nextTick(() => {

        showViewPaymentModal.value = show;
      });
    }

    function toggleMakePaymentModal(show: boolean) {

      showMakePaymentModal.value = show;
    }

    function togglePolicyRequestModal(show: boolean) {

      showPolicyRequestModal.value = show;
    }

    function fetchPayments() {

      store.dispatch(ActionTypes.FETCH_POLICY_PAYMENTS, {

        policyId: props.policy.lastExpensePolicyId,
      });
    }

    function initialize() {

      if(! payments.value) {

        fetchPayments();
      }
    }

    initialize();

    return {

      payments,
      paymentToView,
      showPaymentButton,
      showMakePaymentModal,
      showViewPaymentModal,
      showPolicyRequestModal,
      toggleMakePaymentModal,
      toggleViewPaymentModal,
      togglePolicyRequestModal,
      policyIsPendingSubmission
    }
  }
});

