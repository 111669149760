
import {computed, defineComponent, PropType} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import {
  LastExpenseClaimPayment,
} from "@/store/modules/last-expense/types/models";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {useDateUtilities} from "@/hooks/useDateUtilities";
import {
    useClaimPaymentsUtilities
} from "@/views/pages/insurances/last-expense/claim/claim-payments/useClaimPaymentsUtilities";
import X from "@/icons/solid/x.vue"

export default defineComponent({
  components: { X,BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    payment: {

      type: Object as PropType<LastExpenseClaimPayment>,
      required: true
    }
  },

  setup(props, {emit}) {

    const { ucfirst } = useStringUtilities();
    const { formatCurrency } = useCurrencyUtilities();
    const { computeStatusColor } = useClaimPaymentsUtilities();
    const { formatToHumanFriendlyDate, dateToTimeInTwelveHourFormat} = useDateUtilities();

    const formattedPayment = computed(() => {

      const payment = props.payment;
      return {

        amount: 'Ksh ' + formatCurrency(payment.amount),
        status: payment.claimPaymentStatus,
        formattedStatus: ucfirst(payment.claimPaymentStatus),
        statusColor: computeStatusColor(payment.claimPaymentStatus),
      }
    });

    const paymentDetails = computed(() => {

      const payment = props.payment;

      return {

        'Date created': formatToHumanFriendlyDate(payment.createdOn) + ', ' +
            dateToTimeInTwelveHourFormat(payment.createdOn),
        'Transaction ref': payment.transactionRef,
        'Payment id': payment.claimPaymentId
      };
    });

    function close() {

      emit('close');
    }

    return {

      close,
      paymentDetails,
      formattedPayment,
    };
  }
});
