import {GetterTree} from "vuex";
import {State} from "@/store/modules/last-expense/types/state";
import {RootState} from "@/store";
import {Getters} from "@/store/modules/last-expense/types/getters";
import {LastExpenseClaim} from "@/store/modules/last-expense/types/models";

export const getters: GetterTree<State, RootState> & Getters = {

    getExperiences: state => {

        return state.experiences;
    },
    getPrivateExperiences: state => circleId =>  {

        if(circleId in state.privateExperiences) {

            return state.privateExperiences[circleId];
        }

        return null
    },
    getLastExperience: state => experienceId => {

        const matchingExperience = state.lastExpenseExperiences.find(experience => {

            return experience.experienceId === experienceId;
        });

        return matchingExperience ? matchingExperience : null;
    },

    getDataForLastExpensePoliciesView: state => filters => {

        const matchingViewData = state.dataForPoliciesView.find(
            viewData => {

                return viewData.filters.circleId === filters.circleId
            });

        if(! matchingViewData) { return null; }

        const matchingPolicies = state.policies.filter(policy =>
            matchingViewData.items.includes(policy.lastExpensePolicyId)
        );

        matchingPolicies.sort((a, b) =>
            b.createdOn.getTime() - a.createdOn.getTime()
        );

        return Object.assign({}, matchingViewData,
            { items: matchingPolicies });
    },

    getPolicy: state => id => {

        const matchingPolicy = state.policies.find(policy => {

            return policy.lastExpensePolicyId === id;
        });

        return matchingPolicy ? matchingPolicy : null;
    },

    getPolicyBeneficiaries: state => policyId => {

        if(policyId in state.policyBeneficiaries) {

            return state.policyBeneficiaries[policyId];
        }

        return null;
    },

    getPolicyPayments: state => policyId => {

        if(policyId in state.policyPayments) {

            return state.policyPayments[policyId];
        }

        return null;
    },

    getExperiencePlans: state => experienceId => {

        if(experienceId in state.experiencePlans) {

            return state.experiencePlans[experienceId];
        }

        return null;
    },

    getDataForLastExpenseClaimsView: state => filters => {

        const matchingViewData = state.dataForClaimsView.find(
            viewData => {

                return viewData.filters.circleId === filters.circleId
            });

        if(! matchingViewData) { return null; }

        const matchingClaims = state.claims.filter(claim =>
            matchingViewData.items.includes(claim.lastExpenseClaimId)
        );

        matchingClaims.sort((a, b) =>
            b.createdOn.getTime() - a.createdOn.getTime()
        );

        return Object.assign({}, matchingViewData,
            { items: matchingClaims });
    },

    getClaim: state => id => {

        const matchingClaim = state.claims.find(claim => {

            return claim.lastExpenseClaimId === id;
        });

        return matchingClaim ? matchingClaim : null;
    },

    getClaimPayments: state => claimId => {

        if(claimId in state.claimPayments) {

            return state.claimPayments[claimId];
        }

        return null;
    },

    getPolicyClaims: state => policyId => {

        const claimIds = state.policyClaims[policyId];

        if(! claimIds) {

            return null;
        }

        const claims: Array<LastExpenseClaim> = [];

        claimIds.forEach(claimId => {

           const claim = state.claims.find(claim => claim.lastExpenseClaimId === claimId);

           if(claim) {

               claims.push(claim);
           }
        });

        claims.sort((a, b) =>
            b.createdOn.getTime() - a.createdOn.getTime()
        );

        return claims;
    }
}