import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-pt-1 mp-text-sm text-muted"
}
const _hoisted_2 = {
  key: 1,
  class: "tw-pt-1 mp-text-sm text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.otherContribution)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.successMessageBody), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.paymentMode === _ctx.PaymentMode.MPESA && ! _ctx.isWithdrawal ?
            `Please authorize the request sent to your phone number to complete
          the process.` : _ctx.successMessageBody), 1))
  ]))
}