

import {defineComponent, PropType} from "vue";
import PaymentModeOption from "@/views/components/payment-payout/PaymentModeOption.vue";
import {PaymentMode} from "@/store/types/baseModels";

export default defineComponent({

  components: {PaymentModeOption},

  props: {

    title: {

      type: String as PropType<string>,
      default: "Choose payment mode"
    },

    supportedPaymentModes: {

      type: Array as PropType<Array<PaymentMode>>,
      required: true
    },

    selectedPaymentMode: {

      type: String as PropType<PaymentMode>,
      required: true
    }
  },

  setup(props, {emit}) {

    function selectPaymentMode(method: string) {

      emit('mode-selected', method);
    }

    function close() {

      emit('close');
    }

    function initialize() {

      emit('title', props.title);
    }

    initialize();

    return {

      close,
      PaymentMode,
      selectPaymentMode
    }
  }
});

