

import {computed, defineComponent, PropType} from "vue";

export default defineComponent({

  props: {

    policyId: {

      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {

    const policyRoute = computed(() => {

      return {

        name: 'last-expense-policy',
        params: { policyId: props.policyId}
      }
    });

    return {

      policyRoute
    }
  }
});

