
import {computed, defineComponent, PropType, ref} from "vue";
import {RouteLocationRaw} from "vue-router";
import LastRespectIllustration from "@/icons/last-respect-illustration.vue";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {InsuranceType} from "@/store/modules/last-expense/baseModels";

export default defineComponent({
  components: {LastRespectIllustration},

  props: {

    title: {

      type: String as PropType<string>,
      required: true
    },
    description: {

      type: String as PropType<string>,
      required: true
    },
    subTitle: {

      type: String as PropType<string>,
      required: true
    },
    experienceType: {

      type: String as PropType<'EVENT' | 'LAST_EXPENSE'>,
      required: true
    },
    avatarUrl: {

      type: String as PropType<string>,
      default: null
    },
    route: {

      type: Object as PropType<RouteLocationRaw>,
      required: true
    },

    insuranceType: {
      type: String as PropType<InsuranceType>,
      default: null
    }
  },

  setup( props) {

    const description = ref(props.description);
    const trimDescription = ref(false);
    const { ucfirst, stripUnderscores } = useStringUtilities();
    const cappedDescription = computed( () => {

      const words = description.value.split(" ");
      const maxWords = 70;

      if(words.length > maxWords) {
        trimWords(true);
        return words.slice(0,maxWords).join(" ");
      }
      return description.value
    })

    function trimWords( status: boolean) {
      trimDescription.value = status;
    }

    const styleObject = computed(() => {

      return {

        backgroundImage: props.avatarUrl ? 'url(' + props.avatarUrl + ')' : '',
      };
    });



    return {

      ucfirst,
      styleObject,
      trimDescription,
      stripUnderscores,
      cappedDescription
    };
  }

});
