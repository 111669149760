

import {defineComponent, PropType, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/hooks/useStore";
import {mappers} from "@/store/modules/last-expense/mappers";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import Confirmation from "@/views/components/Confirmation.vue";

export default defineComponent({

  components: { Confirmation },

  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },

  },

  setup(props, {emit}) {

    const route = useRoute();
    const store = useStore();
    const policyId = route.params.policyId as string;
    const body = ref("");
    const isSubmitting = ref(false);
    const {mapToTypedLastExpensePolicy} = mappers();
    const submissionError = ref("");

    function cancel() {

      close();
    }

    function submit() {

      isSubmitting.value = true;

      submissionError.value = "";

      LastExpenseExperience.singleton().cancelPolicy(
          policyId
      ).then(response => {


        const cancelledPolicy = mapToTypedLastExpensePolicy(response.data);

        store.commit(MutationTypes.SET_POLICY, {

          data: cancelledPolicy
        });

        emit('cancelled-policy-data', cancelledPolicy)

        close();

      }).catch(error => {

        if(error.response &&
            (error.response.status == 422 || error.response.status == 409) &&
            error.response.data.message) {

          submissionError.value = error.response.data.message;

        } else {

          submissionError.value =
              "Something went wrong. Please try again.";
        }
      }).finally(() => {

        isSubmitting.value = false;
      });
    }

    function initializeData() {

      isSubmitting.value = false;

      submissionError.value = "";
    }

    function close() {

      emit('close');
    }

    watch(() => props.show, (value) => {

      if(value) {

        initializeData();
      }
    });

    return {

      body,
      cancel,
      submit,
      isSubmitting,
      submissionError,
      policyId
    }
  }
});

