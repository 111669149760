import {ActionContext, CommitOptions} from "vuex";
import {RootState} from "@/store";
import {Mutations as GlobalMutations} from "@/store/types/mutations";
import {AxiosResponse} from "axios";

export type Authorization = {
    accessToken: string | null;
    refreshToken: string | null;
};

export type State = {
    authorization: Authorization;
    appAuthToken: string | null;
};

/*======================Getters======================*/
export enum GetterTypes {
    GET_ACCESS_TOKEN = "getAccessToken",
    GET_REFRESH_TOKEN = "getRefreshToken",
    IS_USER_LOGGED_IN = "isUserLoggedIn",
    GET_APP_AUTH_TOKEN = "getAppAuthToken"
}

export type Getters = {
    [GetterTypes.GET_ACCESS_TOKEN]: (state: State) => string | null;
    [GetterTypes.GET_REFRESH_TOKEN]: (state: State) => string | null;
    [GetterTypes.IS_USER_LOGGED_IN]: (
        state: State, getters: Getters, rootState: RootState, rootGetters: any) => boolean; // eslint-disable-line  @typescript-eslint/no-explicit-any
    [GetterTypes.GET_APP_AUTH_TOKEN]: (state: State) => string | null;
};

/*======================Mutations======================*/
export enum MutationTypes {

    SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
    SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
    SET_APP_AUTH_TOKEN = "SET_APP_AUTH_TOKEN"
}

export type Mutations<S = State> = {

    [MutationTypes.SET_ACCESS_TOKEN]: (state: S, accessToken: string | null) => void;
    [MutationTypes.SET_REFRESH_TOKEN]: (state: S, refreshToken: string | null) => void;
    [MutationTypes.SET_APP_AUTH_TOKEN]: (state: S, accessToken: string | null) => void;
};

/*======================Actions======================*/
export type AugmentedActionContext = Omit<ActionContext<State, RootState>, "commit"> & {

    commit<K extends keyof GlobalMutations>(
        key: K,
        payload?: Parameters<GlobalMutations[K]>[1],
        options?:  CommitOptions
    ): ReturnType<GlobalMutations[K]>;
};

export enum ActionTypes {

    GET_AUTH_TOKENS_USING_AUTH_CODE = "GET_AUTHORIZATION_TOKENS_USING_CODE",
    REFRESH_AUTH_TOKENS = "REFRESH_AUTH_TOKENS",
    UPDATE_AUTHORIZATION_TOKENS = "UPDATE_AUTHORIZATION_TOKENS",
    LOGOUT_USER = "LOGOUT_USER",
    FETCH_APP_AUTH_TOKEN = "FETCH_APP_AUTH_TOKEN",
    REQUEST_USER_REGISTRATION = "REQUEST_USER_REGISTRATION",
    VALIDATE_USER_REGISTRATION = "VALIDATE_USER_REGISTRATION",
    RESEND_USER_REGISTRATION_VALIDATION_CODE="RESEND_USER_REGISTRATION_VALIDATION_CODE"
}

export type Actions = {

    [ActionTypes.GET_AUTH_TOKENS_USING_AUTH_CODE]: (
        ctx: AugmentedActionContext,
        payload: {

            authorizationCode: string;
            redirectUri: string;
            successCallback?: (accessToken: string, refreshToken: string) => void;
            errorCallback?: (error: unknown) => void;

        }) => void | Promise<void>;

    [ActionTypes.REFRESH_AUTH_TOKENS]: (
        ctx: AugmentedActionContext,
        payload: {

            refreshToken: string;
            successCallback?: (tokens: {accessToken: string; refreshToken: string}) => void;
            errorCallback?: (error: unknown) => void;

        }) => void | Promise<void>;

    [ActionTypes.UPDATE_AUTHORIZATION_TOKENS]: (
        ctx: AugmentedActionContext,
        payload: {

            accessToken: string | null;
            refreshToken: string | null;

        }) => void | Promise<void>;

    [ActionTypes.LOGOUT_USER]: (ctx: AugmentedActionContext) => void | Promise<void>;
    [ActionTypes.FETCH_APP_AUTH_TOKEN]: (
        ctx: AugmentedActionContext
    ) => Promise<AxiosResponse>;
    [ActionTypes.REQUEST_USER_REGISTRATION]: (
        ctx: AugmentedActionContext,
        payload: {
            data: unknown
        }) => Promise<AxiosResponse>;
    [ActionTypes.VALIDATE_USER_REGISTRATION]: (
        ctx: AugmentedActionContext,
        payload: {
            email: string,
            otp: string
        }) => Promise<AxiosResponse>;
    [ActionTypes.RESEND_USER_REGISTRATION_VALIDATION_CODE]: (
        ctx: AugmentedActionContext,
        payload: {
            email: string,
            otpChannel: "SMS | EMAIL"
        }) => Promise<AxiosResponse>;
};
