import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw-min-h-screen tw-bg-blueGray-50" }
const _hoisted_2 = { ref: "insuranceSection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_navbar = _resolveComponent("custom-navbar")!
  const _component_carousel = _resolveComponent("carousel")!
  const _component_active_insurances = _resolveComponent("active-insurances")!
  const _component_more_info_section = _resolveComponent("more-info-section")!
  const _component_insurance_section = _resolveComponent("insurance-section")!
  const _component_custom_footer = _resolveComponent("custom-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_custom_navbar, { "show-back-button": false }),
    _createVNode(_component_carousel, {
      sliders: _ctx.insuranceImages,
      onSwitchPoint: _ctx.scrollTarget
    }, null, 8, ["sliders", "onSwitchPoint"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_active_insurances, { title: "Our professional Insurance Services" })
    ], 512),
    _createVNode(_component_more_info_section),
    _createVNode(_component_insurance_section),
    _createVNode(_component_custom_footer)
  ]))
}