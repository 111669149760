

import {defineComponent, PropType} from "vue";

export default defineComponent({
    props: {

        clickable: {

            type: Boolean as PropType<boolean>,
            default: true
        },

        striped: {

            type: Boolean as PropType<boolean>,
            default: false
        },

        bordered: {

            type: Boolean as PropType<boolean>,
            default: false
        },

        noBgColorOnHeader: {

            type: Boolean as PropType<boolean>,
            default: false
        },

        borderlessLastRow: {

            type: Boolean as PropType<boolean>,
            default: false
        }
    }
});

