
import {computed, defineComponent, PropType} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import {
  LastExpensePolicyPayment
} from "@/store/modules/last-expense/types/models";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {
    usePolicyPaymentsUtilities
} from "@/views/pages/insurances/last-expense/policy/policy-payments/usePolicyPaymentsUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {useDateUtilities} from "@/hooks/useDateUtilities";

export default defineComponent({
  components: {BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    payment: {

      type: Object as PropType<LastExpensePolicyPayment>,
      required: true
    }
  },

  setup(props, {emit}) {

    const { ucfirst } = useStringUtilities();
    const { formatCurrency } = useCurrencyUtilities();
    const { computeStatusColor } = usePolicyPaymentsUtilities();
    const { formatToHumanFriendlyDate, dateToTimeInTwelveHourFormat} = useDateUtilities();

    const formattedPayment = computed(() => {

      const payment = props.payment;
      return {

        amount: 'Ksh ' + formatCurrency(payment.amount),
        status: payment.policyPaymentStatus,
        formattedStatus: ucfirst(payment.policyPaymentStatus),
        statusColor: computeStatusColor(payment.policyPaymentStatus),
      }
    });

    const paymentDetails = computed(() => {

      const payment = props.payment;

      return {

        'Date created': formatToHumanFriendlyDate(payment.createdOn) + ', ' +
            dateToTimeInTwelveHourFormat(payment.createdOn),
        'Transaction ref': payment.transactionRef,
        'Payment id': payment.policyPaymentId
      };
    });

    function close() {

      emit('close');
    }

    return {

      close,
      paymentDetails,
      formattedPayment,
    };
  }
});
