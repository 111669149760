import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35afe1ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-table tw-border-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", {
      class: _normalizeClass(["tw-w-full tw-bg-white", [_ctx.clickable ? 'clickable' : '',
           _ctx.noBgColorOnHeader ? 'white-bg-color-on-header': '',
           _ctx.striped ? (_ctx.noBgColorOnHeader ? 'striped-odd' : 'striped-even') : '',
           _ctx.bordered ? 'bordered' : '',
           _ctx.bordered ? (_ctx.borderlessLastRow ? 'borderless-last-row' : '') : '']])
    }, [
      _createElementVNode("thead", null, [
        _renderSlot(_ctx.$slots, "tableHead", {}, undefined, true)
      ]),
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 2)
  ]))
}