
export enum PaymentMode {
  BANK = "BANK", CARD = "CARD", MPESA = "MPESA", WALLET = "WALLET"
}


/*======================transaction models======================*/

export enum TransactionStatus {
  NONE = "NONE", VALIDATED = "VALIDATED", ACCEPTED = "ACCEPTED", PREPARED = "PREPARED",
  PAYMENT_REQUESTED = "PAYMENT_REQUESTED", PAYMENT_VERIFIED = "PAYMENT_VERIFIED",
  PAYOUT_REQUESTED = "PAYOUT_REQUESTED", PAYOUT_VERIFIED = "PAYOUT_VERIFIED", CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED", FAILED = "FAILED", UNRESOLVED = "UNRESOLVED"
}

export enum TransactionType {
  DEPOSIT = "DEPOSIT", WITHDRAW = "WITHDRAW", SEND_MONEY = "SEND_MONEY"
}

export type TransactionInfo = {

  amount: number;
  createdOn: Date;
  destinationAccountId: string;
  paymentMode: PaymentMode;
  sourceAccountId: string;
  transactionCost: number;
  transactionDescription: string;
  transactionRef: string;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  updatedOn: Date;
}

export type Bank = {

  code: string;
  fullName: string;
  name: string;
}

export type BankAccount = {

  accountNumber: string;
  bank: Bank;
  bankAccountId: string;
}

/*======================organisation models======================*/
export enum OrganisationStatus {
  ACTIVE = "ACTIVE", INACTIVE = "INACTIVE"
}

export type Organisation = {

  avatarUrl: string;
  organisationId: string;
  createdOn: Date;
  updatedOn: Date;
  pinNumber: string;
  organisationName: string;
  location: string;
  organisationStatus: OrganisationStatus;
  merchantId: string;
  redirectUrl: string;
  missedCyclesToPenalty: number;
  loanInterestRate: number;
  loanMonthlyPenaltyRate: number;
  loanDefaultWindowInDays: number;
  loanGracePeriodInDays: number;
  parent: Organisation | null;
}
