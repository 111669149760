

import {defineComponent, PropType} from "vue";
import BaseDropdown from "@/views/components/BaseDropdown.vue";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {InsuranceType} from "@/store/modules/last-expense/baseModels";
import FilterIcon from "@/icons/solid/filter-icon.vue";

export default defineComponent({
  components: {FilterIcon, BaseDropdown},

  props: {

    buttonBgColorWhenDropdownIsOpen: {

      type: String as PropType<string>,
      default: '#dce7f5'
    },

    buttonIconColor: {

      type: String as PropType<string>,
      default: ''
    },

    insuranceFilters: {

      type: Object as PropType<InsuranceType>,
      required: true
    },
    selectedInsurance: {

      type: String as PropType<string>,
      required: true
    }
  },

  setup(props, {emit}) {
    const { ucfirst, stripUnderscores } = useStringUtilities();
    function selectOption(value:string) {

      emit('select-option', value)
    }

    return {

      ucfirst,
      selectOption,
      stripUnderscores,
    };
  }
});

