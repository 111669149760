

import {defineComponent, PropType} from "vue";
import AccountCircle from "@/icons/solid/account-circle.vue";
import Avatar from "@/views/components/display-elements/Avatar.vue";

export default defineComponent({

    components: {Avatar, AccountCircle},
    props: {

        avatarSize: {

            type: Number as PropType<number>,
            default: 44
        },
        avatarUrl: {

            type: String as PropType<string | null>,
            default: null
        }
    }
});

