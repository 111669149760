

import {computed, defineComponent, PropType} from "vue";
import {
  LastExpenseClaim,
  LastExpensePolicyType,
} from "@/store/modules/last-expense/types/models";
import {useRouter} from "vue-router";
import ChevronRight from "@/icons/chevron-right.vue";
import {
    useLastExpenseClaimsUtilities
} from "@/views/pages/insurances/last-expense/claims/useLastExpenseClaimsUtilities";
import StatusComponent from "@/views/components/StatusComponent.vue";
import BaseTable from "@/views/components/BaseTable.vue";

export default defineComponent({
  components: {BaseTable, StatusComponent, ChevronRight},
  props: {

    claims: {

      type: Array as PropType<Array<LastExpenseClaim>>,
      required: true
    },
  },

  setup(props) {

    const router = useRouter();
    const { formatClaim } = useLastExpenseClaimsUtilities();

    const formattedClaims = computed(() => {

      return props.claims.map(claim => {

        return formatClaim(claim);
      });
    });

    function viewRecord(claimId: string) {

      if(claimId) {

        router.push({
          name: 'last-expense-claim',
          params: {claimId: claimId}
        });
      }
    }

    return {

      viewRecord,
      formattedClaims,
      LastExpensePolicyType
    };
  }
});

