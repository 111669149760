import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_eye_off = _resolveComponent("eye-off")!
  const _component_eye = _resolveComponent("eye")!
  const _component_custom_input = _resolveComponent("custom-input")!

  return (_openBlock(), _createBlock(_component_custom_input, {
    "label-text": "Password",
    "current-input-value": _ctx.inputValue,
    error: _ctx.error,
    "input-type": _ctx.showPassord ? 'text' : 'password',
    onNewValue: _cache[1] || (_cache[1] = (val) => _ctx.inputValue = val),
    onKeyDown: _ctx.handleKeyDown
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "d-flex align-items-center justify-content-center mr--2 px-3 border-0 bg-transparent text-muted",
        style: {"cursor":"pointer"},
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args)), ["prevent"]))
      }, [
        (! _ctx.showPassord)
          ? (_openBlock(), _createBlock(_component_eye_off, {
              key: 0,
              style: {"width":"24px"}
            }))
          : (_openBlock(), _createBlock(_component_eye, {
              key: 1,
              style: {"width":"24px"}
            }))
      ])
    ]),
    _: 1
  }, 8, ["current-input-value", "error", "input-type", "onKeyDown"]))
}