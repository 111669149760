import axiosInstance from "@/axios/axios";
import {AxiosResponse} from "axios";
import {useDateUtilities} from "@/hooks/useDateUtilities";

const {formatToServerDateTimeString} = useDateUtilities();

class ChamaCirclesService {

    private static baseUrl = `${process.env.VUE_APP_BASE_URL}/circles/v1`;
    private static instance: ChamaCirclesService;
    private readonly axiosInstance = axiosInstance;

    static singleton = (): ChamaCirclesService => {

        if (!ChamaCirclesService.instance) {

            ChamaCirclesService.instance = new ChamaCirclesService();
        }

        return ChamaCirclesService.instance;
    };

    async fetchChamaContributions(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl + `/chama/contribution/circle/${circleId}`,
            {

                params: {

                    page: page - 1,
                    size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchChamaContribution(contributionId: string):
        Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/contribution/${contributionId}`);
    }

    async fetchChamaDisbursements(
        circleId: string, page: number, size: number):
        Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/disbursement/circle/${circleId}`,

            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchChamaDisbursement(disbursementId: string):
        Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/disbursement/${disbursementId}`);
    }

    async fetchNonPagedApprovalsForDisbursement(
        disbursementId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedApprovalsForDisbursement(
            disbursementId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedApprovalsForDisbursement(
            disbursementId, 1, response.data.totalPages
        );
    }

    async fetchPagedApprovalsForDisbursement(
        disbursementId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/disbursement-approval/disbursement/${disbursementId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedDisbursementApprovalsForApprover(
        circleId: string): Promise<AxiosResponse> {

        const response =
            await this.fetchPagedDisbursementApprovalsForApprover(
                circleId, 1, 1,
            );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedDisbursementApprovalsForApprover(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedDisbursementApprovalsForApprover(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/disbursement-approval/circle/${circleId}`,

            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedChamaLoanGuaranteesForReviewByUser(
        circleId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedChamaLoanGuaranteesForReviewByUser(
            circleId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedChamaLoanGuaranteesForReviewByUser(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedChamaLoanGuaranteesForReviewByUser(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/loanguarantor/circle/${circleId}/member`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedChamaLoanApprovalsForReviewByUser(
        circleId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedChamaLoanApprovalsForReviewByUser(
            circleId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedChamaLoanApprovalsForReviewByUser(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedChamaLoanApprovalsForReviewByUser(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/loanapproval/circle/${circleId}`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedGuarantorsForChamaLoanRequest(
        loanRequestId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedGuarantorsForChamaLoanRequest(
            loanRequestId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedGuarantorsForChamaLoanRequest(
            loanRequestId, 1, response.data.totalPages
        );
    }

    async fetchPagedGuarantorsForChamaLoanRequest(
        loanRequestId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/loanguarantor/loanrequest/${loanRequestId}`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedApprovalsForChamaLoanRequest(
        loanRequestId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedApprovalsForChamaLoanRequest(
            loanRequestId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedApprovalsForChamaLoanRequest(
            loanRequestId, 1, response.data.totalPages
        );
    }

    async fetchPagedApprovalsForChamaLoanRequest(
        loanRequestId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/loanapproval/loanrequest/${loanRequestId}`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchTotalMemberContributionsInCircle(
        circleId: string
    ): Promise<AxiosResponse>  {

        return await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/contribution/circle/${circleId}/total`
        )
    }

    async fetchChamaStats(
        circleId: string,
        startDate: Date | null = null,
        endDate: Date | null = null
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/stats/chama/${circleId}`, {

                params: {

                    startDate: startDate ? formatToServerDateTimeString(startDate) : null,
                    endDate: endDate ? formatToServerDateTimeString(endDate) : null,
                }
            }
        );
    }

    async fetchNonPagedContributionTags(
        circleId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedContributionTags(
            circleId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedContributionTags(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedContributionTags(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/contribution/tags/circle/${circleId}`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchContributionTag(contributionTagId: string):
        Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            ChamaCirclesService.baseUrl +
            `/chama/contribution/tags/${contributionTagId}`);
    }
}

export default ChamaCirclesService;
