
import {defineComponent, PropType, ref, watch} from "vue";
import Errors from "@/views/components/Errors";
import {useValidationUtilities} from "@/hooks/useValidationUtilities";
import {BeneficiaryType} from "@/store/modules/last-expense/types/models";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {useRoute} from "vue-router";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import CustomSelect from "@/views/components/form-elements/CustomSelect.vue";
import {mappers} from "@/store/modules/last-expense/mappers";
import {useStore} from "@/hooks/useStore";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import {AxiosError} from "axios";
import BaseFormModal from "@/views/components/form-modal/BaseFormModal.vue";

export default defineComponent({
  components: {BaseFormModal, CustomSelect},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
  },

  setup(props, {emit}) {

    const {validateStringFieldIsNotEmpty} = useValidationUtilities();
    const { stripUnderscores, ucfirst } = useStringUtilities();
    const { mapToTypedLastExpenseBeneficiary } = mappers();
    const store = useStore();
    const route = useRoute();
    const policyId = route.params.policyId as string;
    const formData = ref(generateInitialFormData());
    const formErrors = ref(new Errors());
    const isSubmitting = ref(false);
    const submissionError = ref<AxiosError | null>(null);

    const beneficiaryTypes = Object.fromEntries(Object.values(BeneficiaryType)
        .map(k => [k, stripUnderscores(ucfirst(k))]));

    function addMemberToPolicy() {

      if(isSubmitting.value) { return; }

      validateFormFields();

      if(formErrors.value.any()) {

        return;
      }

      isSubmitting.value = true;
      submissionError.value = null;

      LastExpenseExperience.singleton().createPolicyBeneficiary(
          policyId,
          Object.assign({
            fullName: formData.value.fullName,
            beneficiaryType: formData.value.beneficiaryType + ""
          }, formData.value.nationalId ?
              { nationalId: formData.value.nationalId } : {})
      ).then(response => {

        store.commit(MutationTypes.SET_POLICY_BENEFICIARIES, {

          policyId: policyId,
          beneficiaries: [mapToTypedLastExpenseBeneficiary(response.data)],
          addToExistingBeneficiaries: true
        });

        close();

      }).catch(error => {

        submissionError.value = error;
        isSubmitting.value = false;
      });
    }

    function validateFormFields() {

      formErrors.value.add('fullName',
          validateStringFieldIsNotEmpty(formData.value.fullName));

      if(formData.value.beneficiaryType !== BeneficiaryType.CHILD) {

        formErrors.value.add('nationalId',
            validateStringFieldIsNotEmpty(formData.value.nationalId));

      } else {

        formErrors.value.add('nationalId', '');
      }
    }

    function close() {

      emit('close');
    }

    function generateInitialFormData() {

      return {

        nationalId: "",
        fullName: "",
        beneficiaryType: BeneficiaryType.CHILD
      }
    }

    watch(() => formData.value.beneficiaryType, (newVal) => {

      if(newVal === BeneficiaryType.CHILD) {

        validateFormFields();
      }

    }, {immediate: true});

    watch(() => props.show, (value) => {

      if(value) {

        formData.value = generateInitialFormData();
        formErrors.value = new Errors();
        isSubmitting.value = false;
        submissionError.value = null;
      }
    });

    return {

      close,
      formData,
      formErrors,
      isSubmitting,
      BeneficiaryType,
      submissionError,
      beneficiaryTypes,
      addMemberToPolicy,
    };
  }
});
