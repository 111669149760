import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 rounded-sm px-3 tw-bg-red-100 d-flex justify-content-between align-items-center",
  style: {"height":"50px"}
}
const _hoisted_2 = { class: "tw-text-red-600 tw-text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x = _resolveComponent("x")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1),
        _createElementVNode("button", {
          class: "d-flex align-items-center justify-content-center mr--3 px-3 border-0 bg-transparent text-danger",
          style: {"cursor":"pointer"},
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }, [
          _createVNode(_component_x, { style: {"width":"20px"} })
        ])
      ]))
    : _createCommentVNode("", true)
}