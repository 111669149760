import {State} from "@/store/modules/last-expense/types/state";

export const state = (): State => ({

    experiences: null,
    privateExperiences: {},
    lastExpenseExperiences: [],
    policies: [],
    dataForPoliciesView: [],
    policyBeneficiaries: {},
    policyPayments: {},
    experiencePlans: {},
    claims: [],
    dataForClaimsView: [],
    claimPayments: {},
    policyClaims: {},
});