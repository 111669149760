
import {computed, defineComponent, PropType} from "vue";
import {LastExpensePlan} from "@/store/modules/last-expense/types/models";
import CheckCircle from "@/icons/solid/CheckCircle.vue";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";

export default defineComponent({
    components: {CheckCircle},

    props: {

        plan: {

            type: Object as PropType<LastExpensePlan>,
            required: true
        },
    },

    setup(props, {emit}) {

        const {formatCurrency} = useCurrencyUtilities();

        const isMultipleClaimPlan =  computed(()=> {
            const plan = props.plan

            let isMultipleClaimPlan = false;
            plan.name.split(" ").forEach(word => {

                if (word.toLowerCase() === 'multiple') {

                    isMultipleClaimPlan = true;
                }
            });

            return isMultipleClaimPlan;
        })

        function selectPlan(planId: string) {

            emit('plan-selected', planId);
        }

        return {

            selectPlan,
            formatCurrency,
            isMultipleClaimPlan
        };
    }
});
