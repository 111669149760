import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation = _resolveComponent("confirmation")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.showModal,
    "fullscreen-mode-below-sm-active": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_confirmation, {
        title: "Logout",
        body: "Confirm to logout of Malipo insurance.",
        "secondary-action": "Cancel",
        "primary-action": "Logout",
        onPrimaryAction: _ctx.logout,
        onSecondaryAction: _ctx.close
      }, null, 8, ["onPrimaryAction", "onSecondaryAction"])
    ]),
    _: 1
  }, 8, ["show"]))
}