
import {computed, defineComponent, PropType} from "vue";
import {LastExpensePolicy} from "@/store/modules/last-expense/types/models";
import {useRouter} from "vue-router";
import {
    useLastExpensePoliciesUtilities
} from "@/views/pages/insurances/last-expense/policies/useLastExpensePoliciesUtilities";
import ChevronRight from "@/icons/chevron-right.vue";

export default defineComponent({
  components: {ChevronRight},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    },
    isUserDashboard: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    onSlider: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props) {

    const router = useRouter();
    const { formatPolicy } = useLastExpensePoliciesUtilities();

    const formattedPolicy = computed(() => {

      return formatPolicy(props.policy, props.isUserDashboard);
    });

    function viewRecord() {

      if (props.onSlider) {

        router.push({
          name: 'member-policy',
          params: {policyId: props.policy.lastExpensePolicyId}
        });
      } else {

        router.push({
          name: 'last-expense-policy',
          params: {policyId: props.policy.lastExpensePolicyId}
        });
      }
    }

    return {

      viewRecord,
      formattedPolicy,
    };
  }
});
