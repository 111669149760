import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw-mt-10 tw-flex tw-flex-col tw-items-center" }
const _hoisted_2 = { class: "tw-text-xl tw-font-semibold tw-text-gray-700" }
const _hoisted_3 = { class: "tw-mt-3" }
const _hoisted_4 = { class: "tw-flex tw-items-center tw-justify-end" }
const _hoisted_5 = { class: "tw-py-10 tw--mt-5" }
const _hoisted_6 = { class: "tw-w-full tw-table-fixed tw-text-sm" }
const _hoisted_7 = { class: "tw-pt-5 tw-text-gray-500 tw-text-right tw-pr-1.5" }
const _hoisted_8 = { class: "tw-pt-5 tw-text-gray-700 tw-pl-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    height: 550,
    "fullscreen-mode-below-sm-active": true,
    title: 'Payment  ' + _ctx.payment.policyPaymentId,
    "show-header-border": true,
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formattedPayment.amount), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-sm tw-text-center tw-px-4 tw-py-3 tw-w-48", ['tw-bg-' + _ctx.formattedPayment.statusColor + '-100',
               'tw-text-' + _ctx.formattedPayment.statusColor + '-700']])
            }, _toDisplayString(_ctx.formattedPayment.formattedStatus), 3)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentDetails, (itemValue, itemName) => {
              return (_openBlock(), _createElementBlock("tr", { key: itemName }, [
                _createElementVNode("td", _hoisted_7, _toDisplayString(itemName) + " : ", 1),
                _createElementVNode("td", _hoisted_8, _toDisplayString(itemValue), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "title", "onClose"]))
}