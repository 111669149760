

import {computed, defineComponent, PropType} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/hooks/useStore";
import useInsuranceViewInfo from "@/views/pages/insurances/useInsuranceViewInfo";

export default defineComponent({
  props: {

    paddingBottom: {

      type: [Number, null] as PropType<number | null>,
      default: null
    },
    showSubTitle: {

      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  setup() {

    const { dashboardInfo } = useInsuranceViewInfo();
    const store = useStore();
    const route = useRoute();
    const experienceId = route.params.experienceId as string;

    const lastExpenseExperience = computed(() => {

      return store.getters.getLastExperience(experienceId);
    });

    const experienceName = computed(() => {

      const experience = lastExpenseExperience.value;

      return experience ? experience.title : '';
    });

    const experienceSub = computed(() => {

      const experience = lastExpenseExperience.value;

      return experience && experience.subTitle ? experience.subTitle : '';
    });

    const subtitle = computed(() => {

      const info = dashboardInfo.value;

      if(info.type === "INDIVIDUAL") {

        return "My policies";
      }

      if(! info.name) {

        return `Group ${info.id} policies`
      }

      return info.name + ' policies';
    });

    return {

      subtitle,
      experienceSub,
      experienceName
    }
  }
});

