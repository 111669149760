
import {defineComponent, nextTick, PropType, ref} from "vue";
import ShareOutline from "@/icons/outlined/share-outline.vue";
import ThumbUp from "@/icons/outlined/thumb-up.vue";

export default defineComponent({
  components: {ThumbUp, ShareOutline},

  props: {

    title: {

      type: String as PropType<string>,
      required: true
    },
    url: {

      type: String as PropType<string >,
      required: true
    },
    subTitle: {

      type: String as PropType<string>,
      default: ''
    },
    buttonText: {
      type: String as PropType<string>,
      required: true
    },
    buttonCopy: {
      type: String as PropType<string>,
      required: true
    },
    showButtonText : {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },

  setup(props) {

    const canShare = ref(true);
    const linkToShare =  {

      title: props.title,
      text: props.title + props.subTitle ?  ' : ' + props.subTitle : '',
      url: props.url
    }
    const linkCopied = ref(false);

    async function shareLink() {

      try {

        if (navigator['canShare'](linkToShare)) {

          await navigator['share'](linkToShare)
        } else {

          copyLink(linkToShare.url)

        }
      } catch {
        copyLink(linkToShare.url)
      }
    }

    function copyLink(link: string) {

      nextTick(() => {

        navigator.clipboard.writeText(link).then( () => {
          linkCopied.value = true;
          setTimeout(() => linkCopied.value = false, 30000);
        })
      });
    }

    return {

      canShare,
      shareLink,
      linkCopied
    };
  }
});
