
import {defineComponent, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/authorization/types";
import {ActionTypes as UsersActionTypes} from "@/store/modules/users/types";
import Spinner from "@/views/components/Spinner.vue";
import Error from "@/icons/solid/error.vue";

export default defineComponent({

  components: { Error, Spinner },

  setup() {

    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const baseLoginPath = "/login";
    const redirectUrlWhenAuthenticated = "home-view";
    const isAuthenticating = ref(true);
    const authenticationError = ref( false);
    const previousRoute = ref(localStorage.getItem('previousRoute'));

    function initialize() {

      if(store.getters.isUserLoggedIn) {

        router.push({name: redirectUrlWhenAuthenticated});
        return;
      }

      const query = route.query;

      if(! ('code' in query)) {

        redirectToAuthenticationPage();
        return;
      }

      store.dispatch(ActionTypes.GET_AUTH_TOKENS_USING_AUTH_CODE, {

        authorizationCode: query.code as string,
        redirectUri: process.env.VUE_APP_CLIENT_BASE_URL + baseLoginPath,
        successCallback: () => {

          store.dispatch(UsersActionTypes.FETCH_USER_INFO, {

            successCallback: () => {

              router.push(previousRoute.value ? previousRoute.value :
                { name: redirectUrlWhenAuthenticated})
                .finally(() => {

                  localStorage.removeItem('previousRoute')
                });
            },

            errorCallback: () => {

              store.dispatch(ActionTypes.LOGOUT_USER);
              authenticationError.value = true;
              isAuthenticating.value = false;
            }
          });
        },

        errorCallback: () => {

          authenticationError.value = true;
          isAuthenticating.value = false;
        }
      });
    }

    function redirectToAuthenticationPage() {

      router.push({name: 'authenticate'});
    }

    initialize();

    return {

      isAuthenticating,
      authenticationError,
      redirectToAuthenticationPage
    };
  },
});
