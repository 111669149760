

import {computed, defineComponent, nextTick, PropType, ref} from "vue";
import {
  LastExpenseClaim,
  LastExpensePolicyPayment,
} from "@/store/modules/last-expense/types/models";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import ClaimPaymentsTable from "@/views/pages/insurances/last-expense/claim/claim-payments/ClaimPaymentsTable.vue";
import ViewClaimPaymentModal
    from "@/views/pages/insurances/last-expense/claim/claim-payments/ViewClaimPaymentModal.vue";

export default defineComponent({
  components: {
      ViewClaimPaymentModal,
      ClaimPaymentsTable,
    Spinner
  },
  props: {

    claim: {

      type: Object as PropType<LastExpenseClaim>,
      required: true
    }
  },

  setup(props) {

    const store = useStore();
    const showViewPaymentModal = ref(false);
    const paymentToView = ref<LastExpensePolicyPayment | null>(null);

    const payments = computed(() => {

      return store.getters.getClaimPayments(props.claim.lastExpenseClaimId);
    });

    function toggleViewPaymentModal(
        show: boolean, payment: LastExpensePolicyPayment | null = null
    ) {

      if(payment) {

        paymentToView.value = payment
      }

      nextTick(() => {

        showViewPaymentModal.value = show;
      });
    }

    function fetchPayments() {

      store.dispatch(ActionTypes.FETCH_CLAIM_PAYMENTS, {

        claimId: props.claim.lastExpenseClaimId,
      });
    }

    function initialize() {

      if(! payments.value) {

        fetchPayments();
      }
    }

    initialize();

    return {

      payments,
      paymentToView,
      showViewPaymentModal,
      toggleViewPaymentModal,
    }
  }
});

