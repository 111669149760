

import {defineComponent, PropType} from "vue";
import X from "@/icons/solid/x.vue";

export default defineComponent({

  components: {X},

  props: {

    title: {

      type: String as PropType<string>,
      required: true
    },

    showCloseButton: {

      type: Boolean as PropType<boolean>,
      default: true
    },

    showBorder: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props, {emit}) {

    function close() {

      emit('close');
    }

    return {

      close
    }
  }
});

