import {MutationTree} from "vuex";
import {
    DataForLastExpenseClaimsView,
    DataForLastExpensePoliciesView,
    State
} from "@/store/modules/last-expense/types/state";
import {Mutations} from "@/store/modules/last-expense/types/mutations";
import {useObjectUtilities} from "@/hooks/useObjectUtilities";

const { updateOrCreateRecord } = useObjectUtilities();

export const mutations: MutationTree<State> & Mutations = {

    SET_EXPERIENCES: (state, payload) => {

        state.experiences = payload.experiences;
    },
    SET_PRIVATE_EXPERIENCES: (state, payload) => {

        if(! state.privateExperiences[payload.circleId]) {

            state.privateExperiences[payload.circleId] = [];
        }

        payload.privateExperiences.forEach(experience => {

            updateOrCreateRecord(experience,
                state.privateExperiences[payload.circleId],
                (recordA, recordB) =>
                    recordA.organisationId === recordB.organisationId
            );
        });
    },
    SET_LAST_EXPERIENCE: (state, payload) => {

        updateOrCreateRecord(payload.data, state.lastExpenseExperiences,
            (recordA, recordB) =>
                recordA.experienceId === recordB.experienceId
        );
    },

    SET_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW: (state, payload) => {

        payload.data.items.forEach(item => {

            updateOrCreateRecord(
                item, state.policies,
                (recordA, recordB) =>
                    recordA.lastExpensePolicyId === recordB.lastExpensePolicyId
            )
        });

        updateOrCreateRecord<Omit<DataForLastExpensePoliciesView, "items"> &
            { items: Array<string> }>(
            structuredClone(Object.assign({}, payload.data, {
                items: payload.data.items.map(item => item.lastExpensePolicyId)
            })),
            state.dataForPoliciesView,
            (viewDataA, viewDataB) =>
                viewDataA.filters.circleId === viewDataB.filters.circleId
        );
    },

    CLEAR_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW: (state, payload) => {

        for(let i = state.dataForPoliciesView.length - 1; i >= 0; i--) {

            if(state.dataForPoliciesView[i].filters.circleId ===
                payload.filters.circleId) {

                state.dataForPoliciesView.splice(i, 1);
            }
        }
    },

    SET_POLICY: (state, payload) => {

        updateOrCreateRecord(payload.data, state.policies,
            (recordA, recordB) =>
                recordA.lastExpensePolicyId === recordB.lastExpensePolicyId
        );
    },

    SET_POLICY_BENEFICIARIES: (state, payload) => {

        if(! payload.addToExistingBeneficiaries) {

            state.policyBeneficiaries[payload.policyId] = payload.beneficiaries;

        } else {

            if(! state.policyBeneficiaries[payload.policyId]) {

                state.policyBeneficiaries[payload.policyId] = [];
            }

            payload.beneficiaries.forEach(beneficiary => {

                updateOrCreateRecord(beneficiary,
                    state.policyBeneficiaries[payload.policyId],
                    (recordA, recordB) =>
                        recordA.lastExpenseBeneficiaryId ===
                        recordB.lastExpenseBeneficiaryId
                );
            });
        }

        if(state.policyBeneficiaries[payload.policyId]) {

            state.policyBeneficiaries[payload.policyId].sort((a, b) =>
                b.createdOn.getTime() - a.createdOn.getTime()
            );
        }
    },

    REMOVE_BENEFICIARY: (state, beneficiaryId) => {

        Object.values(state.policyBeneficiaries).some(beneficiaries => {

            let beneficiaryFound = false;

            for(let i = beneficiaries.length - 1; i >= 0; i--) {

                if(beneficiaries[i].lastExpenseBeneficiaryId === beneficiaryId) {

                    beneficiaryFound = true;
                    beneficiaries.splice(i, 1);
                    break;
                }
            }

            return beneficiaryFound;
        });
    },

    SET_POLICY_PAYMENTS: (state, payload) => {

        if(! payload.addToExistingPayments) {

            state.policyPayments[payload.policyId] = payload.payments;

        } else {

            if(! state.policyPayments[payload.policyId]) {

                state.policyPayments[payload.policyId] = [];
            }

            payload.payments.forEach(payment => {

                updateOrCreateRecord(payment,
                    state.policyPayments[payload.policyId],
                    (recordA, recordB) =>
                        recordA.policyPaymentId === recordB.policyPaymentId
                );
            });
        }

        if(state.policyPayments[payload.policyId]) {

            state.policyPayments[payload.policyId].sort((a, b) =>
                b.createdOn.getTime() - a.createdOn.getTime()
            );
        }
    },

    SET_EXPERIENCE_PLANS: (state, payload) => {

        state.experiencePlans[payload.experienceId] = payload.plans;
    },

    SET_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW: (state, payload) => {

        payload.data.items.forEach(item => {

            updateOrCreateRecord(
                item, state.claims,
                (recordA, recordB) =>
                    recordA.lastExpenseClaimId === recordB.lastExpenseClaimId
            )
        });

        updateOrCreateRecord<Omit<DataForLastExpenseClaimsView, "items"> &
            { items: Array<string> }>(
            structuredClone(Object.assign({}, payload.data, {
                items: payload.data.items.map(item => item.lastExpenseClaimId)
            })),
            state.dataForClaimsView,
            (viewDataA, viewDataB) =>
                viewDataA.filters.circleId === viewDataB.filters.circleId
        );
    },

    CLEAR_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW: (state, payload) => {

        for(let i = state.dataForClaimsView.length - 1; i >= 0; i--) {

            if(state.dataForClaimsView[i].filters.circleId ===
                payload.filters.circleId) {

                state.dataForClaimsView.splice(i, 1);
            }
        }
    },

    SET_CLAIM: (state, payload) => {

        updateOrCreateRecord(payload.data, state.claims,
            (recordA, recordB) =>
                recordA.lastExpenseClaimId === recordB.lastExpenseClaimId
        );
    },

    SET_CLAIM_PAYMENTS: (state, payload) => {

        if(! payload.addToExistingPayments) {

            state.claimPayments[payload.claimId] = payload.payments;

        } else {

            if(! state.claimPayments[payload.claimId]) {

                state.claimPayments[payload.claimId] = [];
            }

            payload.payments.forEach(payment => {

                updateOrCreateRecord(payment,
                    state.claimPayments[payload.claimId],
                    (recordA, recordB) =>
                        recordA.claimPaymentId === recordB.claimPaymentId
                );
            });
        }

        if(state.claimPayments[payload.claimId]) {

            state.claimPayments[payload.claimId].sort((a, b) =>
                b.createdOn.getTime() - a.createdOn.getTime()
            );
        }
    },

    SET_POLICY_CLAIMS: (state, payload) => {

        payload.claims.forEach(targetClaim => {

           const matchingClaim = state.claims.find(claim =>
               claim.lastExpenseClaimId === targetClaim.lastExpenseClaimId);

           if(matchingClaim) {

               Object.assign(matchingClaim, targetClaim);

           } else {

               state.claims.push(targetClaim);
           }
        });

        if(! payload.addToExistingClaims) {

            state.policyClaims[payload.policyId] = payload.claims.map(
                claim => claim.lastExpenseClaimId);

        } else {

            if(! state.policyClaims[payload.policyId]) {

                state.policyClaims[payload.policyId] = [];
            }

            payload.claims.forEach(claim => {

                updateOrCreateRecord(claim.lastExpenseClaimId,
                    state.policyClaims[payload.policyId],
                    (recordA, recordB) => recordA === recordB);
            });
        }
    },
}
