
import {defineComponent, PropType, ref, watch} from "vue";
import {
  LastExpenseBeneficiary, LastExpensePolicy,
  LastExpensePolicyOwner
} from "@/store/modules/last-expense/types/models";
import BaseFormModal from "@/views/components/form-modal/BaseFormModal.vue";
import RemovePolicyMember
    from "@/views/pages/insurances/last-expense/policy/policy-members/policy-member-modal/RemovePolicyMember.vue";
import EditPolicyMember
    from "@/views/pages/insurances/last-expense/policy/policy-members/policy-member-modal/EditPolicyMember.vue";
import ViewPolicyMember
    from "@/views/pages/insurances/last-expense/policy/policy-members/policy-member-modal/ViewPolicyMember.vue";

export default defineComponent({
  components: {BaseFormModal, RemovePolicyMember, EditPolicyMember, ViewPolicyMember},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    member: {

      type: Object as PropType<LastExpensePolicyOwner | LastExpenseBeneficiary>,
      required: true
    },
    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },

  setup(props, {emit}) {

    enum ViewType { VIEW = "VIEW", EDIT = "EDIT", "REMOVE" = "REMOVE"}

    const viewType = ref(ViewType.VIEW);

    function updateViewType(type: ViewType) {

      viewType.value = type;
    }

    function close() {

      emit('close');
    }

    watch(() => props.show, (val) => {

      if(val) {

        viewType.value = ViewType.VIEW;
      }
    });

    return {

      close,
      viewType,
      ViewType,
      updateViewType
    };
  }
});
