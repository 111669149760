

import {computed, defineComponent, PropType, ref, watch} from "vue";
import ChoosePaymentMode from "@/views/components/payment-payout/ChoosePaymentMode.vue";
import Errors from "@/views/components/Errors";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {PaymentCard} from "@/store/modules/users/types";
import ChoosePaymentCard from "@/views/components/payment-payout/make-payment-modal/ChoosePaymentCard.vue";
import {
  LastExpensePolicy,
} from "@/store/modules/last-expense/types/models";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {mappers} from "@/store/modules/last-expense/mappers";
import {useStore} from "@/hooks/useStore";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import {AxiosError} from "axios";
import BaseFormModal from "@/views/components/form-modal/BaseFormModal.vue";
import ConfirmPaymentDetails
    from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/ConfirmPaymentDetails.vue";
import PaymentSuccessHandler from "@/views/pages/insurances/shared/PaymentSuccessHandler.vue";
import {PaymentMode} from "@/store/types/baseModels";

export default defineComponent({

  components: {
      PaymentSuccessHandler,
      ConfirmPaymentDetails,
    BaseFormModal,
    ChoosePaymentCard,
    ChoosePaymentMode,
  },

  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    },
  },

  setup(props, {emit}) {

    enum STEPS {

      CHOOSE_PAYMENT_MODE = "CHOOSE_PAYMENT_MODE",
      CHOOSE_PAYMENT_CARD = "CHOOSE_PAYMENT_CARD",
      CONFIRM_DETAILS = "CONFIRM_DETAILS",
      HANDLE_SUCCESS = "HANDLE_SUCCESS"
    }

    const store = useStore();
    const { mapToTypedLastExpensePolicyPayment } = mappers();
    const { uuidv4 } = useStringUtilities();
    const supportedPaymentModes = [PaymentMode.MPESA, PaymentMode.WALLET, PaymentMode.CARD];
    const currentStep = ref<STEPS>(STEPS.CHOOSE_PAYMENT_MODE);
    const formData = ref<{
      paymentMode: PaymentMode,
      selectedPaymentCard: PaymentCard | null
    }>({
      paymentMode: PaymentMode.MPESA,
      selectedPaymentCard: null
    });
    const isSubmitting = ref(false);
    const submissionError = ref<AxiosError | null>(null);
    const formErrors = ref(new Errors());

    const secondaryButtonText = computed(() => {

      if(currentStep.value === STEPS.HANDLE_SUCCESS) {

        return '';
      }

      return currentStep.value === STEPS.CHOOSE_PAYMENT_MODE ? 'Cancel' : 'Previous';
    });

    const primaryButtonText = computed(() => {

      if(currentStep.value === STEPS.HANDLE_SUCCESS) {

        return 'Close';
      }

      return currentStep.value === STEPS.CONFIRM_DETAILS ? 'Submit' : 'Next'
    });

    const primaryButtonIsDisabled = computed(() => {

      return false;
    });

    function updatePaymentCard(value: PaymentCard) {

      formData.value.selectedPaymentCard = value;
    }

    function handlePrevious() {

      if(currentStep.value === STEPS.CHOOSE_PAYMENT_MODE) {

        close();

      } else if(currentStep.value === STEPS.CHOOSE_PAYMENT_CARD) {

        currentStep.value = STEPS.CHOOSE_PAYMENT_MODE;

      } else if(currentStep.value === STEPS.CONFIRM_DETAILS) {

        submissionError.value = null;

        if(formData.value.paymentMode === PaymentMode.CARD) {

          currentStep.value = STEPS.CHOOSE_PAYMENT_CARD;

        } else {

          currentStep.value = STEPS.CHOOSE_PAYMENT_MODE;
        }
      }
    }

    function handleNext() {

      if(currentStep.value === STEPS.CHOOSE_PAYMENT_MODE) {

        if(formData.value.paymentMode === PaymentMode.CARD) {

          currentStep.value = STEPS.CHOOSE_PAYMENT_CARD;

        } else {

          currentStep.value = STEPS.CONFIRM_DETAILS;
        }

      } else if(currentStep.value === STEPS.CHOOSE_PAYMENT_CARD) {

        if(formData.value.selectedPaymentCard) {

          currentStep.value = STEPS.CONFIRM_DETAILS;
        }

      } else if(currentStep.value === STEPS.CONFIRM_DETAILS) {

        makePolicyPayment();

      } else if(currentStep.value === STEPS.HANDLE_SUCCESS) {

        close();
      }
    }

    function updatePaymentMode(value: PaymentMode) {

      formData.value.paymentMode = value;
    }

    function makePolicyPayment() {

      if(isSubmitting.value) { return; }

      submissionError.value = null;
      isSubmitting.value = true;
      const card = formData.value.selectedPaymentCard;
      const billingAddress = card ? (card.token + ";" + uuidv4()) : "";

      LastExpenseExperience.singleton().makePolicyPayment(
          props.policy.lastExpensePolicyId,
          {billingAddress, paymentMode: formData.value.paymentMode}
      ).then(response => {

        store.commit(MutationTypes.SET_POLICY_PAYMENTS, {
          policyId: props.policy.lastExpensePolicyId,
          payments: [mapToTypedLastExpensePolicyPayment(response.data)],
          addToExistingPayments: true
        });

        currentStep.value = STEPS.HANDLE_SUCCESS;

      }).catch(error => {

        submissionError.value = error;

      }).finally(() => {

        isSubmitting.value = false;
      });
    }

    function handleSuccess() {

      emit('success');
    }

    function close() {

      if(currentStep.value === STEPS.HANDLE_SUCCESS) {

        handleSuccess();
      }

      emit('close');
    }

    watch(() => props.show, (value) => {

      if(value) {

        currentStep.value = STEPS.CHOOSE_PAYMENT_MODE;
        formData.value = {

          paymentMode: PaymentMode.MPESA,
          selectedPaymentCard: null
        }
        isSubmitting.value = false;
        submissionError.value = null;
        formErrors.value = new Errors();
      }
    });

    return {

      close,
      STEPS,
      formData,
      handleNext,
      formErrors,
      currentStep,
      isSubmitting,
      handlePrevious,
      submissionError,
      updatePaymentCard,
      updatePaymentMode,
      primaryButtonText,
      secondaryButtonText,
      supportedPaymentModes,
      primaryButtonIsDisabled,

    };
  }
});

