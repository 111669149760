import {ActionTree} from "vuex";
import {RootState} from "@/store";
import {Actions, MutationTypes, State} from "@/store/modules/users/types";
import UsersService from "@/services/users/UsersService";
import {mappers as baseMappers} from "@/store/baseMappers";
import {RawPaymentCard, utilities as usersUtilities} from "@/store/modules/users/utilities";
import {storeUtilities} from "@/store/storeUtilities";
import {TransactionInfo} from "@/store/types/baseModels";
import {RawTransactionInfo} from "@/store/baseMappers";

const {
    mapToTypedTransactionInfo,
} = baseMappers();

const {
    mapToTypedUserInfo,
    mapToTypedWalletInfo,
    mapToTypedPaymentCard
} = usersUtilities();

const {
    fetchMoreViewData
} = storeUtilities();

export const actions: ActionTree<State, RootState> & Actions = {

    FETCH_USER_INFO: (ctx,
                                payload = {}) => {

        UsersService.singleton().fetchLoggedInUserInfo().then(response => {

            const userInfo = mapToTypedUserInfo(response.data);

            ctx.commit(MutationTypes.SET_USER_INFO, userInfo);

            if(payload.successCallback) {

                payload.successCallback(userInfo);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        })
    },

    FETCH_USER_WALLET_INFO: (ctx,
                                payload = {}) => {

        UsersService.singleton().fetchLoggedInUserWalletInfo().then(response => {

            const walletInfo = mapToTypedWalletInfo(response.data);

            ctx.commit(MutationTypes.SET_USER_WALLET_INFO, walletInfo);

            if(payload.successCallback) {

                payload.successCallback(walletInfo);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    UPDATE_LOGGED_IN_USER_INFO: (ctx,
                                  payload) => {

        UsersService.singleton().updateLoggedInUserInfo(
            payload.firstName, payload.lastName, payload.idNumber
        ).then(response => {

            const userInfo = mapToTypedUserInfo(response.data);

            const existingUserInfo = ctx.getters.getUserInfo;
            if(existingUserInfo) {

                userInfo.avatarURL = existingUserInfo.avatarURL;
            }

            ctx.commit(MutationTypes.SET_USER_INFO, userInfo);

            if(payload.successCallback) {

                payload.successCallback(userInfo);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_USER_PAYMENT_CARDS: (ctx,
                               payload = {}) => {

        UsersService.singleton().fetchUserPaymentCards().then(response => {

            const paymentCards = response.data.map(
                (paymentCard: RawPaymentCard) =>
                    mapToTypedPaymentCard(paymentCard)
            );

            ctx.commit(MutationTypes.SET_USER_PAYMENT_CARDS, {

                data: paymentCards
            });

            if(payload.successCallback) {

                payload.successCallback(paymentCards);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_USER_SUPPORTED_BANKS: (ctx,
                                 payload = {}) => {

        UsersService.singleton().fetchSupportedBanks().then(response => {

            const banks = response.data;

            ctx.commit(MutationTypes.SET_USER_SUPPORTED_BANKS, {

                data: banks
            });

            if(payload.successCallback) {

                payload.successCallback(banks);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_USER_BANK_ACCOUNTS: (ctx,
                               payload = {}) => {

        UsersService.singleton().fetchUserBankAccounts().then(response => {

            const bankAccounts = response.data;

            ctx.commit(MutationTypes.SET_USER_BANK_ACCOUNTS, {

                data: bankAccounts
            });

            if(payload.successCallback) {

                payload.successCallback(bankAccounts);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_DATA_FOR_USER_TRANSACTIONS_VIEW: (ctx,
                                              payload ) => {

        const defaultPageSize = 25;

        fetchMoreViewData<TransactionInfo, RawTransactionInfo>({

            defaultPageSize,
            viewData: ctx.getters.getDataForUserTransactionsView,
            mapper: mapToTypedTransactionInfo,
            comparator: (firstItem,
                         secondItem) => {

                return firstItem.transactionRef === secondItem.transactionRef;
            },
            service: (pageToFetch) => {

                return UsersService.singleton()
                    .fetchTransactions(
                        defaultPageSize,
                        pageToFetch,
                        null,
                        null
                    );
            }

        }).then(response => {

            const data = {filters: payload.filters, ...response};

            ctx.commit(MutationTypes.SET_DATA_FOR_USER_TRANSACTIONS_VIEW, {

                data,
            });

            if(payload.successCallback) { payload.successCallback(data); }

        }).catch(error => {

            if(payload.errorCallback) { payload.errorCallback(error); }
        });
    },
}
