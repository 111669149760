

import {defineComponent, ref} from "vue";
import Carousel from "@/views/components/layout/Carousel.vue";
import InsuranceSection from "@/views/pages/home-view/InsuranceSection.vue";
import ActiveInsurances from "@/views/pages/home-view/ActiveInsurances.vue";
import MoreInfoSection from "@/views/pages/home-view/MoreInfoSection.vue";
import CustomFooter from "@/views/components/layout/CustomFooter.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent ({
  components: {CustomNavbar, CustomFooter, MoreInfoSection, ActiveInsurances, InsuranceSection, Carousel},

  setup() {

    const insuranceSection = ref<HTMLElement | null> (null);

    const insuranceImages = [
      {
        image: '/Insurance-1.jpeg' ,
        caption: 'Affordable Last expense insurance',
        subtitle: 'Ease the burden on your loved ones during a difficult time. Secure their future with our affordable and comprehensive final expense insurance.',
        link: ''
      },{
        image: '/private-insurance.jpeg' ,
        caption: 'Custom private insurances',
        subtitle: 'Access private insurances offered by an organisation, circle or company only to a specific group of people( employees, friends or shareholders).',
        link: 'private-insurance'
      },{
        image: '/Insurance-4.jpeg' ,
        caption: 'Secure your health and your future',
        subtitle: 'Choose our reliable medical insurance for the care and coverage you deserve.',
        link: ''
      },{
        image: '/Insurance-2.jpeg' ,
        caption: 'Drive with confidence and peace of mind',
        subtitle: 'Protect your vehicle with our comprehensive insurance coverage',
        link: ''
      },{
        image: '/Insurance-3.jpeg' ,
        caption: 'Provide your loved ones with financial security.',
        subtitle: 'Our affordable last expense insurance ensures a dignified farewell.',
        link: ''
      },
    ];

    function scrollTarget() {

      if(insuranceSection.value) {

        window.scrollTo({
          top: insuranceSection.value.offsetTop,
          behavior: 'smooth'
        });
      }
    }

    return {

      scrollTarget,
      insuranceImages,
      insuranceSection
    };
  }
})
