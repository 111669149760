import {isValidPhoneNumber} from "libphonenumber-js/max";

export const useValidationUtilities = () => {

    function checkIsNumber(value: string) {

        return !isNaN(+value) && ! isNaN(parseFloat(value));
    }

    function checkIsInteger(value: string) {

        return checkIsNumber(value) && Number.isInteger(+value);
    }

    function validateIsNumber(stringValue: string, isInteger = true,
        minValue: number | null = null, maxValue: number | null = null) {

        const value = stringValue.trim();

        if(! value.length) {

            return 'Required';
        }

        const parsedValue = isInteger ? parseInt(value, 10) : parseFloat(value);

        const numberValidator = isInteger ? checkIsInteger : checkIsNumber;

        if(minValue !== null && maxValue !== null) {

            if(! numberValidator(value) || parsedValue < minValue || parsedValue > maxValue) {

                return `Should be ` + (isInteger ? 'a number' : '') +
                    ` between ${minValue} and ${maxValue}`;
            }
        }

        if(minValue !== null) {

            if(! numberValidator(value) || parsedValue < minValue) {

                return `Should be ` + (isInteger ? 'a number' : '') +
                    ` greater than or equal to ${minValue}`;
            }
        }

        if(maxValue !== null) {

            if(! numberValidator(value) || parsedValue > maxValue) {

                return `Should be ` + (isInteger ? 'a number' : '') +
                    ` less than or equal to ${maxValue}`;
            }
        }

        if(! numberValidator(value)) {

            return `Should be ` + (isInteger ? 'an integer' : 'a number');
        }

        return '';
    }

    function validateStringFieldIsNotEmpty(value: string) {

        if(! value.length) {

            return "Required";
        }

        return "";
    }

    function validatePhoneNumber(phoneNumber: string) {

        const errorMessage = validateStringFieldIsNotEmpty(phoneNumber);

        if(errorMessage) {

            return errorMessage;
        }

        return isValidPhoneNumber(phoneNumber, 'KE') ?
                '' : 'Invalid phone number';
    }

    return {

        checkIsNumber,
        checkIsInteger,
        validateIsNumber,
        validatePhoneNumber,
        validateStringFieldIsNotEmpty
    };
}