import {ActionTree} from "vuex";
import {RootState} from "@/store";
import {Actions, ActionTypes, AugmentedActionContext, MutationTypes, State} from "@/store/modules/authorization/types";
import {MutationTypes as UsersMutationTypes} from "@/store/modules/users/types"
import AuthService from "@/services/authorization/AuthService";
import axios, {AxiosError, AxiosResponse} from "axios";

export async function submitRequestWithAppAccessToken(
    ctx: AugmentedActionContext,
    callbackFunction: (appAccessToken: string) => Promise<AxiosResponse>
) {

  let appAuthToken = ctx.getters.getAppAuthToken;

  try {

     return await callbackFunction(appAuthToken ? appAuthToken : '');

  } catch (requestError) {

    if(axios.isAxiosError(requestError)) {

      const error = requestError as AxiosError;

      if(! error.response || error.response.status != 401) {

        throw requestError;
      }
    }

    try {

      await ctx.dispatch(ActionTypes.FETCH_APP_AUTH_TOKEN);

      appAuthToken = ctx.getters.getAppAuthToken;

      return callbackFunction(appAuthToken ? appAuthToken : '')
          .then(response_1=>  response_1);

    } catch (appAuthTokenError) {

      throw requestError;
    }
  }
}

export const actions: ActionTree<State, RootState> & Actions = {

  GET_AUTHORIZATION_TOKENS_USING_CODE: (ctx,
                                        payload) => {

    AuthService.singleton().getAuthTokensUsingAuthorizationCode(

        'authorization_code', payload.authorizationCode, payload.redirectUri

    ).then(response => {

      const accessToken = response.data.access_token || null;
      const refreshToken = response.data.refresh_token || null

      ctx.dispatch(ActionTypes.UPDATE_AUTHORIZATION_TOKENS, {

        accessToken, refreshToken
      });

      if(payload.successCallback) {

        payload.successCallback(accessToken, refreshToken);
      }

    }).catch(error => {

      if(payload.errorCallback) {

        payload.errorCallback(error);
      }
    });
  },

  REFRESH_AUTH_TOKENS: (ctx, payload)  => {

    AuthService.singleton().refreshAuthTokens('refresh_token', payload.refreshToken)
        .then(response => {

          const accessToken = response.data.access_token;
          const refreshToken = response.data.refresh_token

          ctx.dispatch(ActionTypes.UPDATE_AUTHORIZATION_TOKENS, {accessToken, refreshToken});

          if(payload.successCallback) {

            payload.successCallback({accessToken, refreshToken});
          }

        }).catch(error => {

      if(payload.errorCallback) {

        payload.errorCallback(error);
      }
    });
  },

  UPDATE_AUTHORIZATION_TOKENS: (ctx,
                                payload) => {

    payload.accessToken ? localStorage.setItem("accessToken", payload.accessToken) : localStorage.removeItem("accessToken");

    payload.refreshToken ? localStorage.setItem("refreshToken", payload.refreshToken) : localStorage.removeItem("refreshToken");

    ctx.commit(MutationTypes.SET_ACCESS_TOKEN, payload.accessToken);
    ctx.commit(MutationTypes.SET_REFRESH_TOKEN, payload.refreshToken);
  },

  LOGOUT_USER: (ctx) => {

    ctx.dispatch(ActionTypes.UPDATE_AUTHORIZATION_TOKENS, {

      accessToken: null,
      refreshToken: null
    });

    ctx.commit(UsersMutationTypes.SET_USER_INFO, null);
  },

  FETCH_APP_AUTH_TOKEN: (ctx) => {

    return AuthService.singleton().getAuthTokenUsingClientIdAndSecret().then(response => {

      const accessToken = response.data.access_token;

      ctx.commit(MutationTypes.SET_APP_AUTH_TOKEN, accessToken);

      sessionStorage.setItem("appAuthToken", accessToken);

      return response;
    });
  },

  REQUEST_USER_REGISTRATION: (
      ctx, payload) => {

    return submitRequestWithAppAccessToken(ctx,appAccessToken => {

      return AuthService.singleton().requestRegistration(
          payload.data, appAccessToken
      )
    });
  },

  VALIDATE_USER_REGISTRATION: (
      ctx, payload) => {

    return submitRequestWithAppAccessToken(ctx,appAccessToken => {

      return AuthService.singleton().validateRegistration(
          payload.email, payload.otp, appAccessToken
      );
    });
  },

  RESEND_USER_REGISTRATION_VALIDATION_CODE: (
      ctx, payload) => {

    return submitRequestWithAppAccessToken(ctx,appAccessToken => {

      return AuthService.singleton().resendVerificationCodeDuringRegistration(
          payload.email, payload.otpChannel, appAccessToken
      );
    });
  },
};
