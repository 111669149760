import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "mx--4 p-4 d-flex justify-content-between border-bottom",
  style: {"cursor":"pointer"}
}
const _hoisted_2 = { class: "mp-text-sm" }
const _hoisted_3 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_radio = _resolveComponent("custom-radio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.paymentModeName), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_custom_radio, { checked: _ctx.checked }, null, 8, ["checked"])
    ])
  ]))
}