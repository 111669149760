import {GetterTree} from "vuex";
import {RootState} from "@/store";
import {State} from
      "@/store/modules/circles/types/state";
import {Getters} from "@/store/modules/circles/types/getters";
import {allCirclesUtilities} from "@/store/modules/circles/allCirclesUtilities";

const {
    checkFiltersForCircleWithdrawalsViewAreEqual
} = allCirclesUtilities();

export const getters: GetterTree<State, RootState> & Getters = {

    getDataForUserCirclesView: state => filters => {

        const matchingViewData = state.dataForUserCirclesView.find(
            viewData => {

                return viewData.filters.type === filters.type;
        });

        if(! matchingViewData) { return null; }

        return Object.assign({}, matchingViewData, {

            items: state.userCircles[filters.type].filter(
                record => matchingViewData.items.includes(record.circleId)
            ).sort((a, b) =>
                b.createdOn.getTime() - a.createdOn.getTime()
            )
        });
    },

    getUserCircle: state => circleId => {

        let matchingUserCircle = null;

        Object.values(state.userCircles).forEach(circles => {

            const userCircle = circles.find(circle => circle.circleId === circleId);

            if(userCircle) {

                matchingUserCircle = userCircle;
            }
        });

        return matchingUserCircle ? matchingUserCircle : null
    },

    getDataForCircleTransactionsView: state => filters => {

        const matchingViewData = state.dataForTransactionsView.find(
            viewData => {

                return checkFiltersForCircleWithdrawalsViewAreEqual(
                    viewData.filters, filters
                );
            });

        if(! matchingViewData) { return null; }

        return Object.assign({}, matchingViewData, {

            items: state.transactions.filter(
                record => matchingViewData.items.includes(record.transactionRef)
            )
        });
    },

    getCircleTransaction: state => transactionRef => {

        const matchingTransaction = state.transactions.find(transaction => {

            return transaction.transactionRef === transactionRef;
        });

        return matchingTransaction ? matchingTransaction : null;
    },

    getCircleMembers: state => (circleId) => {

        const hasCircleMembersBeenFetched =
            state.circleIdsForFetchedMembers.find(id => {

                return id === circleId;
            })

        if(! hasCircleMembersBeenFetched) { return null; }

        return state.members.filter(member => {

            return member.circleId === circleId;
        });
    },

    getCircleBeneficiary: state => beneficiaryId => {

        const matchingBeneficiary = state.beneficiaries.find(beneficiary => {

            return beneficiary.beneficiaryId === beneficiaryId;
        });

        return matchingBeneficiary ? matchingBeneficiary : null;
    },

    getCircleBeneficiaries: state => (circleId) => {

        const hasCircleBeneficiariesBeenFetched =
            state.circleIdsForFetchedBeneficiaries.find(id => id === circleId);

        if(! hasCircleBeneficiariesBeenFetched) { return null; }

        return state.beneficiaries.filter(
            beneficiary => beneficiary.circleId === circleId
        );
    },
}
