export const useCurrencyUtilities = () => {

    const formatCurrency = (amount: number, fixed = 2) => {

        return amount.toFixed(fixed)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return {

        formatCurrency
    };
}