

import {computed, defineComponent, PropType, ref} from "vue";
import NavbarLink from "@/views/components/layout/navbar/NavbarLink.vue";
import BackButton from "@/views/components/BackButton.vue";
import {RouteLocationRaw, useRoute} from "vue-router";
import {useStore} from "@/hooks/useStore";
import MalipoLogo from "@/icons/malipo-logo.vue";
import NavbarDropdown from "@/views/components/layout/navbar/NavbarDropdown.vue";
export default defineComponent({
  components: {NavbarDropdown, MalipoLogo, BackButton,NavbarLink},
  props: {

    fixed: {

      type: String as PropType<string>,
      default: 'md'
    },
    persistLogo: {

      type: String as PropType<string>,
      default: 'md'
    },
    expandMenu: {

      type: String as PropType<string>,
      default: 'md'
    },
    title: {

      type: String as PropType<string>,
      default: ''
    },
    showBackButton: {

      type: Boolean as PropType<boolean>,
      default: true
    },
    backButtonRoute: {

      type: [Object, null] as PropType<RouteLocationRaw>,
      default: null
    },
    headerBackground: {
        type: String as PropType<string>,
        default: 'tw-bg-white'
    }
  },
  setup() {

    const store = useStore();
    const route = useRoute();
    const isPoliciesRoute = route.name === 'last-expense-view';
    const showDropdown= ref(false);

    const isUserLoggedIn = computed( () =>{

      return store.getters.isUserLoggedIn;
    });

    const userInfo = computed(() => {

      return store.getters.getUserInfo;
    });

    const userFirstName = computed(() => {

      return userInfo.value ? userInfo.value?.firstName : 'User';
    });

    const userAvatar = computed(() => {

      return userInfo.value ? userInfo.value?.avatarURL : '';
    });

    return {

      userAvatar,
      showDropdown,
      userFirstName,
      isUserLoggedIn,
      isPoliciesRoute
    };
  }
});

