import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.route ? 'router-link': 'button'), {
    to: _ctx.route,
    style: {"color":"white"},
    class: _normalizeClass(["link-opacity py-1 tw-font-medium bg-transparent border-0", {'tw-font-semibold' : _ctx.linkIsActive}]),
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.text), 1)
      ], true),
      _renderSlot(_ctx.$slots, "navLink", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["to", "class", "onClick"]))
}