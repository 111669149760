import emitter from "@/event-bus/eventBus";
import {ref} from "vue";

export default function usePopstateNavigation() {

    const isAwaitingNavigationResolution = ref(true);

    let callbackFn: ((isPopstateNavigation: boolean) => void) | null = null;

    function eventHandler(isPopstateNavigation: boolean) {

        if(callbackFn) { callbackFn(isPopstateNavigation); }

        isAwaitingNavigationResolution.value = false;

        emitter.off('popstateNavigation', eventHandler);
    }

    function initialize(callback: (isPopstateNavigation: boolean) => void) {

        callbackFn = callback;

        emitter.on('popstateNavigation', eventHandler);

        setTimeout(() => {

            if(isAwaitingNavigationResolution.value) {

                eventHandler(false);
            }

        }, 100);
    }

    return {

        initialize,
        isAwaitingNavigationResolution
    }
}