

import {defineComponent} from "vue";
import Facebook from "@/icons/social/facebook.vue";
import Twitter from "@/icons/social/twitter.vue";
import MalipoLogo from "@/icons/malipo-logo.vue";
import LocationPin from "@/icons/outlined/location-pin.vue";
import Phone from "@/icons/outlined/phone.vue";
import Mail from "@/icons/outlined/mail.vue";
export default defineComponent( {

    components: {Mail, Phone, LocationPin, MalipoLogo, Twitter, Facebook}

})
