import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ed5ce94"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "tw-text-xs"
}
const _hoisted_2 = {
  key: 3,
  class: "tw-text-sm share-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thumb_up = _resolveComponent("thumb-up")!
  const _component_share_outline = _resolveComponent("share-outline")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      class: _normalizeClass(["tw-rounded-full tw-flex tw-p-2 tw-shrink-0 tw-justify-center tw-items-center hover:tw-bg-blue-200", _ctx.linkCopied ? 'tw-text-green-600' : 'text-primary']),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.shareLink && _ctx.shareLink(...args)))
    }, [
      (_ctx.linkCopied)
        ? (_openBlock(), _createBlock(_component_thumb_up, {
            key: 0,
            class: "tw-h-6 tw-w-6"
          }))
        : (_openBlock(), _createBlock(_component_share_outline, {
            key: 1,
            class: "tw-h-6 tw-w-6"
          })),
      (_ctx.linkCopied)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.buttonCopy), 1))
        : (_ctx.showButtonText)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.buttonText), 1))
          : _createCommentVNode("", true)
    ], 2)
  ]))
}