import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_payment_mode_option = _resolveComponent("payment-mode-option")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportedPaymentModes, (paymentMode) => {
      return (_openBlock(), _createBlock(_component_payment_mode_option, {
        key: paymentMode,
        "payment-mode-name": paymentMode,
        checked: _ctx.selectedPaymentMode === paymentMode,
        onClick: ($event: any) => (_ctx.selectPaymentMode(paymentMode))
      }, null, 8, ["payment-mode-name", "checked", "onClick"]))
    }), 128))
  ]))
}