
import {computed, defineComponent, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import {LastExpensePolicyStatus} from "@/store/modules/last-expense/types/models";
import BackButton from "@/views/components/BackButton.vue";
import Trash from "@/icons/outlined/trash.vue";
import PolicyHeader from "@/views/pages/insurances/last-expense/policy/policy-header/PolicyHeader.vue";
import PolicyDetails from "@/views/pages/insurances/last-expense/policy/PolicyDetails.vue";
import PolicyMembers from "@/views/pages/insurances/last-expense/policy/policy-members/PolicyMembers.vue";
import PolicyPayments from "@/views/pages/insurances/last-expense/policy/policy-payments/PolicyPayments.vue";
import PolicyClaims from "@/views/pages/insurances/last-expense/policy/policy-claims/PolicyClaims.vue";
import CancelPolicyModal from "@/views/pages/insurances/last-expense/policy/cancel-policy/CancelPolicyModal.vue";
import PoliciesHeader from "@/views/pages/insurances/last-expense/policies/PoliciesHeader.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
  components: {
    CustomNavbar,
    PoliciesHeader,
      CancelPolicyModal,
      PolicyClaims,
      PolicyPayments,
      PolicyMembers,
      PolicyDetails,
      PolicyHeader,
      Trash,
    BackButton, Spinner
  },
  setup() {

    const store = useStore();
    const route = useRoute();
    const policyId = route.params.policyId as string;
    const showCancelPolicyModal = ref( false);

    const sliderPolicyRoute = route.name === 'member-policy';

    const policiesRoute = {

      name: sliderPolicyRoute ? 'my-policies' : 'last-expense-policies'
    };

    const policy = computed(() => {

      return store.getters.getPolicy(policyId);
    });

    const policyPayments = computed(() => {

      return store.getters.getPolicyPayments(policyId);
    });

    const hasFetchedAllData = computed(() => {

      return policy.value && policyPayments.value;
    });

    const policyStatus = computed(() => {

      const status = policy.value?.policyStatus;
      return status ? status : LastExpensePolicyStatus.PENDING;
    });

    const isPendingSubmission = computed(() => {

      return policy.value?.policyStatus === LastExpensePolicyStatus.PENDING;
    });

    function fetchPayments() {

      store.dispatch(ActionTypes.FETCH_POLICY_PAYMENTS, {

        policyId: policyId,
      });
    }

    function fetchPolicy() {

      store.dispatch(ActionTypes.FETCH_POLICY, {

        policyId: policyId,
      });
    }

    function toggleCancelPolicyModal(show: boolean){

      showCancelPolicyModal.value = show;
    }

    function initialize() {

      if(! policy.value) {

        fetchPolicy();
      }

      if(! policyPayments.value) {

        fetchPayments();
      }
    }

    initialize();

    return {

      policy,
      policyId,
      policyStatus,
      policiesRoute,
      policyPayments,
      sliderPolicyRoute,
      hasFetchedAllData,
      LastExpensePolicyStatus,
      toggleCancelPolicyModal,
      isPendingSubmission,
      showCancelPolicyModal
    };
  }
});
