import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "mr-1" }
const _hoisted_3 = {
  class: "d-inline-block tw-text-lg text-truncate text-primary",
  style: {"min-width":"1px","max-width":"120px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_member_avatar = _resolveComponent("member-avatar")!
  const _component_dots_vertical = _resolveComponent("dots-vertical")!
  const _component_navbar_link = _resolveComponent("navbar-link")!

  return (_openBlock(), _createBlock(_component_navbar_link, null, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_member_avatar, {
            "avatar-size": 26,
            "avatar-url": _ctx.userAvatar
          }, null, 8, ["avatar-url"])
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.userFirstName), 1),
        _createVNode(_component_dots_vertical, { class: "text-primary ml-1 tw-w-4 tw-h-4" })
      ])
    ]),
    _: 1
  }))
}