import {BeneficiaryStatus} from "@/store/modules/last-expense/types/models";

export type Member = {

    accountId: string;
    authorities: Array<string>;
    avatarURL: string | null;
    email: string;
    firstName: string;
    fullName: string;
    idNumber: string;
    lastName: string;
    phoneNumber: string;
    userId: string;
};

export type MemberCircle = {

    circle?: Circle;
    circleId: string;
    createdOn: Date;
    hasLeft: boolean;
    isAdmin: boolean;
    memberDetails: Member;
    memberId: string;
    updatedOn: Date;
    additionalData: AdditionalData;
    roles: MemberRoles;
};

export type AdditionalData = {

    [key: string]: string
}

export type MemberRoles = Array<string>;

export enum CircleType {

    CHAMA = "CHAMA"
}

export enum MembershipType {
    INVITE_ONLY = "INVITE_ONLY", REQUEST_TO_JOIN = "REQUEST_TO_JOIN",
    OPEN_TO_JOIN = "OPEN_TO_JOIN"
}

export enum CircleVisibility {
    PRIVATE = "PRIVATE", PUBLIC = "PUBLIC"
}

export enum CircleStatus {
    ACTIVE = "ACTIVE", INACTIVE = "INACTIVE"
}

export enum ContributionType {
    NORMAL = "NORMAL", OPENING_BALANCE = "OPENING_BALANCE", MANUAL = "MANUAL"
}

export enum ContributionCycle {
    DAILY = "DAILY", WEEKLY = "WEEKLY", MONTHLY = "MONTHLY"
}

export enum DisbursementCategory {
    MEMBER= "MEMBER", EXPENSE= "EXPENSE"
}

export type Circle = {

    createdOn: Date;
    updatedOn: Date;
    circleId: string;
    name: string;
    description: string;
    avatarUrl: string | null;
    walletId: string;
    circleStatus: CircleStatus;
    circleType: CircleType;
    membershipType: MembershipType;
    circleVisibility: CircleVisibility;
}

export type ChamaCircle = Circle & {

    issuesLoans: boolean;
    supportsGuarantors: boolean;
    loanPenaltyActive: boolean;
    loanType: string;
    minimumCycleContributionAmount: number;
    contributionPenalty: number;
    missedCyclesToPenalty: number;
    totalContributions: number;
    loanInterestRate: number;
    loanMonthlyPenaltyRate: number;
    loanDefaultWindowInDays: number;
    loanGracePeriodInDays: number;
    cycleStart: Date;
    contributionCycle: ContributionCycle;
    loanApprovers: number;
    disbursementApprovers: number;
    withdrawalApprovers: number;
    nextContributionDate: Date;
}

export type ChamaMemberCircle = Omit<MemberCircle, "circle"> & { circle: ChamaCircle };

export type Beneficiary = {

    bankAccounts: Array<string>;
    beneficiaryId: string;
    beneficiaryStatus: BeneficiaryStatus;
    circleId: string;
    createdOn: Date;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    updatedOn: Date;
}

export type CircleStats = Record<string, {

    stats: Array<{

        key: Record<string, unknown> | string;
        value: number;
    }>;
    total: number;
    max: number;
    min: number;
    avg: number;
}>;
