import {RouteLocationNormalized} from "vue-router";

const routerStore: {

    previousRoute: null | RouteLocationNormalized

} = {

    previousRoute: null,
}

export default  routerStore;