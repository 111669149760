
export const useObjectUtilities = () => {

    const updateOrCreateRecord =
        <R>(record: R, records: Array<R>,
            comparisonFn: (recordA: R, recordB: R) => boolean): void => {

            const recordCopy = structuredClone(record);

            const matchingRecord = records.find(item => {

                return comparisonFn(item, record);
            });

            if(matchingRecord) {

                Object.assign(matchingRecord, recordCopy);

            } else {

                records.push(recordCopy);
            }
        }

    return {

        updateOrCreateRecord
    };
}
