import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check = _resolveComponent("check")!

  return (_openBlock(), _createElementBlock("div", {
    class: "intro-x rounded-circle d-flex justify-content-center align-items-center",
    style: _normalizeStyle([{"border":"2px solid #8898aa"}, [ _ctx.checked ?
                {'border-width': '0px', 'background-color': '#1e40af'} :
                {'border-width': '2px', 'background-color': '#ffffff'},
                { 'width': _ctx.size + 'px', 'height': _ctx.size + 'px'} ]])
  }, [
    _createVNode(_component_check, {
      class: "text-white",
      style: {"width":"66.66667%"}
    })
  ], 4))
}