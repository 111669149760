import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "intro-x tw-px-5 sm:tw-px-8 md:tw-px-10 tw-py-10 box" }
const _hoisted_2 = { class: "tw-flex tw-items-center" }
const _hoisted_3 = { class: "tw-flex tw-items-center" }
const _hoisted_4 = { class: "tw-text-gray-600 tw-pr-2" }
const _hoisted_5 = { class: "tw-font-semibold tw-text-gray-700" }
const _hoisted_6 = {
  key: 0,
  class: "tw-ml-4"
}
const _hoisted_7 = {
  key: 0,
  class: "tw-mt-8"
}
const _hoisted_8 = {
  key: 1,
  class: "tw-py-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back_button = _resolveComponent("back-button")!
  const _component_status_component = _resolveComponent("status-component")!
  const _component_claim_basic_info = _resolveComponent("claim-basic-info")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_back_button, {
            route: _ctx.claimsRoute,
            class: "tw-px-0"
          }, null, 8, ["route"])
        ]),
        _createElementVNode("div", _hoisted_5, " Claim " + _toDisplayString(_ctx.claimId), 1)
      ]),
      (_ctx.claim)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_status_component, {
              "show-border": true,
              status: _ctx.formattedClaimStatus,
              "status-color": _ctx.statusColor
            }, null, 8, ["status", "status-color"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.claim)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_claim_basic_info, { claim: _ctx.claim }, null, 8, ["claim"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_spinner)
        ]))
  ]))
}