

import {computed, defineComponent, PropType, ref} from "vue";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import Timeline from "@/icons/outlined/timeline.vue";
import LineProgress from "@/views/pages/insurances/last-expense/policy/LineProgress.vue";
import Plus from "@/icons/solid/plus.vue";
import MakePolicyPaymentModal
  from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/MakePolicyPaymentModal.vue";
import MakePolicyPaymentRequest
  from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/MakePolicyPaymentRequest.vue";
import {LastExpensePolicy} from "@/store/modules/last-expense/types/models";

export default defineComponent({
  components: {MakePolicyPaymentRequest, MakePolicyPaymentModal, Plus, LineProgress, Timeline},

  props: {

    totalPaid: {

      type: Number as PropType<number>,
      required: true
    },
    premium: {

      type: Number as PropType<number>,
      required: true
    },
    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },

  setup(props) {

    const { formatCurrency } = useCurrencyUtilities();

    const percentagePaid = computed(() => {

      return Math.round((props.totalPaid / props.premium) * 100);
    });
    const showMakePaymentModal = ref(false);
    const showPolicyRequestModal = ref(false);

    function toggleMakePaymentModal(show: boolean) {

      showMakePaymentModal.value = show;
    }

    function togglePolicyRequestModal(show: boolean) {

      showPolicyRequestModal.value = show;
    }

    return {

      formatCurrency,
      percentagePaid,
      showMakePaymentModal,
      showPolicyRequestModal,
      toggleMakePaymentModal,
      togglePolicyRequestModal
    }
  }
});

