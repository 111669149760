

import {defineComponent } from "vue";
import Confirmation from "@/views/components/Confirmation.vue";
import {useRoute} from "vue-router";

export default defineComponent({
  components: {Confirmation},

  setup(prop, {emit}) {


    const route = useRoute();
    const policyId = route.params.policyId as string;

    function close() {

      emit('close');
    }

    return {

      close,
      policyId
    };
  }
});

