

import {computed, defineComponent, PropType, ref, watch} from "vue";
import LastRespectIllustration from "@/icons/last-respect-illustration.vue";
import {useStore} from "@/hooks/useStore";
import {useRoute} from "vue-router";
import {LastExpensePolicyType} from "@/store/modules/last-expense/types/models";
import InsurancePlans from "@/views/pages/insurances/last-expense/last-expense-view/InsurancePlans.vue";

export default defineComponent({
  components: {InsurancePlans, LastRespectIllustration},
  props: {

    hideDashboards: {

      type: Boolean as PropType<boolean>,
      default: true
    },
    paddingBottom: {

      type: [Number, null] as PropType<number | null>,
      default: null
    },
    selectedChamaId: {

      type: String as PropType<string>,
      default: ''
    },

  },

  setup: function (props, {emit}) {

    const store = useStore();
    const route = useRoute();
    const experienceId = route.params.experienceId as string;
    const circleId = ref<string>(props.selectedChamaId)
    const policyType = ref<LastExpensePolicyType>(LastExpensePolicyType.INDIVIDUAL);
    const removeSelection = ref(false);

    const lastExpenseExperience = computed(() => {

      return store.getters.getLastExperience(experienceId);
    });

    const formattedExperience = computed(() => {

      const data = {

        title: '',
        subTitle: '',
        description: '',
        insuranceProvider: '',
        avatarUrl: ''
      };

      const experience = lastExpenseExperience.value;

      if (!experience) {

        return data;
      }

      data.title = experience.title;
      data.subTitle = experience.subTitle;
      data.description = experience.description;
      data.insuranceProvider = experience.insuranceProvider;
      data.avatarUrl = experience.avatarUrl;

      return data;
    });

    const avatarObject = computed(() => {

      return {

        backgroundImage: formattedExperience.value.avatarUrl ? 'url(' + formattedExperience.value.avatarUrl + ')' : '',
      };
    });

    function showChamas(value:boolean) {

      if(value){
        circleId.value = ''
        removeSelection.value = true;
        policyType.value = LastExpensePolicyType.INDIVIDUAL
      } else{
          policyType.value = LastExpensePolicyType.CIRCLE
      }
      emit('show-chamas', value);
    }

    function initialize() {

      policyType.value = LastExpensePolicyType.INDIVIDUAL
      removeSelection.value = false
    }

    watch(()=> props.selectedChamaId, (newValue)=> {

      if(newValue){
        circleId.value = props.selectedChamaId
        removeSelection.value = false
      }
    })

    initialize();

    return {

      circleId,
      showChamas,
      policyType,
      avatarObject,
      removeSelection,
      formattedExperience,

    }
  }
});

