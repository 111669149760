

import {computed, defineComponent, PropType, ref, watch} from "vue";

export default defineComponent({
  props: {

    currentInputValue: {

      type: String as PropType<string>,
      required: true
    },

    placeholder: {

      type: String as PropType<string>,
      default: "",
    },

    labelText: {

      type: String as PropType<string>,
      required: true,
    },

    error: {

      type: String as PropType<string>,
      default: ""
    },

    inputType: {

      type: String as PropType<string>,
      default: 'text',
    }
  },

  setup(props, {emit}) {

    const isActive = ref(false);
    const isFocused = ref(false);
    const inputValue = computed({

      get(): string {

        return props.currentInputValue
      },

      set(newValue: string): void {

        emit('new-value', newValue);
      }
    });

    function handleBlur(e: Event) {

      isFocused.value = false

      emit('blurred');

      const target = e.target as HTMLInputElement;

      target.removeAttribute('placeholder')
    }

    function handleFocus(e: Event) {

      isFocused.value = true;

      emit('focused');

      const target = e.target as HTMLInputElement;

      target.setAttribute('placeholder', props.placeholder);
    }

    function handleKeyDown() {

      emit('key-down')
    }

    watch([() => props.currentInputValue, () => isFocused.value],
        ([newCurrentInputValue, newIsFocusedValue]) => {

      isActive.value = !!(newIsFocusedValue || newCurrentInputValue.length);

    }, { immediate: true});

    return {

      isActive,
      isFocused,
      inputValue,
      handleBlur,
      handleFocus,
      handleKeyDown
    };
  }
});

