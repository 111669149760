<template>

    <div class="container-xxl py-5">
        <div class="container facts">
            <div class="tw--mx-6 sm:tw-mx-1 row">
                <div class="col-lg-6 facts-text -intro-x">
                    <div class="h-100 px-4">
                        <h1 class="display-6 tw-font-semibold text-white mb-4">We cater for Individuals And Groups</h1>
                        <p class="text-light mb-1">We take pride in offering a wide range of affordable insurance services tailored to meet the diverse needs of both individuals and groups.</p>
                        <p class="text-light mb-5"> Whether you're an individual seeking personal coverage or a group looking to protect your assets and subscribe to policies jointly, we've got you covered.</p>
                    </div>
                </div>
                <div class="col-lg-6 intro-x">
                    <h1 class="display-6 text-primary tw-font-semibold tw-mt-4 sm:tw-mt-0 mb-4">Some of the perks that makes us outstanding</h1>
                    <p class="mb-4">With our deep understanding of the insurance sector, we strive to deliver reliable and cost-effective insurance options to protect what matters most to you.</p>
                    <div class="row g-3">
                        <div class="col-sm-6 -intro-y">
                            <div class="bg-primary/80 rounded h-100 p-3">
                                <div class="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3">
                                    <img class="align-self-center mb-3" src="/icons/easyProcess.png" alt="malipo insurance">
                                    <h5 class="mb-0 tw-font-semibold">Easy Process</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 -intro-y">
                            <div class="bg-primary/30 rounded h-100 p-3">
                                <div class="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3">
                                    <img class="align-self-center mb-3" src="/icons/fulfilment.png" alt="Malipo policy immediate fulfilment">
                                    <h5 class="mb-0 tw-font-semibold">Immediate Fulfilment</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 intro-y">
                            <div class="bg-primary/30 rounded h-100 p-3">
                                <div class="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3">
                                    <img class="align-self-center mb-3" src="/icons/partners.png" alt="Trusted partners">
                                    <h5 class="mb-0 tw-font-semibold">Trustworthy Partners</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 intro-y">
                            <div class="bg-primary/80 rounded h-100 p-3">
                                <div class="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3">
                                    <img class="align-self-center mb-3" src="/icons/affordable.png" alt="Malipo affordable insurance">
                                    <h5 class="mb-0 tw-font-semibold">Affordable</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">

@media (min-width: 992px) {
  .container.facts {
    max-width: 100% !important;
  }

  .container.facts .facts-text {
    padding-left: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .container.facts .facts-text  {
    padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .container.facts .facts-text  {
    padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}

.container.facts .facts-text {
  background: linear-gradient(rgb(13, 18, 245, .1), rgba(35, 77, 215, .6)), url(/Insurance-5.jpeg) center right no-repeat;
  background-size: cover;
}

.container.facts {
  background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(/Insurance-3.jpeg) center right no-repeat;
  background-size: cover;
}

.container.facts .facts-text .h-100,
.container.facts .h-100 {
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>