
import {defineComponent, PropType} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import Confirmation from "@/views/components/Confirmation.vue";
import CheckCircle from "@/icons/solid/CheckCircle.vue";

export default defineComponent({
  components: {CheckCircle, Confirmation, BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
  },
  setup(props, {emit}) {

    const requirements = [

      'Must be below 75 years of age.',
      'Covers principal and 5 members of nuclear family.',
      'Principal member’s parent and parent in-laws.'
    ];

    function close() {

      emit('close');
    }

    return {

      close,
      requirements
    }
  }
});
