

import {defineComponent, PropType, ref, watch} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import {LastExpensePolicy} from "@/store/modules/last-expense/types/models";
import PolicyCancellation from "@/views/pages/insurances/last-expense/policy/cancel-policy/PolicyCancellation.vue";
import PolicyRemovalSuccess from "@/views/pages/insurances/last-expense/policy/cancel-policy/PolicyRemovalSuccess.vue";

export default defineComponent({

  components: {PolicyCancellation, PolicyRemovalSuccess, BaseModal},

  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    }
  },

  setup(props, {emit}) {

    const cancelledPolicyData = ref<LastExpensePolicy | null>(null)


    function updatedPolicyData(policyData: LastExpensePolicy) {

      cancelledPolicyData.value = policyData;
    }

    function close() {

      emit('close');
    }

    watch(
        () => props.show,
        (value) => {

          if(value) {

            cancelledPolicyData.value = null;
          }
        }
    )

    return {

      close,
      updatedPolicyData,
      cancelledPolicyData
    };
  }
});

