

import {defineComponent, PropType, ref, watch} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import RequestRegistration from "@/views/pages/authenticate/sign-up-modal/RequestRegistration.vue";
import ValidateRegistration from "@/views/pages/authenticate/sign-up-modal/ValidateRegistration.vue";
import SuccessHandler from "@/views/pages/authenticate/sign-up-modal/SuccessHandler.vue";

export default defineComponent({
  components: {SuccessHandler, ValidateRegistration, RequestRegistration, BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
  },

  setup(props, {emit}) {

    enum STEPS {

      REQUEST_REGISTRATION = "REQUEST_REGISTRATION",
      VALIDATE_REGISTRATION = "VALIDATE_REGISTRATION",
      HANDLE_SUCCESS = "HANDLE_SUCCESS",
    }
    const otpChannel = ref<"SMS" | "EMAIL">("SMS");
    const email = ref("kanyist@gmail.com");
    const currentStep = ref<STEPS>(STEPS.REQUEST_REGISTRATION);

    watch(() => props.show, newVal => {

      if(newVal) {

        currentStep.value = STEPS.REQUEST_REGISTRATION;
      }
    });

    function updateOptChannel(value: "SMS" | "EMAIL") {

      otpChannel.value = value;
    }

    function handleRegistrationRequested(
        data: { otpChannel: "SMS" | "EMAIL", email: string}
    ) {

      otpChannel.value = data.otpChannel;
      email.value = data.email
      currentStep.value = STEPS.VALIDATE_REGISTRATION;
    }

    function handleRegistrationValidated() {

      currentStep.value = STEPS.HANDLE_SUCCESS;
    }

    function signIn() {

      emit('sign-in');
    }

    function close() {

      emit('close');
    }

    return {

      STEPS,
      close,
      email,
      signIn,
      otpChannel,
      currentStep,
      updateOptChannel,
      handleRegistrationValidated,
      handleRegistrationRequested,
    };
  }
});

