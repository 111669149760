

import {computed, defineComponent, PropType} from "vue";
import Spinner from "@/views/components/Spinner.vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/circles/types/actions";
import Check from "@/icons/solid/check.vue";
import MemberAvatar from "@/views/pages/insurances/shared/member-elements/MemberAvatar.vue";

export default defineComponent({
    components: {MemberAvatar, Check, Spinner},
    props: {

        selectedMembersIds: {

            type: Array as PropType<Array<string>>,
            required: true
        },
        title: {

            type: String as PropType<string>,
            default: 'Select Members'
        },
        circleId: {

            type: String as PropType<string>,
            required: true
        },
        isAdminLending: {

            type: Boolean as PropType<boolean>,
            default: false
        }
    },

    setup(props, {emit}) {

        const store = useStore();

        const circleMembers = computed(() => {

            return store.getters.getCircleMembers(props.circleId);
        });

        const formattedMembers = computed(() => {

            const members = circleMembers.value;

            if(! members) {return members;}

            if(props.isAdminLending) {

                const adminMembers = members.filter(member => member.isAdmin );
                return adminMembers.map(member => {

                    return {

                        memberId: member.memberId,
                        avatarUrl: member.memberDetails.avatarURL,
                        fullName:member.memberDetails.fullName,
                        email: member.memberDetails.email,
                        selected: props.selectedMembersIds.indexOf(member.memberId) !== -1

                    }
                });
            }

            return members.map(member => {

                return {

                    memberId: member.memberId,
                    avatarUrl: member.memberDetails.avatarURL,
                    fullName:member.memberDetails.fullName,
                    email: member.memberDetails.email,
                    selected: props.selectedMembersIds.indexOf(member.memberId) !== -1

                }
            });
        });

        const hasAllMembersSelected = computed(() => {

            const members = circleMembers.value;
            const formattedCircleMembers = formattedMembers.value;
            if(! members || ! formattedCircleMembers) {

                return false;
            }

            const selectedMembersCount = formattedCircleMembers.reduce((count, member) => {

                return count + (member.selected ? 1 : 0);
            }, 0);

            return selectedMembersCount === formattedCircleMembers.length;
        });

        function toggleAllMembersSelection() {

            if(hasAllMembersSelected.value) {

                updateSelectedMembers([]);

            } else {

                const members = circleMembers.value;

                if(members) {

                    updateSelectedMembers(members.map(member => member.memberId));
                }
            }
        }

        function toggleMemberSelection(memberId: string) {

            const selectedMemberIds = props.selectedMembersIds.slice();

            const targetIndex = selectedMemberIds.indexOf(memberId);

            if(targetIndex === -1) {

                selectedMemberIds.push(memberId);

            } else {

                selectedMemberIds.splice(targetIndex, 1);
            }

            updateSelectedMembers(selectedMemberIds);
        }

        function updateSelectedMembers(memberIds: Array<string>) {

            emit('selected-members', memberIds)
        }

        function fetchCircleMembers() {

            store.dispatch(ActionTypes.FETCH_CIRCLE_MEMBERS, {

                circleId: props.circleId,
            });
        }

        function initialize() {

            emit('title', props.title);

            if(! circleMembers.value) {

                fetchCircleMembers();
            }
        }

        initialize();

        return {

            formattedMembers,
            hasAllMembersSelected,
            toggleMemberSelection,
            toggleAllMembersSelection
        };
    }
});

