import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec6e3c32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      class: "position-fixed bg-transparent",
      style: _normalizeStyle([{"top":"0","bottom":"0","left":"0","right":"0"}, {zIndex: _ctx.zIndex}])
    }, null, 4), [
      [_vShow, _ctx.isOpen]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _renderSlot(_ctx.$slots, "title-container", {
          isOpen: _ctx.isOpen,
          toggleDropdown: _ctx.toggleDropdown
        }, undefined, true)
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "position-absolute box",
        style: _normalizeStyle([{"top":"100%","right":"0"}, { marginTop: _ctx.dropdownMarginTop + 'px', zIndex: _ctx.zIndex}])
      }, [
        _renderSlot(_ctx.$slots, "default", { closeDropdown: _ctx.closeDropdown }, undefined, true)
      ], 4), [
        [_vShow, _ctx.isOpen]
      ])
    ])), [
      [_directive_click_outside, _ctx.closeDropdown]
    ])
  ]))
}