import {State} from "@/store/modules/circles/types/state";

export const state = (): State => ({

    circleMembersCount: {},
    userCircles: {

        CHAMA: [],
    },
    dataForUserCirclesView: [],
    transactions: [],
    dataForTransactionsView: [],
    members: [],
    circleIdsForFetchedMembers: [],
    beneficiaries: [],
    circleIdsForFetchedBeneficiaries: [],
    circleStats: [],
});
