

import {defineComponent} from "vue";
import {useRoute, useRouter} from "vue-router";
import emitter from "@/event-bus/eventBus";
import BaseDropdown from "@/views/components/BaseDropdown.vue";
import NavbarDropdownButton from "@/views/components/layout/navbar/NavbarDropdownButton.vue";

export default defineComponent({
  components: {NavbarDropdownButton, BaseDropdown},

  setup() {

    const router = useRouter();
    const route = useRoute();

    const isPoliciesRoute = route.name === 'last-expense-view';
    function navigate(e: Event, routeName: string, params: Record<string, string>| null = null) {

      e.preventDefault();

      if(routeName === 'logout') {

        showLogoutModal();

      } else {

        if(params) {


          router.push({
            name: routeName,
            params: params
          })
        } else {

          router.push({ name: routeName});
        }
      }
    }

    function showLogoutModal() {

      emitter.emit('logout');
    }

    return {

      navigate,
      isPoliciesRoute
    };
  }
});

