import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_policy_member_details = _resolveComponent("policy-member-details")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_modal_header, {
      title: 'Member ' + _ctx.memberId,
      "show-border": true,
      onClose: _ctx.close
    }, {
      default: _withCtx(() => [
        (_ctx.canManageMember)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("button", {
                class: "tw-mr-3 tw-border-0 tw-bg-white tw-text-slate-500 tw-text-sm tw-font-semibold",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectAction('edit')))
              }, "Edit"),
              (! _ctx.isOwner)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "tw-mr-3 tw-border-0 tw-bg-white tw-text-gray-500 tw-text-sm tw-font-semibold",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectAction('remove')))
                  }, "Remove"))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "onClose"]),
    _createVNode(_component_policy_member_details, { member: _ctx.member }, null, 8, ["member"])
  ]))
}