import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choose_members = _resolveComponent("choose-members")!
  const _component_confirm_policy_details = _resolveComponent("confirm-policy-details")!
  const _component_base_form_modal = _resolveComponent("base-form-modal")!

  return (_openBlock(), _createBlock(_component_base_form_modal, {
    show: _ctx.show,
    "secondary-action": _ctx.secondaryButtonText,
    "primary-action": _ctx.primaryButtonText,
    "disable-primary-button": _ctx.primaryButtonIsDisabled,
    "is-submitting": _ctx.isSubmitting,
    "submission-error": _ctx.submissionError,
    onClose: _ctx.close,
    onSecondaryAction: _ctx.handlePrevious,
    onPrimaryAction: _ctx.handleNext
  }, {
    default: _withCtx(({ updateTitle }) => [
      (_ctx.currentStep === _ctx.STEPS.CHOOSE_MEMBERS)
        ? (_openBlock(), _createBlock(_component_choose_members, {
            key: 0,
            title: "New policies for",
            "selected-members-ids": _ctx.formData.members,
            "circle-id": _ctx.circleId,
            onTitle: updateTitle,
            onSelectedMembers: _ctx.updateSelectedMembers
          }, null, 8, ["selected-members-ids", "circle-id", "onTitle", "onSelectedMembers"]))
        : _createCommentVNode("", true),
      (_ctx.currentStep === _ctx.STEPS.CONFIRM_DETAILS)
        ? (_openBlock(), _createBlock(_component_confirm_policy_details, {
            key: 1,
            plan: _ctx.plan,
            "policy-type": _ctx.policyType,
            "member-ids": _ctx.formData.members,
            "circle-id": _ctx.circleId,
            "is-submitting": _ctx.isSubmitting,
            onTitle: updateTitle
          }, null, 8, ["plan", "policy-type", "member-ids", "circle-id", "is-submitting", "onTitle"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["show", "secondary-action", "primary-action", "disable-primary-button", "is-submitting", "submission-error", "onClose", "onSecondaryAction", "onPrimaryAction"]))
}