

import {computed, defineComponent, PropType, watch} from "vue";
import {PaymentMode} from "@/store/types/baseModels";

export default defineComponent({
    props: {

        paymentMode: {

            type: String as PropType<PaymentMode>,
            required: true
        },

        otherContribution: {

            type: Boolean as PropType<boolean>,
            default: false
        },

        isWithdrawal: {

            type: Boolean as PropType<boolean>,
            default: false
        },

        successMessageTitle: {

            type: String as PropType<string>,
            default: `Transaction initiated`
        },

        successMessageBody: {

            type: String as PropType<string>,
            default: `Your transaction has successfully
      been initiated.`
        }
    },

    setup(props, {emit}) {

        const title = computed(() => {

            if(props.otherContribution) {
                return props.successMessageTitle
            }

            return props.paymentMode === PaymentMode.MPESA && ! props.isWithdrawal ?
                'Authorize request' : props.successMessageTitle;
        });

        function close() {

            emit('close');
        }

        watch(() => title.value, newVal => {

            emit('title', newVal);

        }, {immediate: true});

        return {

            close,
            PaymentMode
        };
    }
});

