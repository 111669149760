import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = {
  class: "text-muted mp-text-sm ml-4 pl-0",
  style: {"list-style-position":"outside"}
}
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = {
  key: 0,
  class: "mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, "Ksh " + _toDisplayString(_ctx.formatCurrency(_ctx.premium)), 1),
      _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.ucfirst(_ctx.paymentMode)), 1),
      (_ctx.paymentMode === _ctx.PaymentMode.CARD && _ctx.paymentCard)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(_ctx.paymentCard.maskedPan), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}