import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_policy_member_details = _resolveComponent("policy-member-details")!
  const _component_form_modal_buttons = _resolveComponent("form-modal-buttons")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_modal_header, {
      title: "Remove member",
      "show-border": true,
      onClose: _ctx.close
    }, null, 8, ["onClose"]),
    _createVNode(_component_policy_member_details, { member: _ctx.member }, null, 8, ["member"]),
    _createVNode(_component_form_modal_buttons, {
      "secondary-action": "Cancel",
      "primary-action": "Remove",
      "is-submitting": _ctx.isSubmitting,
      "submission-error": _ctx.submissionError,
      onSecondaryAction: _ctx.cancel,
      onPrimaryAction: _ctx.deletePolicyBeneficiary
    }, null, 8, ["is-submitting", "submission-error", "onSecondaryAction", "onPrimaryAction"])
  ]))
}