

import {computed, defineComponent, PropType, ref} from "vue";
import {AxiosError} from "axios";
import BaseModal from "@/views/components/BaseModal.vue";
import FormModalButtons from "@/views/components/form-modal/FormModalButtons.vue";

export default defineComponent({
  components: {FormModalButtons, BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },

    title: {

      type: String as PropType<string>,
      default: ""
    },

    secondaryAction: {

      type: String as PropType<string>,
      default: ""
    },

    primaryAction: {

      type: String as PropType<string>,
      default: ""
    },

    disablePrimaryButton: {

      type: Boolean as PropType<boolean>,
      default: false
    },

    isSubmitting: {

      type: Boolean as PropType<boolean>,
      default: false
    },

    submissionError: {

      type: [Object, String, null] as PropType<AxiosError | string | null>,
      default: null
    },
  },

  setup(props, {emit}) {

    const slotTitle = ref("");

    const effectiveTitle = computed(() => {

      return slotTitle.value ? slotTitle.value : props.title;
    });

    function updateSlotTitle(title: string) {

      slotTitle.value = title;
    }

    function handleSecondaryAction() {

      emit('secondary-action');
    }

    function handlePrimaryAction() {

      emit('primary-action');
    }

    function close() {

      emit('close');
    }

    return {

      close,
      effectiveTitle,
      updateSlotTitle,
      handlePrimaryAction,
      handleSecondaryAction
    };
  }
});

