

import {computed, defineComponent, PropType, watch} from "vue";
import {useStore} from "@/hooks/useStore";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {LastExpensePlan, LastExpensePolicyType} from "@/store/modules/last-expense/types/models";
import MemberAvatar from "@/views/pages/insurances/shared/member-elements/MemberAvatar.vue";
import CheckCircle from "@/icons/solid/CheckCircle.vue";

export default defineComponent({
  components: {CheckCircle, MemberAvatar},
  props: {

    plan: {

      type: Object as PropType<LastExpensePlan>,
      required: true
    },
    policyType: {

      type: String as PropType<LastExpensePolicyType>,
      required: true
    },
    memberIds: {

      type: Array as PropType<Array<string>>,
      required: true
    },
    circleId: {

      type: String as PropType<string>,
      required: true
    }
  },

  setup(props, {emit}) {

    const store = useStore();
    const { formatCurrency } = useCurrencyUtilities();

    const title = computed(() => {

      return props.policyType === LastExpensePolicyType.INDIVIDUAL ?
          'Create policy' : 'Create policies';
    });

    const policyFeatures = computed(() => {

      const plan = props.plan;

      return {

        'Policy type': props.policyType === LastExpensePolicyType.INDIVIDUAL ?
            `Individual policy. The policy is not associated with any chama.` :
            `Group policy. Members in a group can view all the group policies.`,
        'Premium': 'Ksh ' + formatCurrency(plan.premium, 0),
        'Benefit': 'Ksh ' + formatCurrency(plan.cover, 0),
        'Claims': (isMultipleClaimPlan(plan) ? '3 claims' : '1 claim') + ' per year'
      }
    });

    const circleMembers = computed(() => {

      return store.getters.getCircleMembers(props.circleId);
    });

    const members = computed(() => {

      const members = circleMembers.value;

      if(! members) { return []; }

      return members.filter(member => {

        return props.memberIds.indexOf(member.memberId) !== -1;
      });
    });

    const formattedMembers = computed(() => {

      return members.value.map(member => {

        return {

          memberId: member.memberId,
          avatarUrl: member.memberDetails.avatarURL,
          fullName:member.memberDetails.fullName,
          email: member.memberDetails.email,
        }
      });
    });

    function isMultipleClaimPlan(plan: LastExpensePlan) {


      let isMultipleClaimPlan = false;
      plan.name.split(" ").forEach(word => {

        if (word.toLowerCase() === 'multiple') {

          isMultipleClaimPlan = true;
        }
      });

      return isMultipleClaimPlan;
    }

    watch(() => title.value, newVal => {

      emit('title', newVal);

    }, {immediate: true});

    return {

      policyFeatures,
      formatCurrency,
      formattedMembers,
      LastExpensePolicyType
    };
  }
});

