import {ActionContext, CommitOptions} from "vuex";
import {RootState} from "@/store";
import {Mutations as GlobalMutations} from "@/store/types/mutations";
import {Bank, BankAccount, TransactionInfo} from "@/store/types/baseModels";

export enum Authority {
    USER = "USER", SUPER_ADMIN = "SUPER_ADMIN", CUSTOMER_SERVICE = "CUSTOMER_SERVICE"
}

export enum AccountStatus {
    ACTIVE = "ACTIVE", INACTIVE = "INACTIVE",
}

export enum AccountType {
    USER_ACCOUNT= "USER_ACCOUNT",
}

export enum CardStatus {

    ACTIVE= "ACTIVE", INACTIVE = "INACTIVE"
}

export enum CardType {

    VISA = "VISA", MASTERCARD = "MASTERCARD", AMEX = "AMEX"
}

export type Authorities = Array<Authority>;

export type UserInfo = {

    accountId: string;
    authorities: Authorities;
    avatarURL: string | null;
    createdOn: Date;
    email: string;
    firstName: string;
    idNumber: string;
    lastName: string;
    phoneNumber: string;
    updatedOn: Date;
    userId: string;
};

export type WalletInfo = {

    accountId: string;
    accountStatus: AccountStatus;
    accountType: AccountType;
    actualBalance: number;
    availableBalance: number;
    createdOn: Date;
    updatedOn: Date;
    userId: string;
}

export type PaymentCard = {

    cardStatus: CardStatus;
    cardType: CardType;
    createdOn: Date;
    expiryMonth: string;
    expiryYear: string;
    maskedPan: string;
    name: string;
    token: string;
    updatedOn: Date;
    user: UserInfo;
}

export type DataForUserTransactionsView = {

    filters: FiltersForUserTransactionsView;
    items: Array<TransactionInfo>;
    nextPage: number;
    totalPages: number;
};

export type FiltersForUserTransactionsView = Record<string, never>;

export type State = {
    userInfo: UserInfo | null;
    userWalletInfo: WalletInfo | null;
    transactions: Array<TransactionInfo>;
    dataForUserTransactionsView: Array<Omit<DataForUserTransactionsView, "items"> &
        { items: Array<string> }>;
    userPaymentCards: Array<PaymentCard> | null,
    userBankAccounts: Array<BankAccount> | null,
    supportedBanks: Array<Bank>,
};

/*======================Getters======================*/
export enum GetterTypes {
    GET_USER_INFO = "getUserInfo",
    GET_USER_WALLET_INFO = "getUserWalletInfo",
    GET_USER_PAYMENT_CARDS = "getUserPaymentCards",
    GET_USER_SUPPORTED_BANKS = "getUserSupportedBanks",
    GET_USER_BANK_ACCOUNTS = "getUserBankAccounts",
    GET_DATA_FOR_USER_TRANSACTIONS_VIEW = "getDataForUserTransactionsView",
    GET_USER_TRANSACTION = "getUserTransaction"
}

export type Getters = {
    [GetterTypes.GET_USER_INFO]: (state: State) => UserInfo | null;
    [GetterTypes.GET_USER_WALLET_INFO]: (state: State) => WalletInfo | null;
    [GetterTypes.GET_USER_PAYMENT_CARDS]: (state: State) => Array<PaymentCard> | null;
    [GetterTypes.GET_USER_BANK_ACCOUNTS]: (state: State) => Array<BankAccount> | null;
    [GetterTypes.GET_USER_SUPPORTED_BANKS]: (state: State) => Array<Bank>;
    [GetterTypes.GET_DATA_FOR_USER_TRANSACTIONS_VIEW]: (state: State) =>
            DataForUserTransactionsView| null;
    [GetterTypes.GET_USER_TRANSACTION]: (state: State) =>
        (transactionRef: string) =>
            TransactionInfo | null;
};

/*======================Mutations======================*/
export enum MutationTypes {

    RESET_USERS_STATE = "RESET_USERS_STATE",
    SET_USER_INFO = "SET_USER_INFO",
    SET_USER_WALLET_INFO = "SET_USER_WALLET_INFO",
    CLEAR_USER_WALLET_INFO = "CLEAR_USER_WALLET_INFO",
    SET_USER_PAYMENT_CARDS = "SET_USER_PAYMENT_CARDS",
    CLEAR_USER_PAYMENT_CARDS = "CLEAR_USER_PAYMENT_CARDS",
    SET_USER_SUPPORTED_BANKS = "SET_USER_SUPPORTED_BANKS",
    SET_USER_BANK_ACCOUNTS = "SET_USER_BANK_ACCOUNTS",
    CLEAR_USER_BANK_ACCOUNTS = "CLEAR_USER_BANK_ACCOUNTS",
    SET_DATA_FOR_USER_TRANSACTIONS_VIEW =
        "SET_DATA_FOR_USER_TRANSACTIONS_VIEW",
    CLEAR_DATA_FOR_USER_TRANSACTIONS_VIEW  =
        "CLEAR_DATA_FOR_USER_TRANSACTIONS_VIEW",
}

export type Mutations<S = State> = {

    [MutationTypes.RESET_USERS_STATE]:
        (state: S, payload: {

            keysNotToReset?: Array<string>

        }) => void;
    [MutationTypes.SET_USER_INFO]: (state: S, userInfo: UserInfo | null) => void;
    [MutationTypes.SET_USER_WALLET_INFO]: (state: S, walletInfo: WalletInfo) => void;
    [MutationTypes.CLEAR_USER_WALLET_INFO]: (state: S) => void;
    [MutationTypes.SET_USER_PAYMENT_CARDS]: (state: S, payload: {

        data: Array<PaymentCard>
    }) => void;
    [MutationTypes.CLEAR_USER_PAYMENT_CARDS]: (state: S) => void;
    [MutationTypes.SET_USER_SUPPORTED_BANKS]:
        (state: S, payload: {

            data: Array<Bank>;
        }) => void;
    [MutationTypes.SET_USER_BANK_ACCOUNTS]: (state: S, payload: {

        data: Array<BankAccount>
    }) => void;
    [MutationTypes.CLEAR_USER_BANK_ACCOUNTS]: (state: S) => void;
    [MutationTypes.SET_DATA_FOR_USER_TRANSACTIONS_VIEW]:
        (state: S, payload: {
            data: DataForUserTransactionsView;
        }) => void;
    [MutationTypes.CLEAR_DATA_FOR_USER_TRANSACTIONS_VIEW]:
        (state: S, payload: {
            filters: FiltersForUserTransactionsView
        }) => void;
};

/*======================Actions======================*/
export type AugmentedActionContext = Omit<ActionContext<State, RootState>, "commit"> & {

    commit<K extends keyof GlobalMutations>(
        key: K,
        payload?: Parameters<GlobalMutations[K]>[1],
        options?:  CommitOptions
    ): ReturnType<GlobalMutations[K]>;
};

export enum ActionTypes {

    FETCH_USER_INFO = "FETCH_USER_INFO",
    FETCH_USER_WALLET_INFO = "FETCH_USER_WALLET_INFO",
    UPDATE_LOGGED_IN_USER_INFO = "UPDATE_LOGGED_IN_USER_INFO",
    FETCH_USER_PAYMENT_CARDS = "FETCH_USER_PAYMENT_CARDS",
    FETCH_USER_SUPPORTED_BANKS = "FETCH_USER_SUPPORTED_BANKS",
    FETCH_USER_BANK_ACCOUNTS = "FETCH_USER_BANK_ACCOUNTS",
    FETCH_DATA_FOR_USER_TRANSACTIONS_VIEW =
        "FETCH_DATA_FOR_USER_TRANSACTIONS_VIEW",
}

export type Actions = {

    [ActionTypes.FETCH_USER_INFO]: (
        ctx: AugmentedActionContext,
        payload: {
            successCallback?: (userInfo: UserInfo) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_USER_WALLET_INFO]: (
        ctx: AugmentedActionContext,
        payload: {
            successCallback?: (walletInfo: WalletInfo) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.UPDATE_LOGGED_IN_USER_INFO]: (
        ctx: AugmentedActionContext,
        payload: {
            firstName: string;
            lastName: string;
            idNumber: string;
            successCallback?: (userInfo: UserInfo) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_USER_PAYMENT_CARDS]: (
        ctx: AugmentedActionContext,
        payload: {
            successCallback?: (paymentCards: Array<PaymentCard>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_USER_SUPPORTED_BANKS]: (
        ctx: AugmentedActionContext,
        payload: {
            successCallback?: (data: Array<Bank>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_USER_BANK_ACCOUNTS]: (
        ctx: AugmentedActionContext,
        payload: {
            successCallback?: (bankAccounts: Array<BankAccount>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_DATA_FOR_USER_TRANSACTIONS_VIEW]: (
        ctx: AugmentedActionContext,
        payload: {
            filters: FiltersForUserTransactionsView;
            successCallback?:
                (data: DataForUserTransactionsView) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;
};
