import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-p-6 tw-absolute tw-bottom-0 tw-inset-x-0 tw-bg-white shadow-lg"
}
const _hoisted_2 = { class: "tw-flex tw-items-center tw-justify-between tw--mx-2" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_submission_error_handler = _resolveComponent("submission-error-handler")!

  return (_ctx.primaryAction)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.secondaryAction)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(["tw-flex-grow btn tw-mx-2", [ _ctx.isSubmitting ?
                  'btn-link tw-text-slate-600 text-center' :
                  'btn-outline-primary text-primary']]),
                style: {"background-color":"#ffffff"},
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSecondaryAction && _ctx.handleSecondaryAction(...args))),
                disabled: _ctx.isSubmitting
              }, _toDisplayString(_ctx.isSubmitting ? 'Please wait..' : _ctx.secondaryAction), 11, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass(["tw-mx-2 tw-flex-grow btn btn-primary", [{ 'spinner spinner-white': _ctx.isSubmitting}, {'btn-disabled': _ctx.disablePrimaryButton}]]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePrimaryAction && _ctx.handlePrimaryAction(...args)))
          }, _toDisplayString(_ctx.primaryAction), 3)
        ]),
        _createVNode(_component_submission_error_handler, { error: _ctx.submissionError }, null, 8, ["error"])
      ]))
    : _createCommentVNode("", true)
}