
import {computed, defineComponent, PropType} from "vue";
import {
  LastExpensePolicy,
  LastExpensePolicyPayment,
  LastExpensePolicyStatus,
  PolicyPaymentStatus
} from "@/store/modules/last-expense/types/models";
import {
    useLastExpensePoliciesUtilities
} from "@/views/pages/insurances/last-expense/policies/useLastExpensePoliciesUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import PolicyPendingSubmission
    from "@/views/pages/insurances/last-expense/policy/policy-header/PolicyPendingSubmission.vue";
import PolicyPendingPremiumPayment
    from "@/views/pages/insurances/last-expense/policy/policy-header/PolicyPendingPremiumPayment.vue";
import PolicyActive from "@/views/pages/insurances/last-expense/policy/policy-header/PolicyActive.vue";


export default defineComponent({
  components: {PolicyActive, PolicyPendingPremiumPayment, PolicyPendingSubmission},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    },
    payments: {

      type: Array as PropType<Array<LastExpensePolicyPayment>>,
      required: true
    }
  },

  setup(props) {

    const { ucfirst } = useStringUtilities();
    const { computeStatusColor } = useLastExpensePoliciesUtilities();

    const policyStatusInfo = computed(() => {

      const policy = props.policy;

      return {

        status: policy.policyStatus,
        formattedStatus: ucfirst(policy.policyStatus),
        statusColor: computeStatusColor(policy.policyStatus)
      }
    });

    const premium = computed(() => {

      return props.policy.lastExpensePlan.premium;
    });

    const totalPaid = computed(() => {

      return props.payments.reduce((total, payment) => {

        return total + (payment.policyPaymentStatus === PolicyPaymentStatus.RECEIVED ?
                payment.amount : 0);

      }, 0);
    });

    const isPendingSubmission = computed(() => {

      return props.policy.policyStatus === LastExpensePolicyStatus.PENDING;
    });

    const isPendingPremiumPayment = computed(() => {

      return totalPaid.value < premium.value &&
          props.policy.policyStatus === LastExpensePolicyStatus.SUBMITTED ||
          props.policy.policyStatus === LastExpensePolicyStatus.CONFIRMED;
    });

    const isActive = computed(() => {

      return totalPaid.value >= premium.value &&
          props.policy.policyStatus === LastExpensePolicyStatus.SUBMITTED ||
          props.policy.policyStatus === LastExpensePolicyStatus.CONFIRMED ||
          props.policy.policyStatus === LastExpensePolicyStatus.ACTIVE;
    });

    return {

      premium,
      isActive,
      totalPaid,
      policyStatusInfo,
      isPendingSubmission,
      isPendingPremiumPayment
    }
  }
});
