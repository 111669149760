import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tw-font-semibold tw-text-lg" }
const _hoisted_2 = { class: "tw-flex tw-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x = _resolveComponent("x")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw-flex tw-justify-between tw-items-center tw-py-6", { 'tw--mx-6 tw-px-6 border-bottom' : _ctx.showBorder }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.showCloseButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn border-0 shadow-none tw-p-2 tw--m-2",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_x, { class: "tw-h-5 tw-w-5" })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}