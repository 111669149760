
import {defineComponent, PropType, ref, watch} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import Confirmation from "@/views/components/Confirmation.vue";
import {LastExpensePolicy} from "@/store/modules/last-expense/types/models";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {useStore} from "@/hooks/useStore";
import {mappers} from "@/store/modules/last-expense/mappers";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";

export default defineComponent({
  components: {Confirmation, BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },
  setup(props, {emit}) {

    const store = useStore();
    const {mapToTypedLastExpensePolicy} = mappers();
    const isSubmitting = ref(false);
    const submissionError = ref("");

    function submit() {

      isSubmitting.value = true;
      submissionError.value = "";

      LastExpenseExperience.singleton().submitPolicyForEvaluation(
          props.policy.lastExpensePolicyId
      ).then(response => {

        store.commit(MutationTypes.SET_POLICY, {

          data: mapToTypedLastExpensePolicy(response.data)
        });

        close();

      }).catch(error => {

        if(error.response &&
            (error.response.status == 422 || error.response.status == 409) &&
            error.response.data.message) {

          submissionError.value = error.response.data.message;

        } else {

          submissionError.value =
              "Something went wrong. Please try again.";
        }

        isSubmitting.value = false;
      });
    }

    function close() {

      emit('close');
    }

    watch(() => props.show, newVal => {

      if(newVal) {

        isSubmitting.value = false;
        submissionError.value = "";
      }
    });

    return {

      close,
      submit,
      isSubmitting,
      submissionError,
    }
  }
});
