

import {computed, defineComponent, PropType} from "vue";
import {LastExpenseClaim} from "@/store/modules/last-expense/types/models";
import StatusComponent from "@/views/components/StatusComponent.vue";
import {
    useLastExpenseClaimsUtilities
} from "@/views/pages/insurances/last-expense/claims/useLastExpenseClaimsUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import BackButton from "@/views/components/BackButton.vue";
import {RouteLocationRaw, useRoute} from "vue-router";
import Spinner from "@/views/components/Spinner.vue";
import ClaimBasicInfo from "@/views/pages/insurances/last-expense/claim/ClaimBasicInfo.vue";

export default defineComponent({
  components: {ClaimBasicInfo, Spinner, BackButton, StatusComponent},
  props: {

    claim: {

      type: [Object, null] as PropType<LastExpenseClaim | null>,
      default: null
    },
    claimsRoute: {

      type: Object as PropType<RouteLocationRaw>,
      required: true
    }
  },

  setup(props) {

    const { ucfirst } = useStringUtilities();
    const { computeStatusColor } = useLastExpenseClaimsUtilities();
    const route = useRoute();
    const claimId = route.params.claimId as string;

    const statusColor = computed(() => {

      return props.claim ? computeStatusColor(props.claim.claimStatus) : "";
    });

    const formattedClaimStatus = computed(() => {

      return props.claim ? ucfirst(props.claim.claimStatus) : "";
    });

    return {

      claimId,
      statusColor,
      formattedClaimStatus
    }
  }
});

