

import {defineComponent, PropType, ref} from "vue";
import {LastExpensePolicy} from "@/store/modules/last-expense/types/models";
import SubmitPolicyModal from "@/views/pages/insurances/last-expense/policy/policy-header/SubmitPolicyModal.vue";

export default defineComponent({
  components: {SubmitPolicyModal},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },
  setup() {

    const showSubmitPolicyModal = ref(false);

    function toggleSubmitPolicyModal(show: boolean) {

      showSubmitPolicyModal.value = show;
    }

    return {

      showSubmitPolicyModal,
      toggleSubmitPolicyModal
    }
  }
});

