import { Directive } from "vue";

const ClickOutsideDirective: Directive = {

    beforeMount(el, binding) {

        el.clickOutsideEvent = function(event: Event) {

            if (!(el === event.target || el.contains(event.target))) {

                binding.value(event, el);
            }
        };

        document.addEventListener("click", el.clickOutsideEvent);
    },

    unmounted(el) {

        document.removeEventListener("click", el.clickOutsideEvent);
    }
};

export default ClickOutsideDirective;
