

import {computed, defineComponent, PropType} from "vue";
import {
  LastExpensePlan,
  LastExpensePolicy,
  LastExpensePolicyType
} from "@/store/modules/last-expense/types/models";
import {useDateUtilities} from "@/hooks/useDateUtilities";
import BenefitAndPremium from "@/views/pages/insurances/last-expense/policy/BenefitAndPremium.vue";
import PolicyDetailsList from "@/views/pages/insurances/last-expense/policy/PolicyDetailsList.vue";

export default defineComponent({
  components: {PolicyDetailsList, BenefitAndPremium},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },
  setup(props) {

    const { formatToHumanFriendlyDate, dateToTimeInTwelveHourFormat} = useDateUtilities();

    const policyDetails = computed(() => {

      const policy = props.policy;

      return {

        'Claims': isMultiplePackagePlan(policy.lastExpensePlan) ?
            '3 claims' : '1 claim',
        'Waiting period': '3 months',
        'Created on': formatToHumanFriendlyDate(policy.createdOn) + ', ' +
            dateToTimeInTwelveHourFormat(policy.createdOn),
        'Policy type': policy.policyType === LastExpensePolicyType.INDIVIDUAL ?
            'Individual policy' : 'Circle policy',
      }
    });

    function isMultiplePackagePlan(plan: LastExpensePlan) {

      let isMultiplePackagePlan = false;
      plan.name.split(" ").forEach(word => {

        if (word.toLowerCase() === 'multiple') {

          isMultiplePackagePlan = true;
        }
      });

      return isMultiplePackagePlan;
    }

    return {

      policyDetails
    };
  }
});

