

import {defineComponent, PropType} from "vue";
import ArrowBack from "@/icons/arrow-back.vue";
import {RouteLocationRaw, useRouter} from "vue-router";
import routerStore from "@/router/routerStore";

export default defineComponent({

  components: {ArrowBack},

  props: {

    iconSize: {

      type: Number as PropType<number>,
      default: 20
    },

    route: {

      type: [Object, null] as PropType<RouteLocationRaw | null>,
      default: null
    },

    matchRoute: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props) {

    const router = useRouter();

    function goBack() {

      if(props.matchRoute) {

        if(props.route) {

          return router.replace(props.route);
        }
      }

      if(routerStore.previousRoute) {

        return router.back();
      }

      if(props.route) {

        return router.replace(props.route);
      }

      router.replace({ name: 'home-view'});
    }

    return {

      goBack,
    };
  }
});

