

import {defineComponent, PropType, ref, watch} from "vue";
import Errors from "@/views/components/Errors";
import CustomRadio from "@/views/components/form-elements/CustomRadio.vue";
import X from "@/icons/solid/x.vue";
import CustomInput from "@/views/pages/authenticate/sign-up-modal/CustomInput.vue";
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import {useValidationUtilities} from "@/hooks/useValidationUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import PasswordInput from "@/views/pages/authenticate/sign-up-modal/PasswordInput.vue";
import SubmissionErrorComponent from "@/views/pages/authenticate/sign-up-modal/SubmissionErrorComponent.vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/authorization/types";
import {useErrorUtilities} from "@/hooks/useErrorUtilities";

export default defineComponent({

  components: {SubmissionErrorComponent, PasswordInput, CustomInput, X, CustomRadio},

  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    }
  },

  setup(props, {emit}) {

    const store = useStore();
    const { validateStringFieldIsNotEmpty } = useValidationUtilities();
    const { removeAllWhitespace } = useStringUtilities();
    const { submissionErrorToString } = useErrorUtilities();

    const formData = ref(generateFormData());
    const formErrors = ref(new Errors());
    const isSubmitting = ref(false);
    const submissionError = ref("");

    function updateOptChannel(value: string) {

      formData.value.otpChannel = value;
    }

    function requestRegistration() {

      if(isSubmitting.value) { return; }

      formErrors.value.add('firstName',
          validateStringFieldIsNotEmpty(formData.value.firstName));

      formErrors.value.add('lastName',
          validateStringFieldIsNotEmpty(formData.value.lastName));

      formErrors.value.add('idNumber',
          validateStringFieldIsNotEmpty(formData.value.idNumber));

      validatePhoneNumber();
      validateEmail();
      validatePassword();

      if(formErrors.value.any()) {

        return;
      }

      const formDataCopy = JSON.parse(JSON.stringify(formData.value));

      const phoneNumber = removeAllWhitespace(parsePhoneNumber(
          formData.value.phoneNumber, 'KE'
      ).formatInternational());

      isSubmitting.value = true;
      submissionError.value = "";

      store.dispatch(ActionTypes.REQUEST_USER_REGISTRATION, {

        data: Object.assign({}, formData.value, {phoneNumber})

      }).then(() => {

        emit('registration-requested', {

          otpChannel: formDataCopy.otpChannel,
          email: formDataCopy.email
        });

      }).catch(error => {

        submissionError.value = submissionErrorToString(error);

      }).finally(() => {

        isSubmitting.value = false;
      });
    }

    function validatePassword() {

      formErrors.value.add('password',
          validateStringFieldIsNotEmpty(formData.value.password));
    }

    function validateEmail() {

      formErrors.value.add('email',
          validateStringFieldIsNotEmpty(formData.value.email));

      if(formErrors.value.has('email')) {

        return;
      }

      const simpleEmailCheck = /\S+@\S+\.\S+/;

      formErrors.value.add('email',
          simpleEmailCheck.test(formData.value.email) ? '' : 'Invalid email');
    }

    function validatePhoneNumber() {

      formErrors.value.add('phoneNumber',
          validateStringFieldIsNotEmpty(formData.value.phoneNumber));

      if(formErrors.value.has('phoneNumber')) {

        return;
      }

      formErrors.value.add('phoneNumber',
          isValidPhoneNumber(formData.value.phoneNumber, 'KE') ?
              '' : 'Invalid phone number');
    }

    function close() {

      emit('close');
    }

    function generateFormData() {

      return {

        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        idNumber: "",
        password: "",
        otpChannel: "SMS",
      }
    }

    watch(() => props.show, (value) => {

      if(value) {

        formData.value = generateFormData();
        formErrors.value = new Errors();
        isSubmitting.value = false;
        submissionError.value = "";
      }
    });

    return {

      close,
      formData,
      formErrors,
      isSubmitting,
      submissionError,
      updateOptChannel,
      requestRegistration,
    };
  }
});

