

import {computed, defineComponent, PropType, ref, watch} from "vue";
import Errors from "@/views/components/Errors";
import {AxiosError} from "axios";
import BaseFormModal from "@/views/components/form-modal/BaseFormModal.vue";
import {useValidationUtilities} from "@/hooks/useValidationUtilities";
import {parsePhoneNumber} from "libphonenumber-js/max";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {useRoute} from "vue-router";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import ChooseCircleMembers
  from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/ChooseCircleMembers.vue";
import PaymentLinkSuccess
  from "@/views/pages/insurances/last-expense/policy/policy-payments/make-policy-payment/PaymentLinkSuccess.vue";
import {LastExpensePolicy, LastExpensePolicyType} from "@/store/modules/last-expense/types/models";
import Trash from "@/icons/outlined/trash.vue";

export default defineComponent({

  components: {
    Trash,
    ChooseCircleMembers,
    PaymentLinkSuccess,
    BaseFormModal,
  },

  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    },
  },

  setup(props, {emit}) {

    enum STEPS {

      SET_PAYMENT_DATA = "SET_PAYMENT_DATA",
      CHOOSE_NUMBER = "CHOOSE_NUMBER",
      HANDLE_SUCCESS = "HANDLE_SUCCESS"
    }

    type Items = {
      name: string;
      unitPrice: string;
      quantity: number;
      description: string;
    }

    const route = useRoute();
    const {
      validateStringFieldIsNotEmpty,
      validatePhoneNumber, validateIsNumber
    } = useValidationUtilities();
    const policyId = props.policy.lastExpensePolicyId as string;
    const { removeAllWhitespace} = useStringUtilities();
    const currentStep = ref<STEPS>(STEPS.SET_PAYMENT_DATA);
    const isSubmitting = ref(false);
    const submissionError = ref<AxiosError | null>(null);
    const formErrors = ref(new Errors());
    const link = ref('');
    const linkSuccess = ref(false);
    const modalTitle = ref('Generate payment request link');
    const phoneNumber = ref('');
    const primaryButtonIsDisabled = ref(false);

    const initialItems = {
      name: '',
      unitPrice: '',
      quantity: 1,
      description: ''
    }

    const paymentItems = ref<Array<Items>>([]);

    const formData = ref({
      phoneNumber: '',
      description: '',
      checkoutItems: paymentItems.value
    });

    const isCirclePolicy = computed( () => {

      const policy = props.policy;
      return policy.policyType === LastExpensePolicyType.CIRCLE && route.params.dashboardId !== 'user'
    });

    const secondaryButtonText = computed(() => {

      if(currentStep.value === STEPS.CHOOSE_NUMBER) {
        return 'Previous'
      }

      return currentStep.value === STEPS.SET_PAYMENT_DATA ? 'Cancel' : '';
    });

    const primaryButtonText = computed(() => {

      if(currentStep.value === STEPS.CHOOSE_NUMBER) {
        return 'Continue'
      }

      return currentStep.value === STEPS.SET_PAYMENT_DATA ? 'Generate link' : 'Close'
    });

    function handlePrevious() {

      if(currentStep.value === STEPS.SET_PAYMENT_DATA) {

        close();
      } else if(currentStep.value === STEPS.CHOOSE_NUMBER) {

        phoneNumber.value = ''
        currentStep.value = STEPS.SET_PAYMENT_DATA
        primaryButtonIsDisabled.value = false
      }
    }

    function handleNext() {

      if(currentStep.value === STEPS.SET_PAYMENT_DATA) {

        makePaymentRequest();

      } else if(currentStep.value === STEPS.CHOOSE_NUMBER) {

        if(!phoneNumber.value) {

          primaryButtonIsDisabled.value = true
          return;
        }
        currentStep.value = STEPS.SET_PAYMENT_DATA

      } else if(currentStep.value === STEPS.HANDLE_SUCCESS) {

        close();
      }
    }

    function removeItem(index: number) {
      formErrors.value.clear('name'+ index);
      formErrors.value.clear('quantity'+ index);
      formErrors.value.clear('unitPrice'+ index);
      paymentItems.value.splice(index,1);
    }

    function validateFormFields() {

      formErrors.value.add('phoneNumber',
        validatePhoneNumber(formData.value.phoneNumber));

      paymentItems.value.forEach((item, index) => {

        formErrors.value.add('name' + index,
          validateStringFieldIsNotEmpty(item.name));
        formErrors.value.add('unitPrice' + index,
          validateIsNumber(item.unitPrice, false, 10));
        formErrors.value.add('quantity' + index,
          validateIsNumber(item.quantity + '', true, 1));
      })
    }

    function chooseMember() {

      currentStep.value = STEPS.CHOOSE_NUMBER
    }
    function updatePhoneNumber(phone: string) {

      phoneNumber.value = phone;
      formData.value.phoneNumber = phone;
      if(primaryButtonIsDisabled.value) {

        primaryButtonIsDisabled.value = false
      }
    }
    function makePaymentRequest() {

      if (link.value) {
        link.value = ''
      }

      if (isSubmitting.value) {
        return;
      }

      validateFormFields();

      if (formErrors.value.any()) {
        return;
      }

      const phoneNumber = removeAllWhitespace(parsePhoneNumber(
        formData.value.phoneNumber, 'KE'
      ).formatInternational());

      isSubmitting.value = true;

      submissionError.value = null;

      const paymentRequestItems = paymentItems.value.map(item => {
        return {
          name: item.name,
          unitPrice: parseInt(item.unitPrice, 10),
          quantity: item.quantity,
          description: item.description.trim()
        }
      })

      LastExpenseExperience.singleton().policyPaymentLink(
        policyId, {
          phoneNumber: phoneNumber,
          description: formData.value.description.trim(),
          checkoutItems: paymentRequestItems
        }
      ).then(response => {

        link.value = response.data;
        linkSuccess.value = true;
        currentStep.value = STEPS.HANDLE_SUCCESS

      }).catch(error => {

        submissionError.value = error;
      }).finally(() => {

        isSubmitting.value = false;
      });
    }

    function close() {

      paymentItems.value = [{...initialItems}];
      emit('close');
    }

    function initialize() {

      const policy = props.policy;

      if(policy) {

        formData.value.description = policy.lastExpensePlan.experience.title || ''
        paymentItems.value[0].name = policy.lastExpensePlan.name || ''
        paymentItems.value[0].quantity = 1
        paymentItems.value[0].unitPrice = policy.lastExpensePlan.premium + ''

      }
    }

    watch(() => props.show, (newValue) => {

        if (newValue) {
          formData.value = {
            phoneNumber: '',
            description: '',
            checkoutItems: [initialItems]
          }
          paymentItems.value = [{...initialItems}];
          currentStep.value = STEPS.SET_PAYMENT_DATA;
          formErrors.value = new Errors();
          isSubmitting.value = false;
          submissionError.value = null;
          linkSuccess.value = false;
          primaryButtonIsDisabled.value = false;
          initialize();
        }
      }
    );

    return {

      link,
      close,
      STEPS,
      formData,
      modalTitle,
      handleNext,
      formErrors,
      removeItem,
      currentStep,
      phoneNumber,
      isSubmitting,
      paymentItems,
      chooseMember,
      handlePrevious,
      submissionError,
      primaryButtonText,
      updatePhoneNumber,
      isCirclePolicy,
      secondaryButtonText,
      primaryButtonIsDisabled,

    };
  }
});

