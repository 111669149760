

import {defineComponent, PropType} from "vue";
import CustomRadio from "../form-elements/CustomRadio.vue";

export default defineComponent({
  components: {CustomRadio},
  props: {

    paymentModeName: {

      type: String as PropType<string>,
      required: true
    },

    checked: {

      type: Boolean as PropType<boolean>,
      required: true
    }
  }
});

