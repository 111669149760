import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_back = _resolveComponent("arrow-back")!

  return (_openBlock(), _createElementBlock("button", {
    class: "border-0 bg-transparent d-flex align-items-center",
    style: {"color":"inherit"},
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
  }, [
    _createVNode(_component_arrow_back, {
      style: _normalizeStyle({ width: _ctx.iconSize + 'px'})
    }, null, 8, ["style"])
  ]))
}