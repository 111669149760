

import {defineComponent, PropType} from "vue";

export default defineComponent({

  props: {

    bgColor: {

      type: String as PropType<string>,
      required: true
    },
    fgColor: {

      type: String as PropType<string>,
      required: true
    },
    maxWidth: {

      type: Number as PropType<number>,
      default: 170
    },
    percentage: {

      type: Number as PropType<number>,
      required: true
    }
  }
})

