export default class Errors {

    private errors: Record<string, string> = {};

    any(): boolean {

        return Object.keys(this.errors).length > 0;
    }

    has(field: string): boolean {

        return field in this.errors;
    }

    get(field: string): string {

        if(this.errors[field]) {

            return this.errors[field];
        }

        return "";
    }

    add(field: string, value: string): void {

        if(value) {

            this.errors[field] = value;

            return;
        }

        this.clear(field);
    }

    clear(field: string): void {

        delete this.errors[field];
    }

    clearAll(): void {

        for (const field in this.errors) {

            delete this.errors[field];
        }
    }
}