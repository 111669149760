
import {computed, defineComponent, PropType} from "vue";
import Warning from "@/icons/solid/warning.vue";
import {AxiosError} from "axios";
import {useErrorUtilities} from "@/hooks/useErrorUtilities";

export default defineComponent({
  components: {Warning},
  props: {

    error: {

      type: [Object, String, null] as PropType<AxiosError | string | null>,
      default: null
    },
  },

  setup(props) {

    const { submissionErrorToString } = useErrorUtilities();

    const errorMessage = computed(() => {

      const error = props.error;

      if(! error) {

        return '';
      }

      if(typeof error === 'string') {

        return error;
      }

      return submissionErrorToString(error);
    });

    return {

      errorMessage
    };
  }
});
