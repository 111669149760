
import {computed, defineComponent, PropType, ref} from "vue";
import {
  BeneficiaryType,
  LastExpenseBeneficiary
} from "@/store/modules/last-expense/types/models";
import {useStore} from "@/hooks/useStore";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import {AxiosError} from "axios";
import FormModalButtons from "@/views/components/form-modal/FormModalButtons.vue";
import ModalHeader from "@/views/components/ModalHeader.vue";
import PolicyMemberDetails
    from "@/views/pages/insurances/last-expense/policy/policy-members/policy-member-modal/PolicyMemberDetails.vue";

export default defineComponent({
  components: {ModalHeader, FormModalButtons, PolicyMemberDetails},

  props: {

    member: {

      type: Object as PropType<LastExpenseBeneficiary>,
      required: true
    },
  },

  setup(props, {emit}) {

    const store = useStore();
    const isSubmitting = ref(false);
    const submissionError = ref<AxiosError | null>(null);

    const isOwner = computed(() => {

      return 'lastExpensePolicyOwnerId' in props.member;
    });

    function deletePolicyBeneficiary() {

      if(isSubmitting.value) { return; }

      isSubmitting.value = true;
      submissionError.value = null;

      LastExpenseExperience.singleton().deletePolicyBeneficiary(
          props.member.lastExpenseBeneficiaryId
      ).then(() => {

        store.commit(MutationTypes.REMOVE_BENEFICIARY,
            props.member.lastExpenseBeneficiaryId);
        close();

      }).catch(error => {

        submissionError.value = error;
        isSubmitting.value = false;
      });
    }

    function cancel() {

      emit('cancel');
    }

    function close() {

      emit('close');
    }

    return {

      close,
      cancel,
      isOwner,
      isSubmitting,
      BeneficiaryType,
      submissionError,
      deletePolicyBeneficiary,
    };
  }
});
