

import {computed, defineComponent, PropType} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/hooks/useStore";

export default defineComponent({
  props: {

    paddingBottom: {

      type: [Number, null] as PropType<number | null>,
      default: null
    },
    title: {
      type: String as PropType<string>,
      default: 'Experience'
    },
    privateNote: {
      type: Boolean as PropType<boolean>,
      default: false
    }

  },
  setup(props) {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const insuranceId = route.params.experienceId as string;

    const isUserLoggedIn = computed( () =>{

      return store.getters.isUserLoggedIn;
    });

    const showPoliciesButton = computed( ()=> {

      return isUserLoggedIn.value && route.name === 'last-expense-view'
    });

    const lastExpenseExperience = computed(() => {

      return store.getters.getLastExperience(insuranceId);
    });

    const experienceInfo = computed(() => {

      const experience = lastExpenseExperience.value;

      return {
        name: experience ? experience.title : ' '+props.title,
        subtitle: experience ? experience.subTitle : ''
      }
    });

    function viewMyPolicies() {

      router.push({
        name: 'last-expense-policies',
        params:  {
          dashboardId: 'user'
        }});
    }

    function viewMyClaims() {

      router.push({
        name: 'last-expense-claims',
        params:  {
          dashboardId: 'user'
        }});
    }

    return {

      viewMyClaims,
      experienceInfo,
      viewMyPolicies,
      showPoliciesButton
    }
  }
});

