

import {computed, defineComponent, PropType, ref} from "vue";
import Spinner from "@/views/components/Spinner.vue";
import CustomRadio from "@/views/components/form-elements/CustomRadio.vue";
import {useStore} from "@/hooks/useStore";
import {useRoute} from "vue-router";
import {FinalMemberCircle} from "@/store/modules/circles/types/state";
import {ActionTypes} from "@/store/modules/circles/types/actions";
import MemberAvatar from "@/views/pages/insurances/shared/member-elements/MemberAvatar.vue";
export default defineComponent( {

    components: {MemberAvatar, CustomRadio, Spinner},
    props: {

        phoneNumber: {

            type: String as PropType<string>,
            default: ''
        }
    },

    setup(props, {emit}) {

        const store = useStore();
        const route = useRoute();
        const circleId = route.params.dashboardId as string;
        const phoneNumber = ref("");

        const userInfo = computed(() => {

            return store.getters.getUserInfo;
        });

        const primaryButtonIsDisabled = computed(() => {

            return ! phoneNumber.value;
        });

        const circleMembers = computed(() => {

            const members = store.getters.getCircleMembers(circleId);

            if(! members) { return members; }

            const membersCopy = members.slice();

            const userId = userInfo.value?.userId;

            let loggedInMember: FinalMemberCircle | null = null;

            for(let i = 0; i < membersCopy.length; i++) {

                if(membersCopy[i].memberId === userId) {

                    loggedInMember = membersCopy[i];
                    membersCopy.splice(i, 1);
                }
            }

            if(loggedInMember) {

                membersCopy.unshift(loggedInMember);
            }

            return membersCopy;
        });

        const formattedCircleMembers = computed(() => {

            const members = circleMembers.value;

            if(! members) { return members; }

            return members.map(member => {

                const selected = props.phoneNumber === member.memberDetails.phoneNumber;

                return {

                    memberId: member.memberId,
                    avatarUrl: member.memberDetails.avatarURL ? member.memberDetails.avatarURL : '',
                    fullName:member.memberDetails.fullName,
                    email: member.memberDetails.email,
                    phoneNumber: member.memberDetails.phoneNumber,
                    selected
                }
            });
        });

        function selectMemberNumber(phone: string) {

            phoneNumber.value = phone;
            emit('phone', phoneNumber.value)
        }

        function initialize() {

            if(! circleMembers.value) {

                store.dispatch(ActionTypes.FETCH_CIRCLE_MEMBERS, {

                    circleId,
                });
            }

            phoneNumber.value = ''
        }

        initialize();

        return {

            selectMemberNumber,
            formattedCircleMembers,
            primaryButtonIsDisabled
        }
    }
})

