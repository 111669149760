

import {defineComponent} from "vue";
import Check from "@/icons/solid/check.vue";
import AutoAwesome from "@/icons/solid/auto-awesome.vue";

export default defineComponent({

  components: {AutoAwesome, Check},

  setup(props, {emit}) {

    function signIn() {

      emit('sign-in');
    }

    return {

      signIn
    };
  }
});

