

import {defineComponent, PropType} from "vue";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {PaymentCard} from "@/store/modules/users/types";
import {PaymentMode} from "@/store/types/baseModels";

export default defineComponent({
  props: {

    paymentMode: {

      type: String as PropType<string>,
      required: true
    },

    paymentCard: {

      type: [Object, null] as PropType<PaymentCard | null>,
      default: null
    },

    premium: {

      type: Number as PropType<number>,
      required: true
    },

    isSubmitting: {

      type: Boolean as PropType<boolean>,
      required: true
    }
  },

  setup(props, {emit}) {

    const { formatCurrency } = useCurrencyUtilities();
    const { ucfirst } = useStringUtilities();

    function initialize() {

      emit('title', 'Confirm policy payment');
    }

    initialize();

    return {

      ucfirst,
      PaymentMode,
      formatCurrency,
    }
  }
});

