import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f0d7035"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-relative" }
const _hoisted_2 = { class: "tw-absolute tw-bottom-0 tw-flex tw-h-24 tw-w-full tw-justify-center tw-items-center" }
const _hoisted_3 = { class: "tw-z-50 tw-flex tw-w-4/12 tw-justify-center" }
const _hoisted_4 = { class: "intro-x carousel-inner tw-relative tw-overflow-hidden tw-w-full panel" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "tw-hidden md:tw-block" }
const _hoisted_8 = { class: "carousel-caption" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 col-lg-6 -intro-y" }
const _hoisted_12 = { class: "p-2 tw-rounded-lg fade-caption" }
const _hoisted_13 = { class: "display-6 tw-font-normal text-primary mb-4" }
const _hoisted_14 = { class: "fs-5 tw-text-gray-700 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ol", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (img, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            class: _normalizeClass(["md:tw-w-4 md:tw-h-4 tw-bg-gray-300 tw-rounded-full tw-cursor-pointer tw-mx-2", _ctx.active === i ? 'bg-primary' : ''])
          }, null, 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (img, i) => {
        return (_openBlock(), _createElementBlock("div", {
          id: `slide-${i}`,
          key: i,
          class: _normalizeClass([_ctx.active === i ? 'active' : 'left-full', "carousel-item tw-inset-0 tw-relative tw-w-full tw-transform tw-transition-all tw-duration-500 tw-ease-in-out faded faded-top"])
        }, [
          _createElementVNode("img", {
            class: "tw-block tw-w-full h-full",
            src: img.image,
            alt: "Malipo insurance"
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_router_link, {
                      to: {name: img.link}
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("h1", _hoisted_13, _toDisplayString(img.caption), 1),
                          _createElementVNode("p", _hoisted_14, _toDisplayString(img.subtitle), 1)
                        ]),
                        _createElementVNode("div", {
                          class: "btn btn-primary mt-2 py-2 px-5",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.jumpTo && _ctx.jumpTo(...args)))
                        }, "More Details")
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ])
                ])
              ])
            ])
          ])
        ], 10, _hoisted_5))
      }), 128))
    ])
  ]))
}