import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e454bbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-h-full tw-relative tw-bg-white tw-rounded-md shadow-md zoom-in" }
const _hoisted_2 = {
  key: 1,
  class: "tw-relative tw-w-full tw-rounded-t-md tw-bg-blue-200",
  style: {"padding-bottom":"50%"}
}
const _hoisted_3 = { class: "tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center" }
const _hoisted_4 = { class: "tw-px-5 tw-pt-5 tw-pb-8" }
const _hoisted_5 = { class: "tw-font-semibold text-primary" }
const _hoisted_6 = {
  key: 0,
  class: "tw-mt-0.5 tw-text-gray-500 text-xs"
}
const _hoisted_7 = {
  key: 1,
  class: "tw-mt-0.5 tw-text-black text-xs"
}
const _hoisted_8 = { class: "tw-mt-1 tw-text-gray-600 tw-text-sm" }
const _hoisted_9 = {
  key: 0,
  class: "d-inline tw-text-indigo-400 text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_last_respect_illustration = _resolveComponent("last-respect-illustration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.route,
      class: "tw-absolute tw-inset-0 tw-z-10"
    }, null, 8, ["to"]),
    (_ctx.avatarUrl)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tw-relative tw-w-full tw-rounded-t-md tw-rounded-b-none avatar",
          style: _normalizeStyle([{"padding-bottom":"50%"}, _ctx.styleObject])
        }, null, 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_last_respect_illustration, { class: "tw-h-3/6" })
          ])
        ])),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
      (_ctx.subTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.subTitle), 1))
        : _createCommentVNode("", true),
      (_ctx.insuranceType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.ucfirst(_ctx.stripUnderscores(_ctx.insuranceType))) + " Insurance ", 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createTextVNode(_toDisplayString(_ctx.cappedDescription) + " ", 1),
        (_ctx.trimDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, " ...more"))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}