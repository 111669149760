import {Member} from "@/store/modules/circles/types/models";
import {Experience} from "@/store/modules/last-expense/baseModels";

export enum LastExpensePolicyStatus {

    PENDING = "PENDING", SUBMITTED = "SUBMITTED", CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED", ACTIVE = "ACTIVE", CLAIMED = "CLAIMED",
    EXPIRED = "EXPIRED"
}

export enum LastExpensePolicyType {

    CIRCLE = "CIRCLE", INDIVIDUAL = "INDIVIDUAL"
}

export enum LastExpensePlanStatus {

    ACTIVE = "ACTIVE", INACTIVE = "INACTIVE"
}

export enum BeneficiaryType {

    SPOUSE = "SPOUSE", PARENT = "PARENT", CHILD = "CHILD"
}

export enum PolicyPaymentStatus {

    PENDING = "PENDING", FAILED = "FAILED", RECEIVED = "RECEIVED"
}

export enum LastExpenseClaimStatus {

    REQUESTED = "REQUESTED", APPROVED = "APPROVED", REJECTED = "REJECTED",
    SETTLED = "SETTLED"
}

export enum ClaimPaymentStatus {

    PENDING = "PENDING", FAILED = "FAILED", RECEIVED = "RECEIVED"
}

export type LastExpenseExperience = Experience & {

    insuranceProvider: string;
}

export enum BeneficiaryStatus {

    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export type LastExpensePolicy = {

    createdOn: Date;
    updatedOn: Date;
    lastExpensePolicyId: string;
    circleId: string;
    memberId: string;
    policyStatus: LastExpensePolicyStatus;
    policyType: LastExpensePolicyType;
    lastExpensePlan: LastExpensePlan;
    owner: LastExpensePolicyOwner;
    circleDetails: LastExpenseCircleDetails | null;
    memberDetails: Member;
}

export type LastExpenseCircleDetails = {

    avatarUrl: string;
    circleId: string;
    circleStatus: string;
    circleType: string;
    description: string;
    name: string;
    walletId: string;
}

export type LastExpensePlan = {

    createdOn: Date;
    updatedOn: Date;
    lastExpensePlanId: string;
    name: string;
    premium: number;
    cover: number;
    maxCoveredMembers: number;
    planStatus: LastExpensePlanStatus;
    experience: Experience,
    additionalData: Record<string, unknown> | null;
}

export type LastExpensePolicyOwner = {

    createdOn: Date;
    updatedOn: Date;
    lastExpensePolicyOwnerId: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    nationalId: string;
    kraPin: string;
}

export type LastExpenseBeneficiary = {

    createdOn: Date;
    updatedOn: Date;
    lastExpenseBeneficiaryId: string;
    nationalId: string;
    fullName: string;
    beneficiaryType: BeneficiaryType;
}

export type LastExpensePolicyPayment = {

    createdOn: Date;
    updatedOn: Date;
    policyPaymentId: string;
    transactionRef: string;
    amount: number;
    policyPaymentStatus: PolicyPaymentStatus;
}

export type LastExpenseClaim = {

    createdOn: Date;
    updatedOn: Date;
    lastExpenseClaimId: string;
    claimStatus: LastExpenseClaimStatus;
    initiatorUserId: string;
    lastExpensePolicy: LastExpensePolicy;
    initiator: Member;
}

export type LastExpenseClaimPayment = {

    createdOn: Date;
    updatedOn: Date;
    claimPaymentId: string;
    transactionRef: string;
    amount: number;
    claimPaymentStatus: ClaimPaymentStatus;
}
