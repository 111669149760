import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw-min-h-screen bgPattern" }
const _hoisted_2 = { class: "tw-py-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_custom_navbar = _resolveComponent("custom-navbar")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lastExpenseInsurance)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_custom_navbar, { "persist-logo": "" }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_spinner, { text: "Loading insurance" })
          ])
        ], 64))
  ]))
}