import { state } from "@/store/modules/users/state";
import { getters } from "@/store/modules/users/getters";
import { mutations } from "@/store/modules/users/mutations";
import { actions } from "@/store/modules/users/actions";

const index = {

    state,
    getters,
    mutations,
    actions
};

export default index;
