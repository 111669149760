import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-100 h-100 rounded-circle d-flex justify-content-center align-items-center mp-text-sm",
  style: { backgroundColor: '#ffffff' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_circle = _resolveComponent("account-circle")!
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createBlock(_component_avatar, {
    url: _ctx.avatarUrl,
    width: _ctx.avatarSize,
    height: _ctx.avatarSize
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_account_circle, {
          class: "w-100",
          style: {"color":"#AEB4BE"}
        })
      ])
    ]),
    _: 1
  }, 8, ["url", "width", "height"]))
}