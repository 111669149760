
import {computed, defineComponent, PropType} from "vue";
import {NormalizedStatus, useNormalizedStatusUtilities} from "@/hooks/useNormalizedStatusUtilities";

export default defineComponent({

  props: {

    status: {

      type: String as PropType<string>,
      required: true
    },
    statusColor: {

      type: [Object, String] as PropType<{
        background: string,
        color: string,
        borderColor?: string
      } | string>,
      default: ""
    },
    normalizedStatus: {

      type: [String, null] as PropType<NormalizedStatus | null>,
      default: null
    },
    showBorder: {

      type: Boolean as PropType<boolean>,
      default: false
    },
    truncate: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props) {

    const { computeNormalizedStatusColors } = useNormalizedStatusUtilities();

    const statusFormatting = computed(() => {

      const data: {
        classes: Array<string>;
        styles: Record<string, string>
      } = {
        classes: [],
        styles: {}
      }

      if(props.normalizedStatus) {

        data.classes = computeEffectiveClasses(
            computeNormalizedStatusColors(props.normalizedStatus));

      } else if(typeof props.statusColor === "string") {

        data.classes = computeEffectiveClasses(
            computeClassesGivenBaseColor(props.statusColor));

      } else {

        const statusColors = props.statusColor;

        data.styles = {
          background: statusColors.background,
          color: statusColors.color,
        }

        if(props.showBorder && statusColors.borderColor) {

          data.styles['borderColor'] = statusColors.borderColor;
        }
      }

      if(props.showBorder) {

        data.classes.push('tw-border');
      }

      return data;
    });

    function computeEffectiveClasses(classes: {
      color: string, backgroundColor: string, borderColor: string
    }) {

      const effectiveClasses = [classes.color, classes.backgroundColor];

      if(props.showBorder) {

        effectiveClasses.push(classes.borderColor);
      }

      return effectiveClasses;
    }

    function computeClassesGivenBaseColor(statusColor: string) {

      return {
        color: 'tw-text-' + statusColor + '-700',
        backgroundColor: 'tw-bg-' + statusColor + '-100',
        borderColor: 'tw-border-' + statusColor + '-400',
      }
    }

    return {

      statusFormatting
    }
  }
});
