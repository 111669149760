import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "border-bottom tw-px-6 tw-py-4 tw-flex tw-justify-between tw-items-center" }
const _hoisted_2 = { class: "tw-font-semibold" }
const _hoisted_3 = { class: "tw-mt-10 tw-flex tw-flex-col tw-items-center" }
const _hoisted_4 = { class: "tw-text-xl tw-font-semibold tw-text-gray-700" }
const _hoisted_5 = { class: "tw-mt-3" }
const _hoisted_6 = { class: "tw-flex tw-items-center tw-justify-end" }
const _hoisted_7 = { class: "tw-py-10 tw--mt-5" }
const _hoisted_8 = { class: "tw-w-full tw-table-fixed tw-text-sm" }
const _hoisted_9 = { class: "tw-pt-5 tw-text-gray-500 tw-text-right tw-pr-1.5" }
const _hoisted_10 = { class: "tw-pt-5 tw-text-gray-700 tw-pl-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x = _resolveComponent("x")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    height: 550,
    "fullscreen-mode-below-sm-active": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, " Payment " + _toDisplayString(_ctx.payment.claimPaymentId), 1),
          _createElementVNode("button", {
            class: "btn border-0 shadow-none p-2 mr--2 tw-leading-none",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_x, { class: "tw-w-5 tw-h-5" })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formattedPayment.amount), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-sm tw-text-center tw-px-4 tw-py-3 tw-w-48", ['tw-bg-' + _ctx.formattedPayment.statusColor + '-100',
               'tw-text-' + _ctx.formattedPayment.statusColor + '-700']])
              }, _toDisplayString(_ctx.formattedPayment.formattedStatus), 3)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentDetails, (itemValue, itemName) => {
                return (_openBlock(), _createElementBlock("tr", { key: itemName }, [
                  _createElementVNode("td", _hoisted_9, _toDisplayString(itemName) + " : ", 1),
                  _createElementVNode("td", _hoisted_10, _toDisplayString(itemValue), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}