import { Getters, State } from "@/store/modules/users/types";
import { GetterTree } from "vuex";
import { RootState } from "@/store";

export const getters: GetterTree<State, RootState> & Getters = {

    getUserInfo: state => state.userInfo,
    getUserWalletInfo: state => state.userWalletInfo,
    getUserPaymentCards: state => state.userPaymentCards,
    getUserSupportedBanks: state => state.supportedBanks,
    getUserBankAccounts: state => state.userBankAccounts,
    getDataForUserTransactionsView: state => {

        const matchingViewData =
            state.dataForUserTransactionsView.length ?
                state.dataForUserTransactionsView[0] : null

        if(! matchingViewData) { return matchingViewData; }

        return Object.assign({}, matchingViewData, {

            items: state.transactions.filter(
                record => matchingViewData.items.includes(record.transactionRef)
            )
        });
    },

    getUserTransaction: state => transactionRef => {

        const matchingTransaction = state.transactions.find(transaction => {

            return transaction.transactionRef === transactionRef;
        });

        return matchingTransaction ? matchingTransaction : null;
    },
};