import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choose_payment_mode = _resolveComponent("choose-payment-mode")!
  const _component_choose_payment_card = _resolveComponent("choose-payment-card")!
  const _component_confirm_payment_details = _resolveComponent("confirm-payment-details")!
  const _component_payment_success_handler = _resolveComponent("payment-success-handler")!
  const _component_base_form_modal = _resolveComponent("base-form-modal")!

  return (_openBlock(), _createBlock(_component_base_form_modal, {
    show: _ctx.show,
    "secondary-action": _ctx.secondaryButtonText,
    "primary-action": _ctx.primaryButtonText,
    "disable-primary-button": _ctx.primaryButtonIsDisabled,
    "is-submitting": _ctx.isSubmitting,
    "submission-error": _ctx.submissionError,
    onClose: _ctx.close,
    onSecondaryAction: _ctx.handlePrevious,
    onPrimaryAction: _ctx.handleNext
  }, {
    default: _withCtx(({ updateTitle }) => [
      (_ctx.currentStep === _ctx.STEPS.CHOOSE_PAYMENT_MODE)
        ? (_openBlock(), _createBlock(_component_choose_payment_mode, {
            key: 0,
            "selected-payment-mode": _ctx.formData.paymentMode,
            title: "Make policy payment",
            "supported-payment-modes": _ctx.supportedPaymentModes,
            onTitle: updateTitle,
            onModeSelected: _ctx.updatePaymentMode
          }, null, 8, ["selected-payment-mode", "supported-payment-modes", "onTitle", "onModeSelected"]))
        : _createCommentVNode("", true),
      (_ctx.currentStep === _ctx.STEPS.CHOOSE_PAYMENT_CARD)
        ? (_openBlock(), _createBlock(_component_choose_payment_card, {
            key: 1,
            "selected-payment-card": _ctx.formData.selectedPaymentCard,
            onTitle: updateTitle,
            onUpdateSelectedPaymentCard: _ctx.updatePaymentCard
          }, null, 8, ["selected-payment-card", "onTitle", "onUpdateSelectedPaymentCard"]))
        : _createCommentVNode("", true),
      (_ctx.currentStep === _ctx.STEPS.CONFIRM_DETAILS)
        ? (_openBlock(), _createBlock(_component_confirm_payment_details, {
            key: 2,
            "payment-mode": _ctx.formData.paymentMode,
            "payment-card": _ctx.formData.selectedPaymentCard,
            premium: _ctx.policy.lastExpensePlan.premium,
            "is-submitting": _ctx.isSubmitting,
            onTitle: updateTitle
          }, null, 8, ["payment-mode", "payment-card", "premium", "is-submitting", "onTitle"]))
        : _createCommentVNode("", true),
      (_ctx.currentStep === _ctx.STEPS.HANDLE_SUCCESS)
        ? (_openBlock(), _createBlock(_component_payment_success_handler, {
            key: 3,
            "payment-mode": _ctx.formData.paymentMode,
            "success-message-title": "Payment initiated",
            "success-message-body": "Your payment was successfully initiated.\n            You can track its progress in the policy payments list. Thank you.",
            onTitle: updateTitle
          }, null, 8, ["payment-mode", "onTitle"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["show", "secondary-action", "primary-action", "disable-primary-button", "is-submitting", "submission-error", "onClose", "onSecondaryAction", "onPrimaryAction"]))
}