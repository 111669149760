import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37030ec1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type"]
const _hoisted_3 = {
  key: 0,
  class: "mt-1 text-danger mp-text-xxs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["intro-x float-container tw-rounded-md border flex", [ _ctx.isActive ? 'active' : '',
         _ctx.isFocused ? 'border-primary border-focus' : (_ctx.error ? 'border-danger' : 'border-default')]])
    }, [
      _createElementVNode("label", {
        for: _ctx.labelText.split(' ').join(''),
        class: _normalizeClass(["text-500 m-0", [ _ctx.isFocused ? 'text-primary' : ( _ctx.error ? 'text-danger' : '')]])
      }, _toDisplayString(_ctx.labelText), 11, _hoisted_1),
      _withDirectives(_createElementVNode("input", {
        class: "w-100 default-text-color",
        id: _ctx.labelText.split(' ').join(''),
        type: _ctx.inputType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
        onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDown && _ctx.handleKeyDown(...args))),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
        autocomplete: "off"
      }, null, 40, _hoisted_2), [
        [_vModelDynamic, _ctx.inputValue]
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}