import {ActionContext, CommitOptions} from "vuex";
import {RootState} from "@/store";
import {Mutations as GlobalMutations} from "@/store/types/mutations";
import {
  DataForCircleTransactionsView,
  FiltersForCircleTransactionsView,
  FiltersForUserCirclesView,
  State,
  FinalMemberCircle,
  DataForUserCirclesView,
} from "@/store/modules/circles/types/state";
import {
  Beneficiary, MemberCircle
} from "@/store/modules/circles/types/models";

export type AugmentedActionContext = Omit<ActionContext<State, RootState>, "commit">
  & {

  commit<K extends keyof GlobalMutations>(
    key: K,
    payload?: Parameters<GlobalMutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<GlobalMutations[K]>;
};

export enum ActionTypes {

  FETCH_DATA_FOR_USER_CIRCLES_VIEW = "FETCH_DATA_FOR_USER_CIRCLES_VIEW",
  FETCH_CIRCLE_STATISTICS = "FETCH_CIRCLE_STATISTICS",
  FETCH_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW =
    "FETCH_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW",
  FETCH_CIRCLE_MEMBERS = "FETCH_CIRCLE_MEMBERS",
  FETCH_BENEFICIARY = "FETCH_BENEFICIARY",
  FETCH_CIRCLE_BENEFICIARIES = "FETCH_CIRCLE_BENEFICIARIES"

}

export type Actions = {

  [ActionTypes.FETCH_DATA_FOR_USER_CIRCLES_VIEW]: (
    ctx: AugmentedActionContext,
    payload: {
      filters: FiltersForUserCirclesView;
      successCallback?:
        (data: DataForUserCirclesView) => void;
      errorCallback?: (error: unknown) => void;
    }
  ) => void | Promise<void>;

  [ActionTypes.FETCH_CIRCLE_STATISTICS]: (
    ctx: AugmentedActionContext,
    payload: {
      circleId: string;
      successCallback?: (
        data: {
          memberCircle: MemberCircle;
          circleMembersCount: number
        }) => void;
      errorCallback?: (error: unknown) => void;
    }
  ) => void | Promise<void>;

  [ActionTypes.FETCH_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW]: (
    ctx: AugmentedActionContext,
    payload: {
      filters: FiltersForCircleTransactionsView;
      successCallback?:
        (data: DataForCircleTransactionsView) => void;
      errorCallback?: (error: unknown) => void;
    }
  ) => void | Promise<void>;

  [ActionTypes.FETCH_CIRCLE_MEMBERS]: (
    ctx: AugmentedActionContext,
    payload: {
      circleId: string;
      successCallback?:
        (data: Array<FinalMemberCircle>) => void;
      errorCallback?: (error: unknown) => void;
    }
  ) => void | Promise<void>;

  [ActionTypes.FETCH_BENEFICIARY]: (
    ctx: AugmentedActionContext,
    payload: {
      beneficiaryId: string;
      successCallback?:
        (beneficiary: Beneficiary) => void;
      errorCallback?: (error: unknown) => void;
    }
  ) => void | Promise<void>;

  [ActionTypes.FETCH_CIRCLE_BENEFICIARIES]: (
    ctx: AugmentedActionContext,
    payload: {
      circleId: string;
      successCallback?:
        (data: Array<Beneficiary>) => void;
      errorCallback?: (error: unknown) => void;
    }
  ) => void | Promise<void>;
}
