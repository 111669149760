
import {defineComponent, PropType, ref} from "vue";
import Errors from "@/views/components/Errors";
import X from "@/icons/solid/x.vue";
import {useValidationUtilities} from "@/hooks/useValidationUtilities";
import SubmissionErrorComponent from "@/views/pages/authenticate/sign-up-modal/SubmissionErrorComponent.vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/authorization/types";

export default defineComponent({
  components: {SubmissionErrorComponent, X},
  props: {

    otpChannel: {

      type: String as PropType<"SMS" | "EMAIL">,
      required: true
    },

    email: {

      type: String as PropType<string>,
      required: true
    }
  },

  setup(props, {emit}) {

    const store = useStore();
    const otp = ref("");
    const formErrors = ref(new Errors());
    const isSubmitting = ref(false);
    const submissionError = ref("");
    const { validateStringFieldIsNotEmpty } = useValidationUtilities();

    function validateRegistration() {

      if(isSubmitting.value) { return; }

      submissionError.value = "";
      formErrors.value.add('otp', validateStringFieldIsNotEmpty(otp.value));

      if(formErrors.value.any()) {

        return;
      }

      isSubmitting.value = true;

      store.dispatch(ActionTypes.VALIDATE_USER_REGISTRATION, {
        email: props.email, otp: otp.value
      }).then(() => {

        emit('registration-validated');

      }).catch(error => {

        if(error.response) {

          if(error.response.status == 400) {

            submissionError.value = "Invalid verification code";
            return;
          }

          if(error.response.status == 422 && error.response.data.message) {

            submissionError.value = error.response.data.message;
            return;
          }
        }

        submissionError.value = "Something went wrong. Please try again.";

      }).finally(() => {

        isSubmitting.value = false;
      });
    }

    function close() {

      emit('close');
    }

    return {

      otp,
      close,
      formErrors,
      isSubmitting,
      submissionError,
      validateRegistration
    };
  }
});

