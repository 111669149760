import { MutationTree } from "vuex";
import { Mutations, State } from "@/store/modules/authorization/types";

export const mutations: MutationTree<State> & Mutations = {

    SET_ACCESS_TOKEN: (state, accessToken) =>
        (state.authorization.accessToken = accessToken),
    SET_REFRESH_TOKEN: (state, refreshToken) =>
        (state.authorization.refreshToken = refreshToken),
    SET_APP_AUTH_TOKEN: (state, accessToken) =>
        (state.appAuthToken = accessToken),
};
