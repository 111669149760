import {
    LastExpensePolicy,
    LastExpensePolicyStatus, LastExpensePolicyType
} from "@/store/modules/last-expense/types/models";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";

export const useLastExpensePoliciesUtilities = () => {

    const { ucfirst } = useStringUtilities();
    const { formatCurrency } = useCurrencyUtilities();

    function computeStatusColor(policyStatus: LastExpensePolicyStatus) {

        if(policyStatus === LastExpensePolicyStatus.PENDING) {

            return "yellow";

        } else if(policyStatus === LastExpensePolicyStatus.SUBMITTED) {

            return "blue";

        } else if(policyStatus === LastExpensePolicyStatus.CONFIRMED) {

            return "cyan";

        } else if(policyStatus === LastExpensePolicyStatus.REJECTED) {

            return "red";

        } else if(policyStatus === LastExpensePolicyStatus.EXPIRED) {

            return "gray";

        } else if(policyStatus === LastExpensePolicyStatus.ACTIVE) {

            return "green";

        } else if(policyStatus === LastExpensePolicyStatus.CLAIMED) {

            return "purple";
        }

        return "gray";
    }

    function formatPolicy(policy: LastExpensePolicy, isUserDashboard: boolean) {

        const circleName = policy.circleDetails ? policy.circleDetails.name : '';
        let formattedPolicyType = policy.owner.fullName;

        if(isUserDashboard) {

            formattedPolicyType = 'Individual policy';

            if(policy.policyType !== LastExpensePolicyType.INDIVIDUAL) {

                formattedPolicyType = circleName ? circleName : 'Group policy';
            }
        }

        return {

            policyId: policy.lastExpensePolicyId,
            policyType: formattedPolicyType,
            userName: policy.owner.fullName,
            circleName: policy.circleDetails ? policy.circleDetails.name : '',
            premium: formatCurrency(policy.lastExpensePlan.premium, 0),
            cover: formatCurrency(policy.lastExpensePlan.cover, 0),
            status: policy.policyStatus,
            plan: policy.lastExpensePlan.name,
            formattedStatus: ucfirst(policy.policyStatus),
            statusColor: computeStatusColor(policy.policyStatus)
        }
    }

    return {

        formatPolicy,
        computeStatusColor,
    };
}
