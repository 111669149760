import {ActionContext, CommitOptions} from "vuex";
import {
    DataForLastExpenseClaimsView,
    FiltersForLastExpenseClaimsView,
    State
} from "@/store/modules/last-expense/types/state";
import {RootState} from "@/store";
import {Mutations as GlobalMutations} from "@/store/types/mutations";
import {
    DataForLastExpensePoliciesView,
    FiltersForLastExpensePoliciesView
} from "@/store/modules/last-expense/types/state";
import {
    LastExpenseBeneficiary, LastExpenseClaim, LastExpenseClaimPayment, LastExpenseExperience, LastExpensePlan,
    LastExpensePolicy,
    LastExpensePolicyPayment
} from "@/store/modules/last-expense/types/models";
import {Experience, PrivateExperience} from "@/store/modules/last-expense/baseModels";
import {AxiosResponse} from "axios";
import {State as AuthState} from "@/store/modules/authorization/types"

export type AugmentedActionContext = Omit<ActionContext<State&AuthState, RootState>, "commit">
    & {

    commit<K extends keyof GlobalMutations>(
        key: K,
        payload?: Parameters<GlobalMutations[K]>[1],
        options?: CommitOptions
    ): ReturnType<GlobalMutations[K]>;
};

export enum ActionTypes {

    FETCH_EXPERIENCES = "FETCH_EXPERIENCES",
    FETCH_PRIVATE_EXPERIENCES = "FETCH_PRIVATE_EXPERIENCES",
    FETCH_EXPERIENCES_WITHOUT_AUTH = "FETCH_EXPERIENCES_WITHOUT_AUTH",
    FETCH_LAST_EXPERIENCE = "FETCH_LAST_EXPERIENCE",
    FETCH_LAST_EXPERIENCE_WITHOUT_AUTH = "FETCH_LAST_EXPERIENCE_WITHOUT_AUTH",
    FETCH_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW =
        "FETCH_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW",
    FETCH_POLICY = "FETCH_POLICY",
    FETCH_POLICY_BENEFICIARIES = "FETCH_POLICY_BENEFICIARIES",
    FETCH_POLICY_PAYMENTS = "FETCH_POLICY_PAYMENTS",
    FETCH_EXPERIENCE_PLANS = "FETCH_EXPERIENCE_PLANS",
    FETCH_EXPERIENCE_PLANS_WITHOUT_AUTH = "FETCH_EXPERIENCE_PLANS_WITHOUT_AUTH",
    FETCH_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW =
        "FETCH_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW",
    FETCH_CLAIM = "FETCH_CLAIM",
    FETCH_CLAIM_PAYMENTS = "FETCH_CLAIM_PAYMENTS",
    FETCH_POLICY_CLAIMS = "FETCH_POLICY_CLAIMS",
}

export type Actions = {

    [ActionTypes.FETCH_EXPERIENCES]: (
        ctx: AugmentedActionContext,
        payload: {
            successCallback?:
                (data: Array<Experience>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;
    [ActionTypes.FETCH_EXPERIENCES_WITHOUT_AUTH]: (
        ctx: AugmentedActionContext,
        payload: {
            onSuccess?:
                (data: Array<Experience>) => void;
            errorCall?: (error: unknown) => void;
        }
    ) => void | Promise<void> | Promise<AxiosResponse>;
    [ActionTypes.FETCH_PRIVATE_EXPERIENCES]: (
        ctx: AugmentedActionContext,
        payload: {
          circleId: string;
          successCallback?:
              (data: Array<PrivateExperience>) => void;
          errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;
    [ActionTypes.FETCH_LAST_EXPERIENCE]: (
        ctx: AugmentedActionContext,
        payload: {
            experienceId: string;
            successCallback?:
                (data: LastExpenseExperience) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_LAST_EXPERIENCE_WITHOUT_AUTH]: (
        ctx: AugmentedActionContext,
        payload: {
            experienceId: string;
            successCallback?:
                (data: LastExpenseExperience) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void> | Promise<AxiosResponse>;

    [ActionTypes.FETCH_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW]: (
        ctx: AugmentedActionContext,
        payload: {
            filters: FiltersForLastExpensePoliciesView;
            successCallback?:
                (data: DataForLastExpensePoliciesView) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_POLICY]: (
        ctx: AugmentedActionContext,
        payload: {
            policyId: string;
            successCallback?:
                (data: LastExpensePolicy) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_POLICY_BENEFICIARIES]: (
        ctx: AugmentedActionContext,
        payload: {
            policyId: string;
            successCallback?:
                (data: Array<LastExpenseBeneficiary>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_POLICY_PAYMENTS]: (
        ctx: AugmentedActionContext,
        payload: {
            policyId: string;
            successCallback?:
                (data: Array<LastExpensePolicyPayment>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_EXPERIENCE_PLANS]: (
        ctx: AugmentedActionContext,
        payload: {
            experienceId: string;
            successCallback?:
                (data: Array<LastExpensePlan>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_EXPERIENCE_PLANS_WITHOUT_AUTH]: (
        ctx: AugmentedActionContext,
        payload: {
            experienceId: string;
            successCallback?:
                (data: Array<LastExpensePlan>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void> | Promise<AxiosResponse>;

    [ActionTypes.FETCH_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW]: (
        ctx: AugmentedActionContext,
        payload: {
            filters: FiltersForLastExpenseClaimsView;
            successCallback?:
                (data: DataForLastExpenseClaimsView) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_CLAIM]: (
        ctx: AugmentedActionContext,
        payload: {
            claimId: string;
            successCallback?:
                (data: LastExpenseClaim) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_CLAIM_PAYMENTS]: (
        ctx: AugmentedActionContext,
        payload: {
            claimId: string;
            successCallback?:
                (data: Array<LastExpenseClaimPayment>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;

    [ActionTypes.FETCH_POLICY_CLAIMS]: (
        ctx: AugmentedActionContext,
        payload: {
            policyId: string;
            successCallback?: (data: Array<LastExpenseClaim>) => void;
            errorCallback?: (error: unknown) => void;
        }
    ) => void | Promise<void>;
}
