import { createRouter, createWebHistory, RouterScrollBehavior } from 'vue-router'
import emitter from "@/event-bus/eventBus";
import routes from "@/router/routes"
import routerStore from "@/router/routerStore";


const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {

  emitter.emit('popstateNavigation', !!savedPosition);

  if (savedPosition) {

    return savedPosition;
  }
  // return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

router.afterEach((to, from) => {

  if(from.name) {

    routerStore.previousRoute = from;

  } else {

    routerStore.previousRoute = null;
  }

});

export default router
