
import {defineComponent, PropType, ref, watch} from "vue";
import BaseModal from "@/views/components/BaseModal.vue";
import Confirmation from "@/views/components/Confirmation.vue";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {mappers} from "@/store/modules/last-expense/mappers";
import {useStore} from "@/hooks/useStore";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";

export default defineComponent({
  components: {Confirmation, BaseModal},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    policyId: {

      type: String as PropType<string>,
      required: true
    }
  },
  setup(props, {emit}) {

    const store = useStore();
    const { mapToTypedLastExpenseClaim } = mappers();
    const isSubmitting = ref(false);
    const submissionError = ref(null);

    const requirements = [

      `The policy is not in waiting period.`,
      `The claim is being made for a member listed in the policy members.`
    ];

    function makeClaim() {

      isSubmitting.value = true;
      submissionError.value = null;

      LastExpenseExperience.singleton().createPolicyClaim(
          props.policyId
      ).then(response => {

        store.commit(MutationTypes.SET_POLICY_CLAIMS, {

          policyId: props.policyId,
          claims: [mapToTypedLastExpenseClaim(response.data)],
          addToExistingClaims: true
        });
        close();

      }).catch(error => {

        submissionError.value = error;
        isSubmitting.value = false;
      });
    }

    function close() {

      emit('close');
    }

    watch(() => props.show, newVal => {

      if(newVal) {

        isSubmitting.value = false;
        submissionError.value = null;
      }
    });

    return {

      close,
      makeClaim,
      requirements,
      isSubmitting,
      submissionError
    }
  }
});
