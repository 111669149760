import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "tw--mt-12 tw-mx-auto tw-relative",
  style: {"max-width":"1392px"}
}
const _hoisted_2 = { class: "sm:tw-mx-4 md:tw-mx-6 lg:tw-mx-10" }
const _hoisted_3 = { class: "tw-mt-2" }
const _hoisted_4 = { class: "tw-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_navbar = _resolveComponent("custom-navbar")!
  const _component_policies_header = _resolveComponent("policies-header")!
  const _component_claim_header = _resolveComponent("claim-header")!
  const _component_claim_payments = _resolveComponent("claim-payments")!
  const _component_claim_policy = _resolveComponent("claim-policy")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_custom_navbar, {
      "show-back-button": false,
      "header-background": "tw-bg-blueGray-100"
    }),
    _createVNode(_component_policies_header, {
      "padding-bottom": 48,
      "show-sub-title": false
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_claim_header, {
          claim: _ctx.validatedClaim,
          "claims-route": _ctx.claimsRoute
        }, null, 8, ["claim", "claims-route"]),
        (_ctx.pageIsReady && _ctx.validatedClaim && _ctx.claimPolicyId)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_claim_payments, { claim: _ctx.validatedClaim }, null, 8, ["claim"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_claim_policy, { "policy-id": _ctx.claimPolicyId }, null, 8, ["policy-id"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}