
import {defineComponent, PropType} from "vue";
import Plans2 from "@/views/pages/insurances/last-expense/new-policy/Plans2.vue"
import {LastExpensePolicyType} from "@/store/modules/last-expense/types/models";

export default defineComponent({
  components: {
    Plans2
  },
  props: {
    circleId: {

      type: String as PropType<string>,
      required: true
    },
    individualSubscription: {

      type: Boolean as PropType<boolean>,
      default: true
    },
    policyType: {

      type: String as PropType<LastExpensePolicyType>,
      required: true
    }
  }

})
