import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw-bg-slate-50 tw-rounded tw-p-4 sm:tw-m-4 tw-flex tw-justify-center tw-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (! _ctx.isLoading)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "text-primary bg-transparent border-0 tw-font-medium",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLoadMore && _ctx.handleLoadMore(...args)))
        }, " Load more "))
      : (_openBlock(), _createBlock(_component_spinner, { key: 1 }))
  ]))
}