
import {computed, defineComponent} from "vue";
import {useStore} from "@/hooks/useStore";
import {useRoute} from "vue-router";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";
export default defineComponent({
    components: {CustomNavbar, Spinner},
    setup() {

        const store = useStore();
        const route = useRoute();
        const experienceId = route.params.experienceId as string;

        const lastExpenseInsurance = computed(() => {

            return store.getters.getLastExperience(experienceId);
        });

        function fetchExperience() {

            store.dispatch(ActionTypes.FETCH_LAST_EXPERIENCE_WITHOUT_AUTH, {

                experienceId: experienceId
            });
        }

        function initialize() {

            if(! lastExpenseInsurance.value) {

                fetchExperience();
            }
        }

        initialize();

        return {

            lastExpenseInsurance
        }
    }
});
