

import {computed, defineComponent} from "vue";
import BackButton from "@/views/components/BackButton.vue";
import {useStore} from "@/hooks/useStore";
import {useRoute} from "vue-router";
import usePopstateNavigation from "@/hooks/usePopstateNavigation";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import Spinner from "@/views/components/Spinner.vue";
import ClaimsTable from "@/views/pages/insurances/last-expense/claims/ClaimsTable.vue";
import ClaimCard from "@/views/pages/insurances/last-expense/claims/ClaimCard.vue";
import PoliciesHeader from "@/views/pages/insurances/last-expense/policies/PoliciesHeader.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
  components: {
    CustomNavbar,
    PoliciesHeader,
    ClaimCard,
    ClaimsTable,
    Spinner,
    BackButton
  },
  setup() {

    const store = useStore();
    const route = useRoute();
    const { initialize } = usePopstateNavigation();
    const dashboardId = route.params.dashboardId as string;
    const USER_DASHBOARD_ID = "user";
    const isUserDashboard = dashboardId === USER_DASHBOARD_ID;
    const circleId = isUserDashboard ? "" : dashboardId;
    const dashboardRoute = {name: 'last-expense-dashboard'};

    const paginatedClaimsData = computed(() => {

      return store.getters.getDataForLastExpenseClaimsView({

        circleId: circleId
      });
    });

    const pageIsReady = computed(() => {

      return !! paginatedClaimsData.value;
    });

    const claims = computed(() => {

      const data = paginatedClaimsData.value;

      if(data) {

        return data.items;
      }

      return [];
    });

    function fetchClaims() {

      store.dispatch(ActionTypes.FETCH_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW, {

        filters: { circleId: circleId},
      });
    }

    function clearClaims() {

      store.commit(MutationTypes.CLEAR_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW, {

        filters: { circleId: circleId},
      });
    }

    initialize((isPopstateNavigation) => {

      if(isPopstateNavigation && paginatedClaimsData.value) {

        return;
      }

      clearClaims();
      fetchClaims();
    });

    return {

      claims,
      pageIsReady,
      dashboardRoute,
      isUserDashboard,
    };
  }
});

