

import {computed, defineComponent} from 'vue';
import {PropType} from "vue";

export default defineComponent({

    props: {

        iconWidthPercent: {

            type: Number as PropType<number>,
            default: 40
        },

        bgColor: {

            type: String as PropType<string>,
            default: "#4B5563"
        },

        url: {

            type: String as PropType<string | null>,
            default: null
        },

        width: {

            type: Number as PropType<number>,
            default: 150,
        },

        height: {

            type: Number as PropType<number>,
            default: 150,
        }

    },

    setup(props) {

        const styleObject = computed(() => {

            return {

                backgroundImage: props.url ? 'url(' + props.url + ')' : '',
                width: props.width + 'px',
                height: props.height + 'px'
            };
        });

        return {

            styleObject,
        };
    }
});

