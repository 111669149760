import axiosInstance from "@/axios/axios";
import {AxiosResponse} from "axios";
import {useDateUtilities} from "@/hooks/useDateUtilities";

class UsersService {

    private static baseUrl = `${process.env.VUE_APP_BASE_URL}/auth/v1`;
    private static instance: UsersService;
    private readonly axiosInstance = axiosInstance;

    static singleton = (): UsersService => {

        if (!UsersService.instance) {

            UsersService.instance = new UsersService();
        }

        return UsersService.instance;
    };

    async fetchLoggedInUserInfo(): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            UsersService.baseUrl + `/users/management/`
        );
    }

    async fetchLoggedInUserWalletInfo(): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            UsersService.baseUrl + `/users/wallet/`
        );
    }

    async updateLoggedInUserInfo(
        firstName: string, lastName: string, idNumber: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.put(
            UsersService.baseUrl + '/users/management/', {

                firstName, lastName, idNumber
            }
        );
    }

    async fetchUserPaymentCards(): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            UsersService.baseUrl + `/card/`
        );
    }

    async fetchSupportedBanks(): Promise<AxiosResponse> {

        return await this.axiosInstance.get(UsersService.baseUrl + `/users/bank/`);
    }

    async fetchUserBankAccounts(): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            UsersService.baseUrl + '/users/bank/account'
        )
    }

    async fetchTransactions(
        pageSize: number, page = 1,
        startDate: Date | null, endDate: Date | null,
    ): Promise<AxiosResponse> {

        const {formatToServerDateTimeString} = useDateUtilities();

        const response = await this.axiosInstance.get(
            UsersService.baseUrl + `/users/wallet/transactions`, {

                params: {

                    page: page - 1, //Server pagination starts at 0
                    size: pageSize,
                    start: startDate ? formatToServerDateTimeString(startDate) : null,
                    end: endDate ? formatToServerDateTimeString(endDate) : null,
                }
            });

        response.data.page++; //App pagination starts at 1

        return response;
    }
}

export default UsersService;
