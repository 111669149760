

import {computed, defineComponent, PropType} from "vue";
import Spinner from "@/views/components/Spinner.vue";
import CustomRadio from "@/views/components/form-elements/CustomRadio.vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes, PaymentCard} from "@/store/modules/users/types";

export default defineComponent({

  components: {CustomRadio, Spinner},

  props: {

    selectedPaymentCard: {

      type: [Object, null] as PropType<PaymentCard | null>,
      default: null
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const paymentCards = computed(() => store.getters.getUserPaymentCards);

    function updateSelectedPaymentCard(value: PaymentCard) {

      emit('update-selected-payment-card', value);
    }

    function close() {

      emit('close');
    }

    function initialize() {

      emit('title', 'Choose payment card');
      store.dispatch(ActionTypes.FETCH_USER_PAYMENT_CARDS);
    }

    initialize();

    return {

      close,
      paymentCards,
      updateSelectedPaymentCard,
    }
  }
});

