import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw-pt-2 d-flex align-items-center" }
const _hoisted_2 = { class: "d-flex mp-text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation = _resolveComponent("confirmation")!

  return (_openBlock(), _createBlock(_component_confirmation, {
    title: 'Cancel Policy',
    body: _ctx.body,
    "secondary-action": "Cancel",
    "primary-action": "Continue",
    "is-loading": _ctx.isSubmitting,
    error: _ctx.submissionError,
    onPrimaryAction: _ctx.submit,
    onSecondaryAction: _ctx.cancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, " Confirm you want to cancel policy " + _toDisplayString(_ctx.policyId), 1)
      ])
    ]),
    _: 1
  }, 8, ["body", "is-loading", "error", "onPrimaryAction", "onSecondaryAction"]))
}