import {useDateUtilities} from "@/hooks/useDateUtilities";
import {
    Beneficiary, ChamaCircle, ChamaMemberCircle,
    Circle,
    CircleStatus,
    CircleType, CircleVisibility, ContributionCycle,
    MemberCircle,
    MembershipType,
} from "@/store/modules/circles/types/models";
import {BeneficiaryStatus} from "@/store/modules/last-expense/types/models";

export type RawCircle = Omit<Circle,
  'circleStatus' | 'circleType' | 'circleVisibility' |
  'membershipType' | 'createdOn' | 'updatedOn'> & {

    circleStatus: string;
    circleType: string;
    circleVisibility: string;
    membershipType: string;
    createdOn: string;
    updatedOn: string;
}
export type RawMemberCircle = Omit<MemberCircle,
  "createdOn" | "updatedOn" | "circle"> & {

    createdOn: string;
    updatedOn: string;
    circle?: RawCircle;
}

export type RawChamaCircle = RawCircle & {

    issuesLoans: boolean;
    supportsGuarantors: boolean;
    loanPenaltyActive: boolean;
    loanType: string;
    minimumCycleContributionAmount: string;
    contributionPenalty: string;
    missedCyclesToPenalty: string;
    totalContributions: string;
    loanInterestRate: string;
    loanMonthlyPenaltyRate: string;
    loanDefaultWindowInDays: string;
    loanGracePeriodInDays: string;
    cycleStart: string;
    contributionCycle: string;
    loanApprovers: string;
    disbursementApprovers: string;
    withdrawalApprovers: string;
    nextContributionDate: string;
}

export type RawChamaMemberCircle = Omit<RawMemberCircle, "circle"> & {

    circle: RawChamaCircle;
}

export type RawBeneficiary = Omit<Beneficiary,
  "beneficiaryStatus" | "createdOn" | "updatedOn"> & {

    beneficiaryStatus: string;
    createdOn: string;
    updatedOn: string;
}

const {

    parseLocalISODate,
    parseServerDateTime,
    stripTimezoneFromISODateTime
} = useDateUtilities();


export const mappers = () => {

    const mapToTypedCircle = (circle: RawCircle): Circle => {

        const {

            createdOn, updatedOn,
            circleStatus, circleType, membershipType, circleVisibility,
            ...otherProps

        } = circle;

        return {

            createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
            updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
            circleStatus: circleStatus as CircleStatus,
            circleType: circleType as CircleType,
            membershipType: membershipType as MembershipType,
            circleVisibility: circleVisibility as CircleVisibility,
            ...otherProps
        };
    }

    const mapToTypedMemberCircle = (memberCircle: RawMemberCircle) => {

        const {
            createdOn, updatedOn, circle, ...otherProps
        } = memberCircle;

        return Object.assign({

              createdOn: parseServerDateTime(
                stripTimezoneFromISODateTime(createdOn)
              ),
              updatedOn: parseServerDateTime(
                stripTimezoneFromISODateTime(updatedOn)
              ),
              ...otherProps
          },
          circle ? { circle : mapToTypedCircle(circle) } : {});
    }

    const mapToTypedChamaCircle = (chamaCircle: RawChamaCircle): ChamaCircle => {

        const {
            createdOn, updatedOn, circleId, name, description,
            avatarUrl, walletId, circleStatus, circleType,
            membershipType, circleVisibility,

            minimumCycleContributionAmount, contributionPenalty,
            missedCyclesToPenalty, totalContributions, loanInterestRate,
            loanMonthlyPenaltyRate, loanDefaultWindowInDays, loanGracePeriodInDays,
            loanApprovers, disbursementApprovers, withdrawalApprovers,

            cycleStart, nextContributionDate,
            contributionCycle,
            ...otherProps

        } = chamaCircle;

        const rawCircle = {

            createdOn, updatedOn, circleId, name, description,
            avatarUrl, walletId, circleStatus, circleType,
            membershipType, circleVisibility,
        };

        const integerFields = {

            minimumCycleContributionAmount, contributionPenalty,
            missedCyclesToPenalty, totalContributions,
            loanDefaultWindowInDays, loanGracePeriodInDays,
            loanApprovers, disbursementApprovers, withdrawalApprovers
        };

        const typedIntegerFields = Object.fromEntries(

            Object.entries(integerFields).map(([k, v]) => {

                return [k, parseInt(v, 10)];
            })

        ) as { [key in keyof typeof integerFields]: number};

        return {

            ...mapToTypedCircle(rawCircle),
            ...typedIntegerFields,
            loanInterestRate: parseFloat(loanInterestRate),
            loanMonthlyPenaltyRate: parseFloat(loanMonthlyPenaltyRate),
            cycleStart: parseLocalISODate(cycleStart),
            nextContributionDate: parseLocalISODate(nextContributionDate),
            contributionCycle: contributionCycle as ContributionCycle,
            ...otherProps
        };
    }

    const mapToTypedBeneficiary = (beneficiary: RawBeneficiary):
      Beneficiary => {

        const {

            beneficiaryStatus, createdOn, updatedOn, bankAccounts, ...otherProps

        } = beneficiary;

        return {

            beneficiaryStatus: beneficiaryStatus as BeneficiaryStatus,
            createdOn: parseServerDateTime(
                stripTimezoneFromISODateTime(createdOn)
            ),
            updatedOn: parseServerDateTime(
                stripTimezoneFromISODateTime(updatedOn)
            ),
            bankAccounts: bankAccounts.filter(value => value),
            ...otherProps
        }
    }

    const mapToTypedChamaMemberCircle =
        (chamaMemberCircle: RawChamaMemberCircle): ChamaMemberCircle => {

            const { circle } = chamaMemberCircle

            return Object.assign(mapToTypedMemberCircle(chamaMemberCircle), {

                circle: mapToTypedChamaCircle(circle)
            });
        }

    return {

        mapToTypedCircle,
        mapToTypedBeneficiary,
        mapToTypedMemberCircle,
        mapToTypedChamaMemberCircle
    }
}
