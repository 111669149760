
import {computed, defineComponent, PropType} from "vue";
import {
  LastExpenseBeneficiary,
  LastExpensePolicyOwner
} from "@/store/modules/last-expense/types/models";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import Pattern from "@/icons/outlined/pattern.vue";
import Person from "@/icons/solid/person.vue";
import Badge from "@/icons/outlined/badge.vue";
import Call from "@/icons/outlined/call.vue";
import Email from "@/icons/outlined/email.vue";
import Pin from "@/icons/outlined/pin.vue";
export default defineComponent({

  props: {

    member: {

      type: Object as PropType<LastExpensePolicyOwner | LastExpenseBeneficiary>,
      required: true
    }
  },

  setup(props) {

    const { ucfirst } = useStringUtilities();

    const isOwner = computed(() => {

      return 'lastExpensePolicyOwnerId' in props.member;
    });

    const memberDetails = computed(() => {

      const member = props.member;
      let relationship = 'Principal';

      if(! isOwner.value) {

        const beneficiary = member as LastExpenseBeneficiary;
        relationship = ucfirst(beneficiary.beneficiaryType);
      }

      const details = [
        {icon: Person, title: 'Name', value: member.fullName},
        {icon: Pattern, title: 'Relationship', value: relationship},
        {icon: Badge, title: 'National id',
          value: member.nationalId ? member.nationalId : 'N/A'}
      ];

      if(isOwner.value) {

        const owner = member as LastExpensePolicyOwner;
        details.push({icon: Call, title: 'Phone number', value: owner.phoneNumber});
        details.push({icon: Email, title: 'Email', value: owner.email});
        details.push({icon: Pin, title: 'KRA Pin',
          value: owner.kraPin ? owner.kraPin : '--'});
      }

      return details;
    });

    return {

      memberDetails
    };
  }
})
