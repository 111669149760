import {ActionTree} from "vuex";
import { FinalMemberCircle, State } from "@/store/modules/circles/types/state";
import {RootState} from "@/store";
import {Actions} from "@/store/modules/circles/types/actions";
import {MutationTypes} from "@/store/modules/circles/types/mutations";
import {storeUtilities} from "@/store/storeUtilities";
import AllCirclesService from "@/services/circles/AllCirclesService";
import {
    mappers as chamaCirclesMappers, RawBeneficiary, RawChamaMemberCircle, RawMemberCircle,
} from "@/store/modules/circles/mappers";
import {CircleStats, CircleType} from "@/store/modules/circles/types/models";
import ChamaCirclesService from "@/services/circles/ChamaCirclesService";
import {TransactionInfo} from "@/store/types/baseModels";
import {RawTransactionInfo, mappers as baseMappers} from "@/store/baseMappers";

const {
    mapToTypedBeneficiary,
    mapToTypedMemberCircle,
    mapToTypedChamaMemberCircle
} = chamaCirclesMappers();

const {
    mapToTypedTransactionInfo
} = baseMappers();

const { fetchMoreViewData } = storeUtilities();

let circleStatsCancellationToken: number;

export const actions: ActionTree<State, RootState> & Actions = {

    FETCH_DATA_FOR_USER_CIRCLES_VIEW: (ctx,
                         payload) => {

        const defaultPageSize = 25;

        fetchMoreViewData<FinalMemberCircle, RawChamaMemberCircle>({

            defaultPageSize,
            viewData: ctx.getters.getDataForUserCirclesView(payload.filters),
            mapper: rawItem => {

                return mapToTypedChamaMemberCircle(rawItem as RawChamaMemberCircle) ;
            },
            comparator: (firstItem, secondItem) => {

                return firstItem.circleId === secondItem.circleId;
            },
            service: (pageToFetch) => {

                return AllCirclesService.singleton().fetchPagedLoggedInUserCircles(
                    payload.filters.type, pageToFetch, defaultPageSize
                );
            }

        }).then(response => {

            const data = {filters: payload.filters, ...response}

            ctx.commit(MutationTypes.SET_DATA_FOR_USER_CIRCLES_VIEW, {

                data,
            });

            if(payload.successCallback) { payload.successCallback(data); }

        }).catch(error => {

            if(payload.errorCallback) { payload.errorCallback(error); }
        });
    },

    FETCH_CIRCLE_STATISTICS:  (ctx, payload) => {

        AllCirclesService.singleton()
            .fetchCircleStatistics(payload.circleId).then(response => {

                const rawMemberCircle = response.data.requester;

                const circle = response.data.circle;

                rawMemberCircle['circle'] = circle;

                let memberCircle = mapToTypedMemberCircle(rawMemberCircle);

                if(circle.circleType === CircleType.CHAMA) {

                    memberCircle = mapToTypedChamaMemberCircle(rawMemberCircle);
                }

                ctx.commit(MutationTypes.SET_USER_CIRCLE, {

                    data: memberCircle
                });

                const membersCount: number = response.data.membersCount

                ctx.commit(MutationTypes.SET_CIRCLE_MEMBERS_COUNT, {

                    circleId: memberCircle.circleId,
                    count: membersCount
                });

            if(payload.successCallback) {

                payload.successCallback({

                    memberCircle,
                    circleMembersCount: membersCount
                });
            }

        }).catch(error => {

            if(payload.errorCallback) { payload.errorCallback(error); }
        });
    },

    FETCH_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW: (ctx,
                                              payload) => {

        const defaultPageSize = 25;

        fetchMoreViewData<TransactionInfo, RawTransactionInfo>({

            defaultPageSize,
            viewData: ctx.getters.getDataForCircleTransactionsView(
                payload.filters
            ),
            mapper: mapToTypedTransactionInfo,
            comparator: (firstItem,
                         secondItem) => {

                return firstItem.transactionRef === secondItem.transactionRef;
            },
            service: (pageToFetch) => {

                return AllCirclesService.singleton()
                    .fetchCircleTransactions(
                        payload.filters.circleId, pageToFetch, defaultPageSize
                    );
            }

        }).then(response => {

            const data = {filters: payload.filters, ...response}

            ctx.commit(MutationTypes.SET_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW, {

                data,
            });

            if(payload.successCallback) { payload.successCallback(data); }

        }).catch(error => {

            if(payload.errorCallback) { payload.errorCallback(error); }
        });
    },

    FETCH_CIRCLE_MEMBERS: (ctx,
                           payload) => {

        AllCirclesService.singleton().fetchNonPagedCircleMembers(
            payload.circleId).then(response => {

            const members = response.data.items.map((item: RawMemberCircle ) => {

                    return mapToTypedMemberCircle(item);
            });

            ctx.commit(MutationTypes.SET_CIRCLE_MEMBERS, {

                circleId: payload.circleId,
                data: members,
            });

            if(payload.successCallback) {

                payload.successCallback(members);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_BENEFICIARY: (ctx,
                         payload) => {

        AllCirclesService.singleton().fetchBeneficiary(payload.beneficiaryId)
            .then(response => {

                const beneficiary = mapToTypedBeneficiary(response.data);

                ctx.commit(MutationTypes.SET_BENEFICIARY, {

                    beneficiary
                });

                if(payload.successCallback) {

                    payload.successCallback(beneficiary);
                }

            }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_CIRCLE_BENEFICIARIES: (ctx,
                                 payload) => {

        AllCirclesService.singleton().fetchNonPagedBeneficiaries(
            payload.circleId).then(response => {

            const beneficiaries = response.data.items.map(
                (item: RawBeneficiary) => mapToTypedBeneficiary(item)
            );

            ctx.commit(MutationTypes.SET_BENEFICIARIES, {

                circleId: payload.circleId,
                beneficiaries: beneficiaries,
            });

            if(payload.successCallback) {

                payload.successCallback(beneficiaries);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_CIRCLE_STATS: (ctx,
                        payload) => {

        const filters = payload.filters;
        const currentCancellationToken = Math.random();
        circleStatsCancellationToken = currentCancellationToken;

        const promise = ChamaCirclesService.singleton()
            .fetchChamaStats(filters.circleId, filters.startDate, filters.endDate);

        promise.then(response => {

            if(circleStatsCancellationToken !== currentCancellationToken) {

                return Promise.reject({cancelled: true});
            }

            const circleStats = response.data as CircleStats;

            ctx.commit(MutationTypes.SET_CIRCLE_STATS, {

                filters: filters,
                data: circleStats,
            });

            if(payload.successCallback) {

                payload.successCallback(circleStats);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },
}
