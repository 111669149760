
import {computed, defineComponent, PropType} from "vue";
import {
  LastExpenseBeneficiary,
  LastExpensePolicyOwner
} from "@/store/modules/last-expense/types/models";
import {useDateUtilities} from "@/hooks/useDateUtilities";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import ChevronRight from "@/icons/chevron-right.vue";
import BaseTable from "@/views/components/BaseTable.vue";
import AccountCircle from "@/icons/solid/account-circle.vue";

export default defineComponent({
  components: {AccountCircle, BaseTable, ChevronRight},
  props: {

    policyOwner: {

      type: Object as PropType<LastExpensePolicyOwner>,
      required: true
    },
    beneficiaries: {

      type: Array as PropType<Array<LastExpenseBeneficiary>>,
      required: true
    }
  },

  setup(props, {emit}) {

    const {formatToRelativeDate} = useDateUtilities();
    const { ucfirst } = useStringUtilities();

    const formattedBeneficiaries = computed(() => {

      const formattedData = props.beneficiaries.map(beneficiary => {

        return {

          id: beneficiary.lastExpenseBeneficiaryId,
          fullName: beneficiary.fullName,
          beneficiaryType: ucfirst(beneficiary.beneficiaryType),
          nationalId: beneficiary.nationalId,
          createdOn: formatToRelativeDate(beneficiary.createdOn)
        }
      });

      const owner = props.policyOwner;

      formattedData.push({

        id: owner.lastExpensePolicyOwnerId,
        fullName: owner.fullName,
        beneficiaryType: 'Principal',
        nationalId: owner.nationalId,
        createdOn: formatToRelativeDate(owner.createdOn)
      });

      return formattedData;
    });

    function viewMember(memberId: string) {

      emit('view-member', memberId);
    }

    return {

      viewMember,
      formattedBeneficiaries
    }
  }
});
