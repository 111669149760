import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw-px-5 sm:tw-px-8 tw-pt-6 tw-pb-8" }
const _hoisted_2 = { class: "tw-flex tw-items-center tw-justify-between" }
const _hoisted_3 = { class: "tw-text-gray-600 tw-text-sm tw-font-semibold" }
const _hoisted_4 = { class: "tw-pt-5 tw-text-gray-700 tw-text-sm" }
const _hoisted_5 = { class: "tw-mt-2" }
const _hoisted_6 = { class: "tw-mt-2" }
const _hoisted_7 = { class: "tw-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_right = _resolveComponent("chevron-right")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedPolicy.policyId), 1),
      _createElementVNode("button", {
        class: _normalizeClass(["tw-group tw-px-0 tw--mr-2 tw-rounded-full tw-border-0 tw-flex tw-items-center tw-transition tw-duration-500 tw-ease-in-out", ['hover:tw-bg-'+ _ctx.formattedPolicy.statusColor + '-100']]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.viewRecord && _ctx.viewRecord(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xs tw-text-center tw-p-3 tw-w-32 sm:tw-w-40", ['tw-bg-' + _ctx.formattedPolicy.statusColor + '-200',
               'tw-text-' + _ctx.formattedPolicy.statusColor + '-700']])
        }, _toDisplayString(_ctx.formattedPolicy.formattedStatus), 3),
        _createVNode(_component_chevron_right, {
          class: _normalizeClass(["tw-ml-1 sm:tw-ml-2 tw-w-5 tw-text-gray-400 tw-transition tw-duration-500 tw-ease-in-out", ['group-hover:tw-text-'+ _ctx.formattedPolicy.statusColor + '-500']])
        }, null, 8, ["class"])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, _toDisplayString(_ctx.formattedPolicy.policyType), 1),
      _createElementVNode("div", _hoisted_5, " Plan: " + _toDisplayString(_ctx.formattedPolicy.plan), 1),
      _createElementVNode("div", _hoisted_6, " Premium: Ksh " + _toDisplayString(_ctx.formattedPolicy.premium), 1),
      _createElementVNode("div", _hoisted_7, " Cover: Ksh " + _toDisplayString(_ctx.formattedPolicy.cover), 1)
    ])
  ]))
}