import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 intro-x" }
const _hoisted_2 = { class: "service-item box tw-bg-white rounded h-100 p-5 hover:tw-shadow-lg" }
const _hoisted_3 = { class: "d-flex align-items-center tw--ml-12 mb-4" }
const _hoisted_4 = { class: "service-icon flex-shrink-0 bg-primary rounded-end me-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "tw-font-semibold mb-0" }
const _hoisted_7 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "img-fluid",
            src: `/icons/${_ctx.icon}.png`,
            alt: "Malipo Insurance"
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.subtitle), 1)
    ])
  ]))
}