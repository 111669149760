

import {computed, defineComponent, nextTick, ref, PropType, watch} from "vue";
import ClipboardList from "@/icons/clipboard-list.vue";
import LinkUrl from "@/icons/link-url.vue";
import CheckCircle from "@/icons/solid/CheckCircle.vue";
import ShareButton from "@/views/pages/insurances/shared/ShareButton.vue";

export default defineComponent({
    components: {ShareButton, CheckCircle, LinkUrl, ClipboardList},

    props: {
        link: {
            type: String as PropType<string>,
            default: ''
        }
    },
    setup(props, {emit}) {

        const generatedLink = ref<string>(props.link)
        const linkCopied = ref(false);
        const paymentLink = ref<HTMLInputElement | null>(null)
        const title = computed(() => {

            return 'Payment request link success'
        });

        function copyLink() {

            nextTick(() => {

                paymentLink.value?.focus();
                document.execCommand('copy');
                linkCopied.value = true;
                setTimeout(() => linkCopied.value = false, 3500);

            });
        }

        watch(() => title.value, newVal => {

            emit('title', newVal);

        }, {immediate: true});

        return {
            copyLink,
            linkCopied,
            paymentLink,
            generatedLink
        }
    }
});

