

import {defineComponent, PropType} from "vue";

export default defineComponent({

  props: {

    text: {

      type: String as PropType<string>,
      default: ""
    },
    showBoundingBox: {

      type: Boolean as PropType<boolean>,
      default: false
    },
    boundingBoxRounding: {

      type: String as PropType<string>,
      default: 'md'
    }
  },
});

