

import {computed, defineComponent, PropType} from "vue";
import {LastExpensePolicy, LastExpensePolicyType,} from "@/store/modules/last-expense/types/models";
import {useRouter} from "vue-router";
import ChevronRight from "@/icons/chevron-right.vue";
import {
    useLastExpensePoliciesUtilities
} from "@/views/pages/insurances/last-expense/policies/useLastExpensePoliciesUtilities";
import BaseTable from "@/views/components/BaseTable.vue";

export default defineComponent({
  components: {BaseTable, ChevronRight},
  props: {

    policies: {

      type: Array as PropType<Array<LastExpensePolicy>>,
      required: true
    },
    isUserDashboard: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    onSlider: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props) {

    const router = useRouter();
    const { formatPolicy } = useLastExpensePoliciesUtilities();

    const formattedPolicies = computed(() => {

      return props.policies.map(policy => {

        return formatPolicy(policy, props.isUserDashboard);
      });
    });

    function viewRecord(policyId: string) {

      if (props.onSlider) {

        router.push({
          name: 'member-policy',
          params: {policyId: policyId}
        });
      } else {

        router.push({
          name: 'last-expense-policy',
          params: {policyId: policyId}
        });
      }
    }

    return {

      viewRecord,
      formattedPolicies,
      LastExpensePolicyType
    };
  }
});

