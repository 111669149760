import { State } from "@/store/modules/authorization/types";

export const state = (): State => ({

  authorization: {

    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken")
  },
  appAuthToken: sessionStorage.getItem("appAuthToken"),
});
