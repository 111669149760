
import {computed, defineComponent, PropType, ref, watch} from "vue";
import Errors from "@/views/components/Errors";
import {useValidationUtilities} from "@/hooks/useValidationUtilities";
import {
  BeneficiaryType,
  LastExpenseBeneficiary,
  LastExpensePolicy,
  LastExpensePolicyOwner
} from "@/store/modules/last-expense/types/models";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import CustomSelect from "@/views/components/form-elements/CustomSelect.vue";
import {mappers} from "@/store/modules/last-expense/mappers";
import {useStore} from "@/hooks/useStore";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import {AxiosError, AxiosResponse} from "axios";
import ModalHeader from "@/views/components/ModalHeader.vue";
import FormModalButtons from "@/views/components/form-modal/FormModalButtons.vue";

export default defineComponent({
  components: {FormModalButtons, ModalHeader, CustomSelect},

  props: {

    member: {

      type: Object as PropType<LastExpensePolicyOwner | LastExpenseBeneficiary>,
      required: true
    },
    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },

  setup(props, {emit}) {

    const {validateStringFieldIsNotEmpty} = useValidationUtilities();
    const { stripUnderscores, ucfirst } = useStringUtilities();
    const {
      mapToTypedLastExpenseBeneficiary,
      mapToTypedLastExpensePolicyOwner
    } = mappers();
    const store = useStore();
    const formErrors = ref(new Errors());
    const isSubmitting = ref(false);
    const submissionError = ref<AxiosError | string>("");

    const beneficiaryTypes = Object.fromEntries(Object.values(BeneficiaryType)
        .map(k => [k, stripUnderscores(ucfirst(k))]));

    const isOwner = computed(() => {

      return 'lastExpensePolicyOwnerId' in props.member;
    });

    const formData = ref({

      fullName: props.member.fullName,
      beneficiaryType: isOwner.value ? BeneficiaryType.PARENT :
          (props.member as LastExpenseBeneficiary).beneficiaryType,
      nationalId: props.member.nationalId,
      phoneNumber: isOwner.value ?
          (props.member as LastExpensePolicyOwner).phoneNumber : '',
      email: isOwner.value ?
          (props.member as LastExpensePolicyOwner).email : '',
      kraPin: isOwner.value && (props.member as LastExpensePolicyOwner).kraPin ?
          (props.member as LastExpensePolicyOwner).kraPin : '',
    });

    function editPolicyMember() {

      if(isSubmitting.value) { return; }

      validateFormFields();

      if(formErrors.value.any()) {

        submissionError.value = "Form has errors";
        return;
      }

      isSubmitting.value = true;
      submissionError.value = "";

      const promise: Promise<AxiosResponse> = isOwner.value ?
          editPolicyOwner(formData.value) : editPolicyBeneficiary(formData.value);

      promise.then(() => {

        cancel();

      }).catch(error => {

        submissionError.value = error;
        isSubmitting.value = false;
      });
    }

    function editPolicyOwner(data: typeof formData.value) {

      const ownerId = (props.member as LastExpensePolicyOwner).lastExpensePolicyOwnerId;
      const {fullName, nationalId, email, kraPin, phoneNumber} = data;

      return LastExpenseExperience.singleton().updatePolicyOwner(
          ownerId, {fullName, nationalId, email, kraPin, phoneNumber}
      ).then(response => {

        const newPolicy = Object.assign({}, props.policy, {

          owner: mapToTypedLastExpensePolicyOwner(response.data)
        });

        store.commit(MutationTypes.SET_POLICY, {

          data: newPolicy
        });

        return response;

      });
    }

    function editPolicyBeneficiary(data: typeof formData.value) {

      const beneficiaryId =
          (props.member as LastExpenseBeneficiary).lastExpenseBeneficiaryId;
      const {fullName, beneficiaryType, nationalId} = data;

      return LastExpenseExperience.singleton().updatePolicyBeneficiary(
          beneficiaryId, Object.assign({fullName, beneficiaryType},
              nationalId ? {nationalId} : {})
      ).then(response => {

        store.commit(MutationTypes.SET_POLICY_BENEFICIARIES, {

          policyId: props.policy.lastExpensePolicyId,
          beneficiaries: [mapToTypedLastExpenseBeneficiary(response.data)],
          addToExistingBeneficiaries: true
        });

        return response;

      });
    }

    function validateFormFields() {

      formErrors.value.add('fullName',
          validateStringFieldIsNotEmpty(formData.value.fullName));

      if(! isOwner.value && formData.value.beneficiaryType !== BeneficiaryType.CHILD) {

        formErrors.value.add('nationalId',
            validateStringFieldIsNotEmpty(formData.value.nationalId));

      } else {

        formErrors.value.add('nationalId', '');
      }

      if(isOwner.value) {

        formErrors.value.add('email',
            validateStringFieldIsNotEmpty(formData.value.email));
        formErrors.value.add('phoneNumber',
            validateStringFieldIsNotEmpty(formData.value.phoneNumber));
        formErrors.value.add('kraPin',
            validateStringFieldIsNotEmpty(formData.value.kraPin));
      }
    }

    function cancel() {

      emit('cancel');
    }

    function close() {

      emit('close');
    }

    watch(() => formData.value.beneficiaryType, (newVal) => {

      if(newVal === BeneficiaryType.CHILD) {

        validateFormFields();
      }

    });

    watch(() => formData.value, () => {

      submissionError.value = "";

    }, {deep: true});

    return {

      close,
      cancel,
      isOwner,
      formData,
      formErrors,
      isSubmitting,
      BeneficiaryType,
      submissionError,
      beneficiaryTypes,
      editPolicyMember,
    };
  }
});
