import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_policy_cancellation = _resolveComponent("policy-cancellation")!
  const _component_policy_removal_success = _resolveComponent("policy-removal-success")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    closeOnMaskClick: false,
    "fullscreen-mode-below-sm-active": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (! _ctx.cancelledPolicyData)
          ? (_openBlock(), _createBlock(_component_policy_cancellation, {
              key: 0,
              show: _ctx.show,
              onClose: _ctx.close,
              onCancelledPolicy: _ctx.updatedPolicyData
            }, null, 8, ["show", "onClose", "onCancelledPolicy"]))
          : (_openBlock(), _createBlock(_component_policy_removal_success, {
              key: 1,
              onClose: _ctx.close
            }, null, 8, ["onClose"]))
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}