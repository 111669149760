import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-pt-6 tw-text-red-600 tw-flex tw-items-center"
}
const _hoisted_2 = { class: "tw-text-xs tw-ml-2 break-long-words" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning = _resolveComponent("warning")!

  return (_ctx.errorMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_warning, { class: "tw-flex-shrink-0 tw-w-4" }),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1)
      ]))
    : _createCommentVNode("", true)
}