
import {defineComponent, PropType, ref} from "vue";

export default defineComponent ({

  props: {
    sliders: {
      type: Array as PropType<Array<{image: string; caption: string; subtitle: string; link: string }>>,
      required: true
    }
  },
  setup(props, {emit}) {


    const active = ref(0);

    let i = 0;

    setInterval(() => {
      if (i > props.sliders.length - 1) {
        i = 0;
      }
      active.value = i;
      i++;}, 5000);

    function jumpTo() {

      emit('switchPoint')
    }

    return {
      active,
      jumpTo
    }

  }
})
