import {
    LastExpenseClaimStatus, LastExpenseClaim,
} from "@/store/modules/last-expense/types/models";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {useDateUtilities} from "@/hooks/useDateUtilities";

export const useLastExpenseClaimsUtilities = () => {

    const { ucfirst } = useStringUtilities();
    const { formatCurrency } = useCurrencyUtilities();
    const { formatToRelativeDate } = useDateUtilities();

    function computeStatusColor(status: LastExpenseClaimStatus) {

        if(status === LastExpenseClaimStatus.REQUESTED || status === LastExpenseClaimStatus.APPROVED) {

            return "yellow";

        } else if(status === LastExpenseClaimStatus.SETTLED) {

            return "green";

        } else if(status === LastExpenseClaimStatus.REJECTED) {

            return "red";
        }

        return "gray";
    }

    function formatClaim(claim: LastExpenseClaim) {

        const initiator = claim.initiatorUserId ===
            claim.lastExpensePolicy.memberId ? 'Principal' : 'Circle admin';

        return {

            initiator: initiator,
            claimId: claim.lastExpenseClaimId,
            principalName: claim.lastExpensePolicy.owner.fullName,
            principalEmail: claim.lastExpensePolicy.owner.email,
            cover: formatCurrency(claim.lastExpensePolicy.lastExpensePlan.cover, 0),
            createdOn: formatToRelativeDate(claim.createdOn),
            status: claim.claimStatus,
            formattedStatus: ucfirst(claim.claimStatus),
            statusColor: computeStatusColor(claim.claimStatus)
        }
    }

    return {

        formatClaim,
        computeStatusColor,
    };
}