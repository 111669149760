
import {defineComponent, PropType} from "vue";
import X from "@/icons/solid/x.vue";

export default defineComponent({
  components: {X},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },

    errorMessage: {

      type: String as PropType<string>,
      required: true
    }
  },

  setup(props, {emit}) {

    function close() {

      emit('close');
    }

    return {

      close
    }
  }
});
