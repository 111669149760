

import {defineComponent, PropType} from "vue";
import {AxiosError} from "axios";
import SubmissionErrorHandler from "@/views/components/SubmissionErrorHandler.vue";

export default defineComponent({
  components: {SubmissionErrorHandler},
  props: {

    secondaryAction: {

      type: String as PropType<string>,
      default: ""
    },

    primaryAction: {

      type: String as PropType<string>,
      default: ""
    },

    disablePrimaryButton: {

      type: Boolean as PropType<boolean>,
      default: false
    },

    isSubmitting: {

      type: Boolean as PropType<boolean>,
      default: false
    },

    submissionError: {

      type: [Object, String, null] as PropType<AxiosError | string | null>,
      default: null
    },
  },

  setup(props, {emit}) {

    function handleSecondaryAction() {

      emit('secondary-action');
    }

    function handlePrimaryAction() {

      emit('primary-action');
    }

    return {

      handlePrimaryAction,
      handleSecondaryAction
    }
  }
});

