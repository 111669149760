import {
  Organisation,
  OrganisationStatus,
  PaymentMode,
  TransactionInfo,
  TransactionStatus, TransactionType
} from "@/store/types/baseModels";
import {useDateUtilities} from "@/hooks/useDateUtilities";

export type RawTransactionInfo = {

  amount: number | string;
  createdOn: string;
  destinationAccountId: string;
  paymentMode: string;
  sourceAccountId: string;
  transactionCost: number | string;
  transactionDescription: string;
  transactionRef: string;
  transactionStatus: string;
  transactionType: string;
  updatedOn: string;
};

export type RawOrganisation = Omit<Organisation,
  'createdOn' | 'organisationStatus' | 'updatedOn' | 'parent'> & {

  createdOn: string;
  organisationStatus: string;
  updatedOn: string;
  parent: RawOrganisation;
}

const {
  parseServerDateTime,
  stripTimezoneFromISODateTime,
  parseServerWithNormalDateTime
} = useDateUtilities();

export const mappers = () => {

  /*======================Organisation mapper======================*/
  const mapToTypedOrganisation = (organisation: RawOrganisation): Organisation => {

    const {

      createdOn, updatedOn, organisationStatus,parent,  ...otherProps

    } = organisation;

    return {
      organisationStatus: organisationStatus as OrganisationStatus,
      createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
      updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
      parent: parent ? mapToTypedOrganisation(parent) : null,
      ...otherProps
    }
  }

  /*======================Transaction mapper======================*/
  function mapToTypedTransactionInfo (tx: RawTransactionInfo): TransactionInfo {

    const {
      paymentMode, transactionStatus,
      transactionType, amount, transactionCost, createdOn, updatedOn,...otherProps
    } = tx;

    return {

      paymentMode: paymentMode as PaymentMode,
      transactionStatus: transactionStatus as TransactionStatus,
      transactionType: transactionType as TransactionType,
      amount: parseFloat(amount + ""),
      transactionCost: parseFloat(transactionCost + ""),
      createdOn: parseServerWithNormalDateTime(stripTimezoneFromISODateTime(createdOn)),
      updatedOn: parseServerWithNormalDateTime(stripTimezoneFromISODateTime(updatedOn)),
      ...otherProps
    };
  }

  return {
    mapToTypedOrganisation,
    mapToTypedTransactionInfo
  }
}
