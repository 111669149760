import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation = _resolveComponent("confirmation")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    "fullscreen-mode-below-sm-active": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_confirmation, {
        title: "Submit policy",
        body: "You are about the submit policy for evaluation. After being submitted,\n              the information provided in the policy cannot be changed.",
        "secondary-action": "Cancel",
        "primary-action": "Submit policy",
        "is-loading": _ctx.isSubmitting,
        error: _ctx.submissionError,
        onPrimaryAction: _ctx.submit,
        onSecondaryAction: _ctx.close
      }, null, 8, ["is-loading", "error", "onPrimaryAction", "onSecondaryAction"])
    ]),
    _: 1
  }, 8, ["show"]))
}