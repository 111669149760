
import {computed, defineComponent, PropType, ref} from "vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import {ExperienceType, InsuranceType} from "@/store/modules/last-expense/baseModels";
import {useStringUtilities} from "@/hooks/useStringUtilities";
import Insurance from "@/views/pages/home-view/Insurance.vue";
import InsuranceDropdown from "@/views/pages/home-view/InsuranceDropdown.vue";
export default defineComponent({
    components: {InsuranceDropdown, Insurance, Spinner},
    props: {
        showTitle: {

            type: Boolean as PropType<boolean>,
            default: true
        },
        title: {

            type: String as PropType<string>,
            default: "Malipo insurances"
        },
        dashboardId: {

            type: String as PropType<string>,
            default: ""
        }
    },
    setup(props) {

        const store = useStore();
        const experienceType = ref<'EVENT' | 'LAST_EXPENSE'>('LAST_EXPENSE');
        const selectedInsurance = ref("");
        const { ucfirst } = useStringUtilities();

        const experiences = computed(() => {

            return store.getters.getExperiences;
        });

        const lastExpenseInsurances = computed(() => {

            const allExperiences = experiences.value;

            if(! allExperiences) {

                return null;
            }

            return allExperiences.filter(experience =>
                experience.experienceType === ExperienceType.LAST_EXPENSE
            );
        });

        const insuranceFilters = computed(() => {

            return InsuranceType
        });

        function selectedOption(selectedInsuranceType:string) {

            selectedInsurance.value = selectedInsuranceType;
        }

        function getInsuranceRoute(experienceId: string) {

            if(props.dashboardId) {

                return {
                    name: 'last-expense-dashboards',
                    params: {
                        experienceId: experienceId,
                        dashboardId: props.dashboardId
                    }
                };

            }

            return {
                name: 'last-expense-insurance',
                params: {experienceId: experienceId}
            };
        }

        function fetchExperiences() {

            store.dispatch(ActionTypes.FETCH_EXPERIENCES_WITHOUT_AUTH, {});
        }

        function initialize() {

            if(! experiences.value) {

                fetchExperiences();
            }
        }

        initialize();

        return {

            ucfirst,
            experiences,
            InsuranceType,
            experienceType,
            selectedOption,
            insuranceFilters,
            selectedInsurance,
            getInsuranceRoute,
            lastExpenseInsurances,
        }
    }
});
