

import {defineComponent, PropType, ref, watch} from 'vue';
import ModalHeader from "@/views/components/ModalHeader.vue";

export default defineComponent({
  components: {ModalHeader},
  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },

    height: {

      type: [Number, null] as PropType<number | null>,
      default: null
    },

    width: {

      type: Number as PropType<number>,
      default: 460
    },

    closeOnMaskClick: {

      type: Boolean as PropType<boolean>,
      default: false
    },

    fullscreenModeBelowSmActive: {

      type: Boolean as PropType<boolean>,
      default: true
    },

    title: {

      type: String as PropType<string>,
      default: ""
    },

    showCloseButton: {

      type: Boolean as PropType<boolean>,
      default: true
    },

    showHeaderBorder: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props, {emit}) {

    const mediaQuery = ref<MediaQueryList | null>(null);

    const modalContainerOuterElement = ref<HTMLElement | null>(null);
    const modalContainerInnerElement = ref<HTMLElement | null>(null);

    function createMatchMediaForMinWidthSm(): MediaQueryList {

      return window.matchMedia('(min-width: 576px)');
    }

    function minWidthSmHandler(e: MediaQueryListEvent | MediaQueryList) {

      if(props.fullscreenModeBelowSmActive) {

        if (e.matches) {

          updateModalContainerForSmAndAbove();

        } else {

          updateModalContainerForBelowSm();
        }

      } else {

        updateModalContainerForSmAndAbove();
      }
    }

    function updateModalContainerForBelowSm() {

      if(modalContainerInnerElement.value) {

        modalContainerInnerElement.value.style.maxHeight = '100%';
        modalContainerInnerElement.value.style.height = '100%';
      }

      if(modalContainerOuterElement.value) {

        modalContainerOuterElement.value.style.maxWidth = '100%';
        modalContainerOuterElement.value.style.maxHeight = '100%';
        modalContainerOuterElement.value.style.height = '100%';
        modalContainerOuterElement.value.classList.remove('mx-2');
      }
    }

    function updateModalContainerForSmAndAbove() {

      if(modalContainerInnerElement.value) {

        const defaultMaxHeight = 650;

        const maxHeight = props.height && props.height < defaultMaxHeight ?
            props.height : defaultMaxHeight

        modalContainerInnerElement.value.style.maxHeight = maxHeight + 'px';

        if(props.height) {

          modalContainerInnerElement.value.style.height = '100%';

        } else {

          modalContainerInnerElement.value.style.height = 'auto';
        }
      }

      if(modalContainerOuterElement.value) {

        modalContainerOuterElement.value.style.maxWidth = props.width + 'px';
        modalContainerOuterElement.value.style.maxHeight = '90%';
        modalContainerOuterElement.value.style.height = '100%';
        modalContainerOuterElement.value.classList.add('mx-2');
      }
    }

    function afterLeave() {

      emit('after-leave');
    }

    function handleMaskClick() {

      if(props.closeOnMaskClick) {

        close();
      }
    }

    function close() {

      emit('close');
    }

    watch(() => props,
        (newProps) => {

            if(newProps.show) {

              if(! mediaQuery.value) {

                mediaQuery.value = createMatchMediaForMinWidthSm();

                mediaQuery.value.addListener(minWidthSmHandler);
              }

              minWidthSmHandler(mediaQuery.value);

            } else if(mediaQuery.value) {

              mediaQuery.value.removeListener(minWidthSmHandler);

              mediaQuery.value = null;
            }
        },
        {

          deep: true,
          immediate: true
        }
    )

    return {

      close,
      afterLeave,
      handleMaskClick,
      modalContainerOuterElement,
      modalContainerInnerElement
    };
  }
});

