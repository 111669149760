

import {defineComponent, PropType} from "vue";
import Spinner from "@/views/components/Spinner.vue";

export default defineComponent({
  components: {Spinner},
  props: {

    isLoading: {

      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  setup(props, {emit}) {

    function handleLoadMore() {

      emit('load-more');
    }

    return {

      handleLoadMore
    }
  }
});

