import {
    DataForLastExpenseClaimsView, FiltersForLastExpenseClaimsView,
    State
} from "@/store/modules/last-expense/types/state";
import {
    DataForLastExpensePoliciesView,
    FiltersForLastExpensePoliciesView
} from "@/store/modules/last-expense/types/state";
import {
    LastExpenseBeneficiary, LastExpenseClaim, LastExpenseClaimPayment, LastExpenseExperience, LastExpensePlan,
    LastExpensePolicy,
    LastExpensePolicyPayment
} from "@/store/modules/last-expense/types/models";
import {Experience, PrivateExperience} from "@/store/modules/last-expense/baseModels";

export enum MutationTypes {

    SET_EXPERIENCES = "SET_EXPERIENCES",
    SET_PRIVATE_EXPERIENCES = "SET_PRIVATE_EXPERIENCES",
    SET_LAST_EXPERIENCE = "SET_LAST_EXPERIENCE",
    SET_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW =
        "SET_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW",
    CLEAR_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW =
        "CLEAR_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW",
    SET_POLICY = "SET_POLICY",
    SET_POLICY_BENEFICIARIES = "SET_POLICY_BENEFICIARIES",
    REMOVE_BENEFICIARY = "REMOVE_BENEFICIARY",
    SET_POLICY_PAYMENTS = "SET_POLICY_PAYMENTS",
    SET_EXPERIENCE_PLANS = "SET_EXPERIENCE_PLANS",
    SET_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW =
        "SET_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW",
    CLEAR_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW =
        "CLEAR_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW",
    SET_CLAIM = "SET_CLAIM",
    SET_CLAIM_PAYMENTS = "SET_CLAIM_PAYMENTS",
    SET_POLICY_CLAIMS = "SET_POLICY_CLAIMS",
}

export type Mutations<S = State> = {

    [MutationTypes.SET_EXPERIENCES]:
        (state: S, payload: {

            experiences: Array<Experience>;
        }) => void;
    [MutationTypes.SET_PRIVATE_EXPERIENCES]:
        (state: S, payload: {

          circleId: string;
          privateExperiences: Array<PrivateExperience>;
        }) => void;
    [MutationTypes.SET_LAST_EXPERIENCE]:
        (state: S, payload: {

            data: LastExpenseExperience;
        }) => void;

    [MutationTypes.SET_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW]:
        (state: S, payload: {

            data: DataForLastExpensePoliciesView;
        }) => void;

    [MutationTypes.CLEAR_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW]:
        (state: S, payload: {

            filters: FiltersForLastExpensePoliciesView
        }) => void;

    [MutationTypes.SET_POLICY]:
        (state: S, payload: {

            data: LastExpensePolicy;
        }) => void;

    [MutationTypes.SET_POLICY_BENEFICIARIES]:
        (state: S, payload: {

            policyId: string;
            beneficiaries: Array<LastExpenseBeneficiary>;
            addToExistingBeneficiaries?: boolean;
        }) => void;

    [MutationTypes.REMOVE_BENEFICIARY]:
        (state: S, beneficiaryId: string) => void;

    [MutationTypes.SET_POLICY_PAYMENTS]:
        (state: S, payload: {

            policyId: string;
            payments: Array<LastExpensePolicyPayment>;
            addToExistingPayments?: boolean;
        }) => void;

    [MutationTypes.SET_EXPERIENCE_PLANS]:
        (state: S, payload: {

            experienceId: string;
            plans: Array<LastExpensePlan>;
        }) => void;

    [MutationTypes.SET_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW]:
        (state: S, payload: {

            data: DataForLastExpenseClaimsView;
        }) => void;

    [MutationTypes.CLEAR_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW]:
        (state: S, payload: {

            filters: FiltersForLastExpenseClaimsView
        }) => void;

    [MutationTypes.SET_CLAIM]:
        (state: S, payload: {

            data: LastExpenseClaim;
        }) => void;

    [MutationTypes.SET_CLAIM_PAYMENTS]:
        (state: S, payload: {

            claimId: string;
            payments: Array<LastExpenseClaimPayment>;
            addToExistingPayments?: boolean;
        }) => void;

    [MutationTypes.SET_POLICY_CLAIMS]:
        (state: S, payload: {

            policyId: string;
            claims: Array<LastExpenseClaim>;
            addToExistingClaims?: boolean;
        }) => void;
}