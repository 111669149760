import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2ecf762"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "mp-text-xs text-muted",
          for: _ctx.id
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      class: _normalizeClass(["form-control select-custom", _ctx.inputClasses]),
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: _ctx.isArray(_ctx.options) ? item : index,
          value: _ctx.isArray(_ctx.options) ? item: index
        }, _toDisplayString(item), 9, _hoisted_3))
      }), 128))
    ], 10, _hoisted_2), [
      [_vModelSelect, _ctx.selectedItem]
    ])
  ]))
}