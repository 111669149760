import {RouterView} from 'vue-router'
import Login from "@/views/pages/authenticate/Login.vue";
import Authenticate from "@/views/pages/authenticate/Authenticate.vue";
import Home from "@/views/pages/home-view/Home.vue";
import LastExpenseInsurance from "@/views/pages/insurances/last-expense/LastExpenseInsurance.vue";
import LastExpensePolicies from "@/views/pages/insurances/last-expense/policies/LastExpensePolicies.vue";
import LastExpenseNewPolicy from "@/views/pages/insurances/last-expense/new-policy/LastExpenseNewPolicy.vue";
import LastExpensePolicy from "@/views/pages/insurances/last-expense/policy/LastExpensePolicy.vue";
import LastExpenseClaims from "@/views/pages/insurances/last-expense/claims/LastExpenseClaims.vue";
import LastExpenseClaim from "@/views/pages/insurances/last-expense/claim/LastExpenseClaim.vue";
import LastExpense2 from "@/views/pages/insurances/last-expense/last-expense-view/LastExpense2.vue";
import PrivateInsurance from "@/views/pages/insurances/last-expense/private-insurance/PrivateInsurance.vue";
export default [
    {
        path: '/',
        name: 'home',
        redirect: '/insurance'
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        props: true,
        meta: {

            requiresGuest: true,
        }
    },
    {
        path: '/authenticate',
        name: 'authenticate',
        component: Authenticate,
        props: true,
        meta: {

            requiresGuest: true,
        }
    },
    {
        path: '/insurance',
        component: RouterView,
        children: [
            {
                path: '',
                name: 'home-view',
                component: Home,
            },
            {
                path: 'last-expense',
                name: 'expense-insurance',
                component: Home,
            },
            {
                path: 'medical',
                name: 'medical-insurance',
                component: Home,
            },
            {
                path: 'vehicle',
                name: 'vehicle-insurance',
                component: Home,
            },
            {
                path: 'house',
                name: 'house-insurance',
                component: Home,
            }
            ]
    },
    {
        path: '/private-insurance',
        name: 'private-insurance',
        component: PrivateInsurance,
    },
    {
        path: '/last-expense/:experienceId',
        name: 'last-expense-insurance',
        component: LastExpenseInsurance,
        redirect: {
            name: 'last-expense-view'
        },
        children: [
            {
                path: '',
                name: 'last-expense-view',
                component: LastExpense2,
            },
            {
                path: 'policies/:dashboardId',
                component: RouterView,
                name: 'last-expense-policies-wrapper',
                redirect: {
                    name: 'last-expense-policies'
                },
                children: [
                    {
                        path: '',
                        name: 'last-expense-policies',
                        component: LastExpensePolicies,
                    },
                    {
                        path: 'add-new',
                        name: 'last-expense-new-policy',
                        component: LastExpenseNewPolicy,
                    },
                    {
                        path: 'view/:policyId',
                        name: 'last-expense-policy',
                        component: LastExpensePolicy,
                    },
                ]
            },
            {
                path: 'policies/:dashboardId/claims',
                component: RouterView,
                name: 'last-expense-claims-wrapper',
                children: [
                    {
                        path: '',
                        name: 'last-expense-claims',
                        component: LastExpenseClaims,
                    },
                    {
                        path: ':claimId',
                        name: 'last-expense-claim',
                        component: LastExpenseClaim,
                    },
                ]
            },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: { name: 'home-view' }
    },
    {
        path: "/404",
        redirect: '/insurance'
    },
];
