import {ActionTree} from "vuex";
import {State} from "@/store/modules/last-expense/types/state";
import {RootState} from "@/store";
import {Actions} from "@/store/modules/last-expense/types/actions";
import {storeUtilities} from "@/store/storeUtilities";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import {
    mappers,
    RawExperience,
    RawLastExpenseBeneficiary,
    RawLastExpenseClaim, RawLastExpenseClaimPayment,
    RawLastExpensePlan,
    RawLastExpensePolicy,
    RawLastExpensePolicyPayment, RawPrivateExperience,
} from "@/store/modules/last-expense/mappers";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {LastExpenseClaim, LastExpensePolicy} from "@/store/modules/last-expense/types/models";
import AllExperiencesService from "@/services/experiences/AllExperiencesService";
import {submitRequestWithAppAccessToken} from "@/store/modules/authorization/actions";
import {State as AuthState} from "@/store/modules/authorization/types";

const {
    mapToTypedExperience,
    mapToTypedLastExpensePlan,
    mapToTypedLastExpenseClaim,
    mapToTypedLastExpensePolicy,
    mapToTypedPrivateExperience,
    mapToTypedLastExpenseExperience,
    mapToTypedLastExpenseBeneficiary,
    mapToTypedLastExpenseClaimPayment,
    mapToTypedLastExpensePolicyPayment,
} = mappers();


const {
    fetchMoreViewData
} = storeUtilities();

export const actions: ActionTree<State & AuthState, RootState> & Actions = {

    FETCH_EXPERIENCES_WITHOUT_AUTH: (ctx, payload) => {

        return submitRequestWithAppAccessToken(ctx,async(appAccessToken) => {

            try {

                const response = await AllExperiencesService.singleton().fetchNonPagedActiveExperiencesWithoutAuth(
                    appAccessToken );

                const experiences = response.data.items.map(
                    (experience: RawExperience) => mapToTypedExperience(experience));

                ctx.commit(MutationTypes.SET_EXPERIENCES, {
                    experiences: experiences,
                });

                if (payload.onSuccess) {

                    payload.onSuccess(experiences);
                }

                return response;

            } catch (error) {

                if(payload.errorCall) {

                    payload.errorCall(error);
                }

                throw error;
            }
        });

    },

    FETCH_EXPERIENCES: (ctx,
                        payload = {}) => {

        AllExperiencesService.singleton()
            .fetchNonPagedActiveExperiences()
            .then(response => {

                const experiences = response.data.items.map(
                    (experience: RawExperience) => mapToTypedExperience(experience)
                );

                ctx.commit(MutationTypes.SET_EXPERIENCES, {
                    experiences: experiences,
                });

                if(payload.successCallback) {

                    payload.successCallback(experiences);
                }

            }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_PRIVATE_EXPERIENCES: (ctx,
                                payload) => {

        AllExperiencesService.singleton()
            .fetchNonPagedPrivateExperiences(payload.circleId)
            .then(response => {

                const experiences = response.data.items.map(
                    (experience: RawPrivateExperience) => mapToTypedPrivateExperience(experience)
                );

                ctx.commit(MutationTypes.SET_PRIVATE_EXPERIENCES, {
                    circleId: payload.circleId,
                    privateExperiences: experiences,
                });

                if(payload.successCallback) {

                    payload.successCallback(experiences);
                }

            }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },
    FETCH_LAST_EXPERIENCE: (ctx,
                            payload) => {

        AllExperiencesService.singleton()
            .fetchExperience(payload.experienceId).then(response => {

            const experience = mapToTypedLastExpenseExperience(response.data);

            ctx.commit(MutationTypes.SET_LAST_EXPERIENCE, { data: experience });

            if(payload.successCallback) {

                payload.successCallback(experience);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_LAST_EXPERIENCE_WITHOUT_AUTH: (ctx, payload) => {

        return submitRequestWithAppAccessToken(ctx,async(appAccessToken) => {

            try {
                const response = await AllExperiencesService.singleton().fetchExperienceWithoutAuth(
                    payload.experienceId, appAccessToken);

                const experience = mapToTypedLastExpenseExperience(response.data);

                ctx.commit(MutationTypes.SET_LAST_EXPERIENCE, {data: experience});

                if (payload.successCallback) {

                    payload.successCallback(experience);
                }

                return response;

            } catch (error) {

                if (payload.errorCallback) {

                    payload.errorCallback(error);
                }

                throw error;
            }

        });


    },

    FETCH_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW: (ctx,
                                                payload) => {

        const defaultPageSize = 25;

        fetchMoreViewData<LastExpensePolicy, RawLastExpensePolicy>({

            defaultPageSize,
            viewData: ctx.getters.getDataForLastExpensePoliciesView(
                payload.filters
            ),
            mapper: mapToTypedLastExpensePolicy,
            comparator: (firstItem,
                         secondItem) => {

                return firstItem.lastExpensePolicyId ===
                    secondItem.lastExpensePolicyId;
            },
            service: (pageToFetch) => {

                if(payload.filters.circleId.trim()) {

                    return LastExpenseExperience.singleton().fetchCirclePolicies(
                        payload.filters.circleId, pageToFetch, defaultPageSize
                    );
                }

                return LastExpenseExperience.singleton().fetchUserPolicies(
                    pageToFetch, defaultPageSize
                );
            }

        }).then(response => {

            const data = {filters: payload.filters, ...response};

            ctx.commit(MutationTypes.SET_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW, {

                data,
            });

            if(payload.successCallback) { payload.successCallback(data); }

        }).catch(error => {

            if(payload.errorCallback) { payload.errorCallback(error); }
        });
    },

    FETCH_POLICY: (ctx,
                         payload) => {

        LastExpenseExperience.singleton()
            .fetchPolicy(payload.policyId).then(response => {

            const policy = mapToTypedLastExpensePolicy(response.data);

            ctx.commit(MutationTypes.SET_POLICY, { data: policy });

            if(payload.successCallback) {

                payload.successCallback(policy);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_POLICY_BENEFICIARIES: (ctx,
                   payload) => {

        LastExpenseExperience.singleton()
            .fetchNonPagedPolicyBeneficiaries(payload.policyId).then(response => {

            const beneficiaries = response.data.items.map(
                (beneficiary: RawLastExpenseBeneficiary) =>
                    mapToTypedLastExpenseBeneficiary(beneficiary));

            ctx.commit(MutationTypes.SET_POLICY_BENEFICIARIES, {
                policyId: payload.policyId,
                beneficiaries: beneficiaries
            });

            if(payload.successCallback) {

                payload.successCallback(beneficiaries);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_POLICY_PAYMENTS: (ctx,
                            payload) => {

        LastExpenseExperience.singleton()
            .fetchNonPagedPolicyPayments(payload.policyId).then(response => {

            const payments = response.data.items.map(
                (payment: RawLastExpensePolicyPayment) =>
                    mapToTypedLastExpensePolicyPayment(payment));

            ctx.commit(MutationTypes.SET_POLICY_PAYMENTS, {
                policyId: payload.policyId,
                payments: payments
            });

            if(payload.successCallback) {

                payload.successCallback(payments);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_EXPERIENCE_PLANS: (ctx,
                             payload) => {

        LastExpenseExperience.singleton()
            .fetchNonPagedActiveExperiencePlans(payload.experienceId)
            .then(response => {

            const plans = response.data.items.map(
                (plan: RawLastExpensePlan) =>
                    mapToTypedLastExpensePlan(plan));

            ctx.commit(MutationTypes.SET_EXPERIENCE_PLANS, {
                experienceId: payload.experienceId,
                plans: plans,
            });

            if(payload.successCallback) {

                payload.successCallback(plans);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_EXPERIENCE_PLANS_WITHOUT_AUTH: (ctx, payload) => {

        return submitRequestWithAppAccessToken(ctx,appAccessToken => {

            return LastExpenseExperience.singleton()
                .fetchNonPagedActivePlansWithoutAuth(payload.experienceId, appAccessToken)
                .then(response => {

                    const plans = response.data.items.map(
                        (plan: RawLastExpensePlan) =>
                            mapToTypedLastExpensePlan(plan));

                    ctx.commit(MutationTypes.SET_EXPERIENCE_PLANS, {
                        experienceId: payload.experienceId,
                        plans: plans,
                    });

                    if(payload.successCallback) {

                        payload.successCallback(plans);
                    }

                    return response;

                }).catch(error => {

                    if(payload.errorCallback) {

                        payload.errorCallback(error);
                    }

                    throw error;
                });
        });

    },

    FETCH_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW: (ctx,
                                                payload) => {

        const defaultPageSize = 25;

        fetchMoreViewData<LastExpenseClaim, RawLastExpenseClaim>({

            defaultPageSize,
            viewData: ctx.getters.getDataForLastExpenseClaimsView(
                payload.filters
            ),
            mapper: mapToTypedLastExpenseClaim,
            comparator: (firstItem,
                         secondItem) => {

                return firstItem.lastExpenseClaimId === secondItem.lastExpenseClaimId;
            },
            service: (pageToFetch) => {

                if(payload.filters.circleId.trim()) {

                    return LastExpenseExperience.singleton().fetchCircleClaims(
                        payload.filters.circleId, pageToFetch, defaultPageSize
                    );
                }

                return LastExpenseExperience.singleton().fetchUserClaims(
                    pageToFetch, defaultPageSize
                );
            }

        }).then(response => {

            const data = {filters: payload.filters, ...response};

            ctx.commit(MutationTypes.SET_DATA_FOR_LAST_EXPENSE_CLAIMS_VIEW, {

                data,
            });

            if(payload.successCallback) { payload.successCallback(data); }

        }).catch(error => {

            if(payload.errorCallback) { payload.errorCallback(error); }
        });
    },

    FETCH_CLAIM: (ctx,
                   payload) => {

        LastExpenseExperience.singleton()
            .fetchClaim(payload.claimId).then(response => {

            const claim = mapToTypedLastExpenseClaim(response.data);

            ctx.commit(MutationTypes.SET_CLAIM, { data: claim });

            if(payload.successCallback) {

                payload.successCallback(claim);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_CLAIM_PAYMENTS: (ctx,
                            payload) => {

        LastExpenseExperience.singleton()
            .fetchNonPagedClaimPayments(payload.claimId).then(response => {

            const payments = response.data.items.map(
                (payment: RawLastExpenseClaimPayment) =>
                    mapToTypedLastExpenseClaimPayment(payment));

            ctx.commit(MutationTypes.SET_CLAIM_PAYMENTS, {
                claimId: payload.claimId,
                payments: payments
            });

            if(payload.successCallback) {

                payload.successCallback(payments);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },

    FETCH_POLICY_CLAIMS: (ctx,
                            payload) => {

        LastExpenseExperience.singleton()
            .fetchNonPagedPolicyClaims(payload.policyId).then(response => {

            const claims = response.data.items.map(
                (claim: RawLastExpenseClaim) =>
                    mapToTypedLastExpenseClaim(claim));

            ctx.commit(MutationTypes.SET_POLICY_CLAIMS, {
                policyId: payload.policyId,
                claims: claims
            });

            if(payload.successCallback) {

                payload.successCallback(claims);
            }

        }).catch(error => {

            if(payload.errorCallback) {

                payload.errorCallback(error);
            }
        });
    },
}
