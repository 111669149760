import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21a58e4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "list-unstyled font-weight-normal box my-0 py-2 shadow-lg",
  style: {"min-width":"240px"}
}
const _hoisted_2 = {
  class: "d-flex text-primary tw-font-medium align-items-center justify-content-start zoom-in",
  style: {"height":"52px"}
}
const _hoisted_3 = {
  class: "d-flex align-items-center tw-font-medium justify-content-start zoom-in",
  style: {"height":"52px"}
}
const _hoisted_4 = {
  key: 0,
  class: "d-flex tw-font-medium align-items-center justify-content-start zoom-in",
  style: {"height":"52px"}
}
const _hoisted_5 = {
  class: "d-flex align-items-center justify-content-start zoom-in",
  style: {"height":"52px"}
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_dropdown_button = _resolveComponent("navbar-dropdown-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_dropdown = _resolveComponent("base-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_dropdown, { "dropdown-margin-top": 12 }, {
      "title-container": _withCtx(() => [
        _createVNode(_component_navbar_dropdown_button)
      ]),
      default: _withCtx((slotProps) => [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _createVNode(_component_router_link, {
              class: "px-4 navbar-dropdown-link d-inline-block w-100 h-100 d-flex align-items-center",
              to: {name: 'home-view'},
              onClick: e => {slotProps.closeDropdown();
                _ctx.navigate(e, 'home-view')}
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Home ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _createElementVNode("li", _hoisted_3, [
            _createVNode(_component_router_link, {
              class: "px-4 navbar-dropdown-link d-inline-block w-100 h-100 d-flex align-items-center",
              to: {name: 'private-insurance'},
              onClick: e => {slotProps.closeDropdown();
              _ctx.navigate(e, 'private-insurance')}
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Private insurances ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          (_ctx.isPoliciesRoute)
            ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                _createVNode(_component_router_link, {
                  class: "px-4 navbar-dropdown-link d-inline-block w-100 h-100 d-flex align-items-center",
                  to: {name: 'last-expense-policies', params: {dashboardId: 'user'}},
                  onClick: e => {slotProps.closeDropdown();
            _ctx.navigate(e, 'last-expense-policies',{dashboardId: 'user'})}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" My Policies ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("button", {
              class: "px-4 border-0 text-primary tw-font-medium navbar-dropdown-link d-inline-block w-100 h-100 d-flex align-items-center",
              onClick: e => {slotProps.closeDropdown();
                _ctx.navigate(e, 'logout')}
            }, "Logout", 8, _hoisted_6)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}