import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tw-px-6 tw-pb-52" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_modal_buttons = _resolveComponent("form-modal-buttons")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    height: 550,
    "fullscreen-mode-below-sm-active": true,
    title: _ctx.effectiveTitle,
    "show-close-button": ! _ctx.isSubmitting,
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", { updateTitle: _ctx.updateSlotTitle }),
        _createVNode(_component_form_modal_buttons, {
          "primary-action": _ctx.primaryAction,
          "secondary-action": _ctx.secondaryAction,
          "disable-primary-button": _ctx.disablePrimaryButton,
          "is-submitting": _ctx.isSubmitting,
          "submission-error": _ctx.submissionError,
          onSecondaryAction: _ctx.handleSecondaryAction,
          onPrimaryAction: _ctx.handlePrimaryAction
        }, null, 8, ["primary-action", "secondary-action", "disable-primary-button", "is-submitting", "submission-error", "onSecondaryAction", "onPrimaryAction"])
      ])
    ]),
    _: 3
  }, 8, ["show", "title", "show-close-button", "onClose"]))
}