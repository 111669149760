

import {computed, defineComponent, PropType, ref, watch} from "vue";
import Errors from "@/views/components/Errors";
import {LastExpensePlan, LastExpensePolicyType,} from "@/store/modules/last-expense/types/models";
import LastExpenseExperience from "@/services/experiences/LastExpenseExperience";
import {AxiosError, AxiosResponse} from "axios";
import BaseFormModal from "@/views/components/form-modal/BaseFormModal.vue";
import ChooseMembers from "@/views/pages/insurances/shared/member-elements/ChooseMembers.vue";
import ConfirmPolicyDetails
    from "@/views/pages/insurances/last-expense/new-policy/new-policy-modal/ConfirmPolicyDetails.vue";

export default defineComponent({

  components: {
      ConfirmPolicyDetails,
      ChooseMembers,
    BaseFormModal,
  },

  props: {

    show: {

      type: Boolean as PropType<boolean>,
      required: true
    },
    policyType: {

      type: String as PropType<LastExpensePolicyType>,
      required: true
    },
    plan: {

      type: Object as PropType<LastExpensePlan>,
      required: true
    },
    circleId: {
      type: String as PropType<string>,
      default: ''
    }
  },

  setup(props, {emit}) {

    enum STEPS  {

      CHOOSE_MEMBERS = "CHOOSE_MEMBERS",
      CONFIRM_DETAILS = "CONFIRM_DETAILS",
    }

    const isSubmitting = ref(false);
    const submissionError = ref<AxiosError | null>(null);
    const formErrors = ref(new Errors());
    const formData = ref<{ members: Array<string>, }>({
      members: [],
    });
    const currentStep = ref<STEPS>(getInitialStep());

    const isIndividualPolicy = computed(() => {

      return props.policyType === LastExpensePolicyType.INDIVIDUAL;
    });

    const secondaryButtonText = computed(() => {

      return isIndividualPolicy.value || currentStep.value === STEPS.CHOOSE_MEMBERS ?
          'Cancel' : 'Previous';
    });

    const primaryButtonText = computed(() => {

      return currentStep.value === STEPS.CONFIRM_DETAILS ? 'Create' : 'Next';
    });

    const primaryButtonIsDisabled = computed(() => {

      return currentStep.value === STEPS.CHOOSE_MEMBERS &&
          !formData.value.members.length;
    });

    function handlePrevious() {

      if(props.policyType === LastExpensePolicyType.INDIVIDUAL) {

        return close();
      }

      if(currentStep.value === STEPS.CHOOSE_MEMBERS) {

        close();

      } else if(currentStep.value === STEPS.CONFIRM_DETAILS) {

        submissionError.value = null;
        currentStep.value = STEPS.CHOOSE_MEMBERS;
      }
    }

    function handleNext() {

      if(props.policyType === LastExpensePolicyType.INDIVIDUAL) {

        registerPolicies();
        return;
      }

      if(currentStep.value === STEPS.CHOOSE_MEMBERS) {

        if(! formData.value.members.length) {

          return;
        }

        currentStep.value = STEPS.CONFIRM_DETAILS;

      } else if(currentStep.value === STEPS.CONFIRM_DETAILS) {

        registerPolicies();
      }
    }

    function registerPolicies() {

      if(isSubmitting.value) { return; }

      isSubmitting.value = true;
      submissionError.value = null;

      const promise: Promise<AxiosResponse> =
          props.policyType === LastExpensePolicyType.INDIVIDUAL ?
          registerIndividualPolicy() : registerCirclePolicies(formData.value);

      promise.then(() => {

        handleSuccess();

      }).catch(error => {

        submissionError.value = error;
        isSubmitting.value = false;
      });
    }

    function registerIndividualPolicy() {

      return LastExpenseExperience.singleton().registerIndividualPolicy(
          props.plan.lastExpensePlanId
      );
    }

    function registerCirclePolicies(data: typeof formData.value) {

      return LastExpenseExperience.singleton().registerCirclePolicies(
          props.circleId, {planId: props.plan.lastExpensePlanId, memberIds: data.members}
      );
    }

    function updateSelectedMembers(memberIds: Array<string>) {

      formData.value.members = memberIds;
    }

    function getInitialStep() {

      return props.policyType === LastExpensePolicyType.CIRCLE ?
          STEPS.CHOOSE_MEMBERS : STEPS.CONFIRM_DETAILS;
    }

    function handleSuccess() {

      emit('success');
      close();
    }

    function close() {

      emit('close');
    }

    watch(() => props.show, newVal => {

      if(newVal) {

        currentStep.value = getInitialStep();
        formData.value = { members: [] };
        isSubmitting.value = false;
        submissionError.value = null;
        formErrors.value = new Errors();
      }
    });

    return {

      close,
      STEPS,
      formData,
      handleNext,
      formErrors,
      currentStep,
      isSubmitting,
      handleSuccess,
      handlePrevious,
      submissionError,
      primaryButtonText,
      isIndividualPolicy,
      secondaryButtonText,
      updateSelectedMembers,
      primaryButtonIsDisabled,
    };
  }
});

