import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tw-ml-5" }
const _hoisted_2 = { class: "tw-text-xs" }
const _hoisted_3 = { class: "tw-text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberDetails, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "tw-flex tw-items-center tw-pt-8",
      key: item.title
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "tw-w-6 tw-h-6 tw-text-gray-400" })),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.value), 1)
      ])
    ]))
  }), 128))
}