

import {computed, defineComponent, ref} from "vue";
import BackButton from "@/views/components/BackButton.vue";
import {useRoute} from "vue-router";
import PoliciesHeader from "@/views/pages/insurances/last-expense/policies/PoliciesHeader.vue";
import InsurancePlans from "@/views/pages/insurances/last-expense/last-expense-view/InsurancePlans.vue";
import {LastExpensePolicyType} from "@/store/modules/last-expense/types/models";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
  components: {
    CustomNavbar,
    InsurancePlans,
    PoliciesHeader,
    BackButton,
  },
  setup() {

    const route = useRoute();
    const dashboardId = route.params.dashboardId as string;
    const circleId = dashboardId === 'user' ? '' : dashboardId;
    const isIndividualSubscription = ref(dashboardId === 'user');

    const policyType = computed (() => {

      return dashboardId === 'user'? LastExpensePolicyType.INDIVIDUAL : LastExpensePolicyType.CIRCLE
    })


    const policiesRoute = {

      name: 'last-expense-policies'
    }

    return {

      circleId,
      policyType,
      policiesRoute,
      isIndividualSubscription
    };
  }
});

