

import ServiceCard from "@/views/components/ServiceCard.vue";
import {defineComponent} from "vue";
export default defineComponent ( {

    components: { ServiceCard},
    setup() {

        const servicesInfo = [
            {
                title: 'Last Expense',
                subtitle: 'Ease the Burden, Embrace Peace and ensure a lasting legacy with Malipo Last Expense Insurance.',
                btnText: 'Explore',
                icon: 'LifeInsurance'
            }, {
                title: 'Medical Insurance',
                subtitle: 'Stay protected from unexpected medical expenses. Choose our curated reliable medical insurances for complete peace of mind.',
                btnText: 'Explore',
                icon: 'MedicalInsurance'
            }, {
                title: 'Vehicle Insurance',
                subtitle: 'Drive with confidence and peace of mind. Protect your vehicle with our Malipo vehicle insurance coverage.',
                btnText: 'Explore',
                icon: 'VehicleInsurance'
            }, {
                title: 'Home Insurance',
                subtitle: 'Protect your most valuable asset. Safeguard your home with our Malipo comprehensive insurance coverage tailored to your needs.',
                btnText: 'Explore more',
                icon: 'PropertyInsurance'
            }, {
                title: 'Business Insurance',
                subtitle: 'Safeguard your business, fuel growth, and protect your hard work. Choose our tailored Malipo business insurance solutions for comprehensive coverage."',
                btnText: 'Explore More',
                icon: 'BusinessInsurance'
            }
        ]

        return {
            servicesInfo
        }
    }
})
