import {
    ClaimPaymentStatus,
} from "@/store/modules/last-expense/types/models";

export const useClaimPaymentsUtilities = () => {

    function computeStatusColor(paymentStatus: ClaimPaymentStatus) {

        if(paymentStatus === ClaimPaymentStatus.PENDING) {

            return "yellow";

        } else if(paymentStatus === ClaimPaymentStatus.RECEIVED) {

            return "green";
        }

        return "red";
    }

    return {

        computeStatusColor,
    };
}