

import {defineComponent, PropType} from "vue";
import TaskAlt from "@/icons/outlined/task-alt.vue";

export default defineComponent({
  components: {TaskAlt},
  props: {

    list: {

      type: Object as PropType<Record<string, string>>,
      required: true
    }
  }
});

