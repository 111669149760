import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "h-full position-relative",
  style: {"overflow":"auto"}
}
const _hoisted_2 = {
  class: "position-absolute pb-3",
  style: {"top":"0","left":"0","bottom":"0","right":"0"}
}
const _hoisted_3 = {
  class: "h-full",
  style: {"overflow-y":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_registration = _resolveComponent("request-registration")!
  const _component_validate_registration = _resolveComponent("validate-registration")!
  const _component_success_handler = _resolveComponent("success-handler")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    show: _ctx.show,
    width: 540,
    height: 600
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.currentStep === _ctx.STEPS.REQUEST_REGISTRATION)
              ? (_openBlock(), _createBlock(_component_request_registration, {
                  key: 0,
                  show: _ctx.show,
                  onClose: _ctx.close,
                  onRegistrationRequested: _ctx.handleRegistrationRequested
                }, null, 8, ["show", "onClose", "onRegistrationRequested"]))
              : (_ctx.currentStep === _ctx.STEPS.VALIDATE_REGISTRATION)
                ? (_openBlock(), _createBlock(_component_validate_registration, {
                    key: 1,
                    "otp-channel": _ctx.otpChannel,
                    email: _ctx.email,
                    onClose: _ctx.close,
                    onRegistrationValidated: _ctx.handleRegistrationValidated
                  }, null, 8, ["otp-channel", "email", "onClose", "onRegistrationValidated"]))
                : (_ctx.currentStep === _ctx.STEPS.HANDLE_SUCCESS)
                  ? (_openBlock(), _createBlock(_component_success_handler, {
                      key: 2,
                      onSignIn: _ctx.signIn
                    }, null, 8, ["onSignIn"]))
                  : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}