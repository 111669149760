export const useStringUtilities = () => {

    const ucfirst = (str: string) => {

        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const  stripUnderscores = (str: string) => {

        return str.replace(/_/g, " ")
    }

    const removeAllWhitespace = (str: string) => {

        return str.replace(/\s/g,'');
    }

    function uuidv4() {

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function(c) {

                const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }

    return {

        uuidv4,
        ucfirst,
        stripUnderscores,
        removeAllWhitespace
    };
}