import axiosInstance from "@/axios/axios";
import {AxiosResponse} from "axios";

class AllCirclesService {

    private static baseUrl = `${process.env.VUE_APP_BASE_URL}/circles/v1`;
    private static instance: AllCirclesService;
    private readonly axiosInstance = axiosInstance;

    static singleton = (): AllCirclesService => {

        if (!AllCirclesService.instance) {

            AllCirclesService.instance = new AllCirclesService();
        }

        return AllCirclesService.instance;
    };

    async fetchPagedLoggedInUserCircles(
        circleType: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/circle`,
            {

                params: {circleType, page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchCircleStatistics(circleId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/circle/${circleId}`,
        );
    }

    async fetchCircleTransactions(
        circleId: string, page: number, size: number,
        startDate: Date | null = null, endDate: Date | null = null
    ): Promise<AxiosResponse> {

        const response =  await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/wallet/transactions/${circleId}`,
            {

                params: {

                    page: page - 1,
                    size: size,
                    start: startDate,
                    end: endDate
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchCircleWalletInfo(circleId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/wallet/${circleId}`
        );
    }

    async fetchCircleWithdrawals(
        circleId: string, page: number, size: number):
        Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/withdrawal/circle/${circleId}`,
            { params: { page: page - 1, size } }
        );

        response.data.page++;

        return response;
    }

    async fetchCircleWithdrawal(circleWithdrawalId: string):
        Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/withdrawal/${circleWithdrawalId}`);
    }

    async fetchNonPagedApprovalsForWithdrawal(
        withdrawalId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedApprovalsForWithdrawal(
            withdrawalId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedApprovalsForWithdrawal(
            withdrawalId, 1, response.data.totalPages
        );
    }

    async fetchPagedApprovalsForWithdrawal(
        withdrawalId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/withdrawal-approval/withdrawal/${withdrawalId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedWithdrawalApprovalsForSpecificApprover(
        circleId: string): Promise<AxiosResponse> {

        const response =
            await this.fetchPagedWithdrawalApprovalsForSpecificApprover(
                circleId, 1, 1,
            );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedWithdrawalApprovalsForSpecificApprover(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedWithdrawalApprovalsForSpecificApprover(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/withdrawal-approval/circle/${circleId}`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async reviewWithdrawalApproval(
        withdrawalApprovalId: string, reviewApprovalStatus: "APPROVE" | "DENY")
        : Promise<AxiosResponse> {

        return await this.axiosInstance.patch(
            AllCirclesService.baseUrl +
            `/withdrawal-approval/${withdrawalApprovalId}`,
            {}, {

                params: {reviewApprovalStatus}
            }
        );
    }

    async validateWithdrawal(circleId: string, data: {

        amount: number;
        payout: {

            billingAddress: string;
            paymentMode: string;
        };
        remarks: string;

    }): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl +
            `/withdrawal/validate/${circleId}`,
            data
        );
    }

    async confirmWithdrawal(withdrawalId: string, pin: number):
        Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl +
            `/withdrawal/request/${withdrawalId}`,
            { pin }
        );
    }

    async fetchCircleLoanRequests(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/loanrequest/circle/${circleId}`,
            {

                params: {

                    page: page - 1,
                    size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchLoanInterestAccruals(
        loanId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/loan-interest-accruals/loan/${loanId}`,
            {

                params: {

                    page: page - 1,
                    size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchLoanRequest(loanRequestId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/loanrequest/${loanRequestId}`
        );
    }

    async fetchNonPagedUserLoanRequestsInSpecificCircle(
        circleId: string
    ): Promise<AxiosResponse> {

        const response = await this.fetchPagedUserLoanRequestsInSpecificCircle(
            circleId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedUserLoanRequestsInSpecificCircle(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedUserLoanRequestsInSpecificCircle(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/loanrequest/circle/${circleId}/member`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async makeChamaLoanRequest(circleId: string, data: {

        amountRequested: number;
        guarantorIds: Array<string>;
        remarks: string;
        repaymentDurationInMonths: string;

    }): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/chama/loanrequest/circle/${circleId}`,
            data
        );
    }

    async postManualLoanRequest(circleId: string, data: {

        memberId: string;
        amountRequested: number;
        guarantorIds: Array<string>;
        repaymentDurationInMonths: string;

    }): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/chama/loanrequest/manual/circle/${circleId}`,
            data
        );
    }

    async makeOpeningBalLoanRequest(circleId: string, data: {

        amountRequested: number;
        repaymentDurationInMonths: string;
        memberId: string

    }): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/chama/loanrequest/opening-balance/circle/${circleId}`,
            data
        );
    }

    async makeP2POpeningBalLoanRequest(circleId: string, data: {

        amountRequested: number;
        repaymentDurationInMonths: string;
        interestRate: number;
        remarks: string;
        recipientMemberId: string;
        offerorMemberId: string;

    }): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/p2p/loanrequest/opening-balance/circle/${circleId}`,
            data
        );
    }

    async fetchCircleLoans(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/loan/circle/${circleId}`,
            {

                params: {

                    page: page - 1,
                    size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchLoan(loanId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/loan/${loanId}`);
    }

    async fetchNonPagedLoanRepayments(loanId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedLoanRepayments(
            loanId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedLoanRepayments(
            loanId, 1, response.data.totalPages
        );
    }

    async fetchPagedLoanRepayments(
        loanId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/loanrepayment/loan/${loanId}`,

            {

                params: {

                    page: page - 1, size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async makeLoanRepayment(
        loanId: string, data: {

            payment: {

                billingAddress: string;
                paymentMode: string;
            },
            repaidAmount: number
        }
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/loanrepayment/loan/${loanId}`,
            data
        );
    }

    async postManualLoanRepayment(
        loanId: string, repaidAmount: number,
        repayerMemberId: string, dateReceived: string | null, transactionRef: string

    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/loanrepayment/manual/loan/${loanId}`,
            {
                repaidAmount, repayerMemberId, dateReceived, transactionRef
            }
        );
    }

    async fetchNonPagedCircleMembers(circleId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedCircleMembers(
            circleId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedCircleMembers(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedCircleMembers(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl +
            `/member/circle/${circleId}`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchAdminRemovalApprovalsInCircle(
        circleId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/member/adminRemovalApproval/circle/${circleId}`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async reviewMemberAdminStatus(
        subjectMemberId: string, circleId: string, adminReview: "ADD" | "REMOVE")
        : Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl +
            `/member/${subjectMemberId}/circle/${circleId}`,
            {}, {params: { adminReview: adminReview }}
        );
    }

    async fetchSupportedBanks(): Promise<AxiosResponse> {

        return await this.axiosInstance.get(AllCirclesService.baseUrl + `/bank/`);
    }

    async fetchCircleBankAccounts(circleId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/bank/circle/${circleId}`
        );
    }

    async createBankAccount(
        circleId: string, accountNumber: string, bank: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl + `/bank/circle/${circleId}`,
            { accountNumber, bank }
        );
    }

    async deleteBankAccount(
        circleId: string, bankAccountId: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.delete(
            AllCirclesService.baseUrl + `/bank/circle/${circleId}/${bankAccountId}`);
    }

    async fetchNonPagedBeneficiaries(circleId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedBeneficiaries(
            circleId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedBeneficiaries(
            circleId, 1, response.data.totalPages
        );
    }

    async fetchPagedBeneficiaries(
        circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/beneficiary/circle/${circleId}`,
            {

                params: {

                    page: page - 1,
                    size
                }
            }
        );

        response.data.page++;

        return response;
    }

    async fetchBeneficiary(beneficiaryId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllCirclesService.baseUrl + `/beneficiary/${beneficiaryId}`
        );
    }

    async createBeneficiary(
        circleId: string, createBeneficiaryDTO: {

            bankAccounts: Array<string>;
            firstName: string;
            lastName: string;
            phoneNumber: string;
        }
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            AllCirclesService.baseUrl +
            `/beneficiary/circle/${circleId}`,
            createBeneficiaryDTO
        );
    }

    async updateBeneficiary(
        beneficiaryId: string, circleId: string, updateBeneficiaryDTO: {

            bankAccounts: Array<string>;
            firstName: string;
            lastName: string;
            phoneNumber: string;
        }
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.put(
            AllCirclesService.baseUrl +
            `/beneficiary/${beneficiaryId}/circle/${circleId}`,
            updateBeneficiaryDTO
        );
    }

    async deleteBeneficiary(
        beneficiaryId: string, circleId: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.delete(
            AllCirclesService.baseUrl +
            `/beneficiary/${beneficiaryId}/circle/${circleId}`,
        );
    }
}

export default AllCirclesService;
