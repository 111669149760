import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "tw-mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_policy_pending_submission = _resolveComponent("policy-pending-submission")!
  const _component_policy_pending_premium_payment = _resolveComponent("policy-pending-premium-payment")!
  const _component_policy_active = _resolveComponent("policy-active")!

  return (_ctx.isPendingSubmission)
    ? (_openBlock(), _createBlock(_component_policy_pending_submission, {
        key: 0,
        policy: _ctx.policy
      }, null, 8, ["policy"]))
    : (_ctx.isPendingPremiumPayment)
      ? (_openBlock(), _createBlock(_component_policy_pending_premium_payment, {
          key: 1,
          premium: _ctx.premium,
          policy: _ctx.policy,
          "total-paid": _ctx.totalPaid
        }, null, 8, ["premium", "policy", "total-paid"]))
      : (_ctx.isActive)
        ? (_openBlock(), _createBlock(_component_policy_active, {
            key: 2,
            policy: _ctx.policy,
            payments: _ctx.payments
          }, null, 8, ["policy", "payments"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-sm tw-text-center tw-px-4 tw-py-3 tw-text-white", ['tw-bg-' + _ctx.policyStatusInfo.statusColor + '-600']]),
              style: {"width":"170px"}
            }, _toDisplayString(_ctx.policyStatusInfo.formattedStatus), 3)
          ]))
}