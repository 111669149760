

import {computed, defineComponent, PropType} from "vue";
import {LastExpenseClaim} from "@/store/modules/last-expense/types/models";
import {useCurrencyUtilities} from "@/hooks/useCurrencyUtilities";
import {useDateUtilities} from "@/hooks/useDateUtilities";
import IconContainer from "@/views/components/IconContainer.vue";
import Event from "@/icons/solid/event.vue";
import Payments from "@/icons/solid/payments.vue";
import MemberAvatar from "@/views/pages/insurances/shared/member-elements/MemberAvatar.vue";

export default defineComponent({
  components: {MemberAvatar, Payments, Event, IconContainer},
  props: {

    claim: {

      type: Object as PropType<LastExpenseClaim>,
      required: true
    }
  },

  setup(props) {

    const { formatCurrency } = useCurrencyUtilities();
    const { formatToHumanFriendlyDate, dateToTimeInTwelveHourFormat} = useDateUtilities();

    const formattedClaim = computed(() => {

      const claim = props.claim;
      const initiator = claim.initiator;

      return {

        initiatorName: initiator ? initiator.fullName : 'Initiator name',
        initiatorPhoneNumber: initiator ? initiator.phoneNumber : '07xx xxx xxx',
        initiatorUrl: initiator ? initiator.avatarURL : '',
        initiatorType: claim.initiatorUserId === claim.lastExpensePolicy.memberId ?
            'Principal' : 'Circle admin',
        claimAmount: formatCurrency(claim.lastExpensePolicy.lastExpensePlan.cover),
        createdOn: formatToHumanFriendlyDate(claim.createdOn) + ', ' +
            dateToTimeInTwelveHourFormat(claim.createdOn),
      }

    });

    return {

      formattedClaim
    }
  }
});

