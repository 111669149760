

import {computed, defineComponent, nextTick, PropType, ref} from "vue";
import {
  LastExpensePolicy,
  LastExpensePolicyStatus,
} from "@/store/modules/last-expense/types/models";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import Plus from "@/icons/solid/plus.vue";
import PolicyMembersTable from "@/views/pages/insurances/last-expense/policy/policy-members/PolicyMembersTable.vue";
import NewMemberModal
    from "@/views/pages/insurances/last-expense/policy/policy-members/new-member-modal/NewMemberModal.vue";
import PolicyMemberModal
    from "@/views/pages/insurances/last-expense/policy/policy-members/policy-member-modal/PolicyMemberModal.vue";
import MemberRequirements from "@/views/pages/insurances/last-expense/policy/policy-members/MemberRequirements.vue";

export default defineComponent({
  components: {MemberRequirements, PolicyMemberModal, NewMemberModal, PolicyMembersTable, Plus, Spinner,},
  props: {

    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },

  setup(props) {

    const store = useStore();
    const showNewMemberModal = ref(false);
    const showViewMemberModal = ref(false);
    const showRequirementsModal = ref(false);
    const memberIdToView = ref("");

    const showNewMemberButton = computed(() => {

      return props.policy.policyStatus === LastExpensePolicyStatus.PENDING;
    })

    const beneficiaries = computed(() => {

      return store.getters.getPolicyBeneficiaries(props.policy.lastExpensePolicyId);
    });

    const memberToView = computed(() => {

      const policy = props.policy;

      if(! memberIdToView.value) {

        return policy.owner;
      }

      if(memberIdToView.value === policy.owner.lastExpensePolicyOwnerId) {

        return policy.owner;
      }

      const policyBeneficiaries = beneficiaries.value;

      if(! policyBeneficiaries) {

        return policy.owner;
      }

      const matchingBeneficiary = policyBeneficiaries.find(beneficiary =>
        beneficiary.lastExpenseBeneficiaryId === memberIdToView.value
      )

      return matchingBeneficiary ? matchingBeneficiary : policy.owner;
    });

    function toggleRequirementsModal(show: boolean) {

      showRequirementsModal.value = show;
    }

    function toggleViewMemberModal(
        show: boolean, memberId = ""
    ) {

      if(memberId) {

        memberIdToView.value = memberId
      }

      nextTick(() => {

        showViewMemberModal.value = show;
      });
    }

    function toggleNewMemberModal(show: boolean) {

      showNewMemberModal.value = show;
    }

    function fetchBeneficiaries() {

      store.dispatch(ActionTypes.FETCH_POLICY_BENEFICIARIES, {

        policyId: props.policy.lastExpensePolicyId,
      });
    }

    function initialize() {

      if(! beneficiaries.value) {

        fetchBeneficiaries();
      }
    }

    initialize();

    return {

      memberToView,
      beneficiaries,
      showNewMemberModal,
      showNewMemberButton,
      showViewMemberModal,
      toggleNewMemberModal,
      toggleViewMemberModal,
      showRequirementsModal,
      toggleRequirementsModal,
    }
  }
});

