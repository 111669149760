import {MutationTree} from "vuex";
import {State} from "@/store/modules/circles/types/state";
import {Mutations} from "@/store/modules/circles/types/mutations";
import {useObjectUtilities} from "@/hooks/useObjectUtilities";
import {state as initialState} from "@/store/modules/circles/state";
import {storeUtilities} from "@/store/storeUtilities";

const { updateOrCreateRecord } = useObjectUtilities();

const { refreshState } = storeUtilities();

export const mutations: MutationTree<State> & Mutations = {

    RESET_ALL_CIRCLES_STATE: (state, payload = {}) => {

        const keysNotToReset = payload.keysNotToReset ? payload.keysNotToReset : [];

        refreshState(state, initialState(), keysNotToReset);
    },

    SET_DATA_FOR_USER_CIRCLES_VIEW: (state, payload) => {

        payload.data.items.forEach(item => {

            if(! item.circle) { return; }

            const circleType = item.circle.circleType;

            updateOrCreateRecord(
                item, state.userCircles[circleType],
                (recordA, recordB) =>
                    recordA.circleId === recordB.circleId
            )
        });

        const record = Object.assign(structuredClone(payload.data), {
            items: payload.data.items.map(item => item.circleId)
        });

        updateOrCreateRecord(
            record, state.dataForUserCirclesView,
            (viewDataA, viewDataB) =>
                viewDataA.filters.type === viewDataB.filters.type
        );
    },

    CLEAR_DATA_FOR_USER_CIRCLES_VIEW: (state, payload) => {

        for(let i = state.dataForUserCirclesView.length - 1; i >= 0; i--) {

            if(state.dataForUserCirclesView[i].filters.type ===
                payload.filters.type) {

                state.dataForUserCirclesView.splice(i, 1);
            }
        }
    },

    SET_USER_CIRCLE: (state, payload) => {

        if(! payload.data.circle) { return; }

        const circleType = payload.data.circle.circleType;

        updateOrCreateRecord(
            payload.data, state.userCircles[circleType],
            (recordA, recordB) =>
                recordA.circleId === recordB.circleId
        );
    },

    REMOVE_USER_CIRCLE: (state, payload) => {

        Object.values(state.userCircles).forEach(memberCircles => {

           for(let i = 0; i < memberCircles.length; i++) {

               if(memberCircles[i].circleId === payload.memberCircle.circleId) {

                   memberCircles.splice(i, 1);
                   break;
               }
           }
        });
    },

    SET_CIRCLE_MEMBERS_COUNT: (state, payload) => {

        state.circleMembersCount[payload.circleId] = payload.count;
    },

    SET_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW: (state, payload) => {

        payload.data.items.forEach(item => {

            updateOrCreateRecord(
                item, state.transactions,
                (recordA, recordB) =>
                    recordA.transactionRef === recordB.transactionRef
            )
        });

        const record = Object.assign(payload.data, {
            items: payload.data.items.map(item => item.transactionRef)
        });

        updateOrCreateRecord(
            record, state.dataForTransactionsView,
            (viewDataA, viewDataB) =>
                viewDataA.filters.circleId === viewDataB.filters.circleId
        );
    },

    CLEAR_DATA_FOR_CIRCLE_TRANSACTIONS_VIEW: (state, payload) => {

        for(let i = state.dataForTransactionsView.length - 1; i >= 0; i--) {

            if(state.dataForTransactionsView[i].filters.circleId ===
                payload.filters.circleId) {

                state.dataForTransactionsView.splice(i, 1);
            }
        }
    },

    SET_CIRCLE_MEMBERS: (state, payload) => {

        if(state.circleIdsForFetchedMembers.indexOf(payload.circleId) === -1) {

            state.circleIdsForFetchedMembers.push(payload.circleId);
        }

        payload.data.forEach(memberCircle => {

            const matchingMemberCircle =
                state.members.find(targetMemberCircle => {

                    return targetMemberCircle.memberId ===
                        memberCircle.memberId;
                });

            if(matchingMemberCircle) {

                Object.assign(matchingMemberCircle, memberCircle);

            } else {

                state.members.push(
                    Object.assign({}, memberCircle)
                );
            }
        });
    },

    CLEAR_CIRCLE_MEMBERS: (state, payload) => {

        for(let i = 0; i < state.circleIdsForFetchedMembers.length; i++) {

            if(state.circleIdsForFetchedMembers[i] === payload.circleId) {

                state.circleIdsForFetchedMembers.splice(i, 1);
                break;
            }
        }

    },

    SET_CIRCLE_MEMBER: (state, payload) => {

        const matchingMemberCircle =
            state.members.find(targetMemberCircle => {

                return targetMemberCircle.memberId === payload.memberCircle.memberId;
            });

        if(matchingMemberCircle) {

            const memberDetails = payload.memberCircle.memberDetails ?
                payload.memberCircle.memberDetails : matchingMemberCircle.memberDetails;

            Object.assign(matchingMemberCircle, payload.memberCircle, {

                memberDetails
            });
        }
    },

    SET_BENEFICIARY: (state, payload) => {

        updateOrCreateRecord(
            payload.beneficiary,
            state.beneficiaries,
            (recordA, recordB) =>
                recordA.circleId === recordB.circleId
        );
    },

    SET_BENEFICIARIES: (state, payload) => {

        if(state.circleIdsForFetchedBeneficiaries.indexOf(payload.circleId) === -1) {

            state.circleIdsForFetchedBeneficiaries.push(payload.circleId);
        }

        state.beneficiaries = payload.beneficiaries;
    },

    CLEAR_BENEFICIARIES: (state, payload) => {

        for(let i = 0; i < state.circleIdsForFetchedBeneficiaries.length; i++) {

            if(state.circleIdsForFetchedBeneficiaries[i] === payload.circleId) {

                state.circleIdsForFetchedBeneficiaries.splice(i, 1);
                break;
            }
        }
    }
}
