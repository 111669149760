

import {computed, defineComponent, PropType} from "vue";
import {
  LastExpenseBeneficiary,
  LastExpensePolicy,
  LastExpensePolicyOwner,
  LastExpensePolicyStatus
} from "@/store/modules/last-expense/types/models";
import ModalHeader from "@/views/components/ModalHeader.vue";
import PolicyMemberDetails
    from "@/views/pages/insurances/last-expense/policy/policy-members/policy-member-modal/PolicyMemberDetails.vue";

export default defineComponent({
  components: {ModalHeader, PolicyMemberDetails},

  props: {

    member: {

      type: Object as PropType<LastExpensePolicyOwner | LastExpenseBeneficiary>,
      required: true
    },
    policy: {

      type: Object as PropType<LastExpensePolicy>,
      required: true
    }
  },

  setup(props, {emit}) {

    const isOwner = computed(() => {

      return 'lastExpensePolicyOwnerId' in props.member;
    });

    const memberId = computed(() => {

      if(isOwner.value) {

        const policyOwner = props.member as LastExpensePolicyOwner;
        return policyOwner.lastExpensePolicyOwnerId;
      }

      const beneficiary = props.member as LastExpenseBeneficiary;
      return beneficiary.lastExpenseBeneficiaryId;
    });

    const canManageMember = computed(() => {

      return props.policy.policyStatus === LastExpensePolicyStatus.PENDING;
    });

    function selectAction(value: "edit" | "remove") {

      emit(value);
    }

    function close() {

      emit('close');
    }

    return {

      close,
      isOwner,
      memberId,
      selectAction,
      canManageMember
    };
  }
});

