import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import emitter from "@/event-bus/eventBus";
import ClickOutsideDirective from "@/hooks/directives/clickOutside";
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/app.css';
import "@/assets/css/custom.css";
import  "./index.css";


const app = createApp(App)

app.config.globalProperties.emitter = emitter;
app.use(store)
    .use(router)
    .directive("click-outside", ClickOutsideDirective)
    .mount('#app')
