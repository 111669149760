

import {computed, defineComponent, PropType} from "vue";

export default defineComponent({

  props: {

    bgColor: {

      type: String as PropType<string>,
      default: '#AEB4BE'
    },

    bgColorClass: {

      type: String as PropType<string>,
      default: ''
    },

    containerSize: {

      type: Number as PropType<number>,
      default: 48
    },

    borderRadius: {

      type: [Number, null] as PropType<number | null>,
      default: null
    }
  },

  setup(props) {

    const containerClasses = computed(() => {

      const classes = [];

      if(props.borderRadius === null) {

        classes.push('rounded-circle');
      }

      if(props.bgColorClass) {

        classes.push(props.bgColorClass);
      }

      return classes;
    });

    const containerStyles = computed(() => {

      const styles: Record<string, string> =  {
        width: props.containerSize + 'px',
        height: props.containerSize + 'px',
      }

      if(props.borderRadius) {

        styles['borderRadius'] = props.borderRadius + 'px';
      }

      if(! props.bgColorClass) {

        styles['backgroundColor'] = props.bgColor;
      }

      return styles;
    });

    return {

      containerStyles,
      containerClasses,
    }
  }
});

