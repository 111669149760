import {CommitOptions, createStore, DispatchOptions, Store as VuexStore} from "vuex";
import authModule from "@/store/modules/authorization";
import usersModule from "@/store/modules/users";
import circlesModule from "@/store/modules/circles";
import experiencesModule from "@/store/modules/last-expense";
import {State as AuthState} from "@/store/modules/authorization/types";
import {State as UsersState} from "@/store/modules/users/types";
import {State as CirclesState} from "@/store/modules/circles/types/state";
import {State as ExperiencesState} from "@/store/modules/last-expense/types/state";
import {Mutations} from "@/store/types/mutations";
import {Actions} from "@/store/types/actions";
import {Getters} from "@/store/types/getters";
import {axiosSetupVuexPlugin} from "@/axios/axios";

export type RootState = {

  authModule: AuthState;
  usersModule: UsersState;
  circlesModule: CirclesState;
  experiencesModule: ExperiencesState;
}

const store = createStore<RootState>({

  modules: {

    authModule,
    usersModule,
    circlesModule,
    experiencesModule,
  },
  plugins: [ axiosSetupVuexPlugin ]
});

export default store;

export type Store = Omit<VuexStore<RootState>, "getters" | "commit" | "dispatch"> & {

  getters: {

      [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  commit: <K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
      key: K,
      payload?: P,
      options?: CommitOptions
  ) => ReturnType<Mutations[K]>;
} & {
  dispatch: <K extends keyof Actions>(
      key: K,
      payload?: Parameters<Actions[K]>[1],
      options?: DispatchOptions
  ) => ReturnType<Actions[K]>
};
