import {Organisation} from "@/store/types/baseModels";

export enum ExperienceStatus {

    ACTIVE = "ACTIVE", INACTIVE = "INACTIVE"
}

export enum ExperienceType {

    LAST_EXPENSE = "LAST_EXPENSE", EVENT = "EVENT"
}

export enum InsuranceType {

    LAST_EXPENSE = "LAST_EXPENSE" , MEDICAL = "MEDICAL" , VEHICLE = "VEHICLE", HOME = "HOME", BUSINESS = "BUSINESS"
}

export type Experience = {

    createdOn: Date;
    updatedOn: Date;
    experienceId: string;
    merchantId: string;
    accountId: string;
    creatorUserId: string;
    title: string;
    avatarUrl: string;
    subTitle: string;
    description: string;
    experienceStatus: ExperienceStatus;
    experienceType: ExperienceType;
}

export type PrivateExperience = {
    createdOn: Date;
    updatedOn: Date;
    experience: Experience;
    organisation: Organisation;
    organisationId: string;
}

