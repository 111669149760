

import {defineComponent, PropType, ref, watch} from "vue";

export default defineComponent({

  props: {

    dropdownMarginTop: {

      type: Number as PropType<number>,
      default: 3,
    },

    zIndex: {

      type: Number as PropType<number>,
      default: 10000,
    },

    closeDrop: {

      type: Boolean as PropType<boolean>,
      default: false
    }

  },

  setup(props) {

    const isOpen = ref(false);

    function toggleDropdown() {

      isOpen.value = ! isOpen.value;
    }

    function closeDropdown() {

      isOpen.value = false;
    }

    watch(()=> props.closeDrop , (newValue) => {

      if(newValue){
        closeDropdown();
      }
    })

    return {

      isOpen,
      closeDropdown,
      toggleDropdown
    }
  }
});

