
import {computed, defineComponent, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import ClaimHeader from "@/views/pages/insurances/last-expense/claim/ClaimHeader.vue";
import ClaimPayments from "@/views/pages/insurances/last-expense/claim/claim-payments/ClaimPayments.vue";
import ClaimPolicy from "@/views/pages/insurances/last-expense/claim/ClaimPolicy.vue";
import PoliciesHeader from "@/views/pages/insurances/last-expense/policies/PoliciesHeader.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
  components: {
    CustomNavbar,
    PoliciesHeader,
    ClaimPolicy,
    ClaimPayments,
    ClaimHeader,
  },
  setup() {

    const store = useStore();
    const route = useRoute();
    const claimId = route.params.claimId as string;
    const claimsRoute = {name: 'last-expense-claims'};
    const isFetchingClaim = ref(true);

    const claim = computed(() => {

      return store.getters.getClaim(claimId);
    });

    const validatedClaim = computed(() => {

      const existingClaim = claim.value;

      return existingClaim && existingClaim.initiator ?
          existingClaim : null;
    });

    const claimPolicyId = computed(() => {

      return validatedClaim.value?.lastExpensePolicy.lastExpensePolicyId;
    });

    const policyPayments = computed(() => {

      return store.getters.getPolicyPayments(claimId);
    });

    const pageIsReady = computed(() => {

      return validatedClaim.value;
    });

    function fetchClaim() {

      store.dispatch(ActionTypes.FETCH_CLAIM, {

        claimId: claimId,
        successCallback: () => {

          isFetchingClaim.value = false;
        }
      });
    }

    function initialize() {

      if(! validatedClaim.value) {

        fetchClaim();
      }
    }

    initialize();

    return {

      pageIsReady,
      claimsRoute,
      claimPolicyId,
      policyPayments,
      validatedClaim,
    };
  }
});
