

import {defineComponent, PropType} from "vue";
import Check from "@/icons/solid/check.vue";

export default defineComponent({
  components: {Check},

  props: {

    checked: {

      type: Boolean as PropType<boolean>,
      required: true
    },

    size: {

      type: Number,
      default: 24
    }
  },
});

