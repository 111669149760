import axiosInstance from "@/axios/axios";
import axios, {AxiosResponse} from "axios";

class LastExpenseExperience {

    private static baseUrl = `${process.env.VUE_APP_BASE_URL}/experiences/v1/last-expense`;
    private static instance: LastExpenseExperience;
    private readonly axiosInstance = axiosInstance;

    static singleton = (): LastExpenseExperience => {

        if (!LastExpenseExperience.instance) {

            LastExpenseExperience.instance = new LastExpenseExperience();
        }

        return LastExpenseExperience.instance;
    };

    async fetchCirclePolicies(
        circleId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl + `/policy/circle/${circleId}`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchUserPolicies(
        page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl + `/policy/member`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchPolicy(policyId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            LastExpenseExperience.baseUrl + `/policy/${policyId}`
        );
    }

    async fetchNonPagedPolicyBeneficiaries(
        policyId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedPolicyBeneficiaries(
            policyId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedPolicyBeneficiaries(
            policyId, 1, response.data.totalPages
        );
    }

    async fetchPagedPolicyBeneficiaries(
        policyId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl +
            `/beneficiary/policy/${policyId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async createPolicyBeneficiary(
        policyId: string, data: {

            nationalId?: string;
            fullName: string;
            beneficiaryType: string;

        }): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            LastExpenseExperience.baseUrl + `/beneficiary/policy/${policyId}`, data
        );
    }

    async updatePolicyBeneficiary(
        beneficiaryId: string, data: {

            nationalId?: string;
            fullName: string;
            beneficiaryType: string;

        }): Promise<AxiosResponse> {

        return await this.axiosInstance.put(
            LastExpenseExperience.baseUrl +
            `/beneficiary/${beneficiaryId}`, data
        );
    }

    async updatePolicyOwner(
        ownerId: string, data: {


            fullName: string;
            phoneNumber: string;
            email: string;
            nationalId: string;
            kraPin: string;

        }): Promise<AxiosResponse> {

        return await this.axiosInstance.put(
            LastExpenseExperience.baseUrl +
            `/policy-owner/${ownerId}`, data
        );
    }

    async deletePolicyBeneficiary(
        beneficiaryId: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.delete(
            LastExpenseExperience.baseUrl + `/beneficiary/${beneficiaryId}`
        );
    }

    async submitPolicyForEvaluation(
        policyId: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.patch(
            LastExpenseExperience.baseUrl + `/policy/submit/${policyId}`
        );
    }

    async cancelPolicy(
        policyId: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.patch(
            LastExpenseExperience.baseUrl + `/policy/cancel/${policyId}`
        );
    }

    async fetchNonPagedPolicyPayments(
        policyId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedPolicyPayments(
            policyId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedPolicyPayments(
            policyId, 1, response.data.totalPages
        );
    }

    async fetchPagedPolicyPayments(
        policyId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl +
            `/payment/policy/${policyId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async makePolicyPayment(
        policyId: string, data: {
            paymentMode: string;
            billingAddress: string
        }
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            LastExpenseExperience.baseUrl +
            `/payment/${policyId}`, {payment: data}
        );
    }

    async fetchNonPagedActiveExperiencePlans(
        experienceId: string
    ): Promise<AxiosResponse> {

        const response = await this.fetchPagedActiveExperiencePlans(
            experienceId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedActiveExperiencePlans(
            experienceId, 1, response.data.totalPages
        );
    }

    async fetchPagedActiveExperiencePlans(
        experienceId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl +
            `/plan/experience/${experienceId}/active-plans`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedActivePlansWithoutAuth(experieneId:string, clientAccessToken: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedActivePlansWithoutAuth(1, 1, experieneId,  clientAccessToken );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedActivePlansWithoutAuth(
            1, response.data.totalPages, experieneId, clientAccessToken
        );
    }

    async fetchPagedActivePlansWithoutAuth(page: number, size: number, experienceId:string, clientAccessToken: string ): Promise<AxiosResponse> {


        const response =  await axios.get(
            LastExpenseExperience.baseUrl + `/plan/experience/${experienceId}/active-plans`,
            {
                params: {
                    page: page - 1, size
                },
                headers: {Authorization: `Bearer ${clientAccessToken}`}
            });

        response.data.page++;

        return response;
    }

    async registerIndividualPolicy(
        planId: string
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            LastExpenseExperience.baseUrl + `/policy/plan/${planId}`
        );
    }

    async registerCirclePolicies(
        circleId: string, data: { planId: string; memberIds: Array<string> }
    ): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            LastExpenseExperience.baseUrl + `/policy/circle/${circleId}`, data
        );
    }

    async fetchUserClaims(
        page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl + `/claim/member`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchCircleClaims(
        circleId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl + `/claim/circle/${circleId}`,
            {params: {page: page - 1, size}}
        );

        response.data.page++;

        return response;
    }

    async fetchClaim(claimId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            LastExpenseExperience.baseUrl + `/claim/${claimId}`
        );
    }

    async fetchNonPagedClaimPayments(
        claimId: string
    ): Promise<AxiosResponse> {

        const response = await this.fetchPagedClaimPayments(
            claimId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedClaimPayments(
            claimId, 1, response.data.totalPages
        );
    }

    async fetchPagedClaimPayments(
        claimId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl +
            `/claim/payment/claim/${claimId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedPolicyClaims(
        claimId: string
    ): Promise<AxiosResponse> {

        const response = await this.fetchPagedPolicyClaims(
            claimId, 1, 1,
        );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedPolicyClaims(
            claimId, 1, response.data.totalPages
        );
    }

    async fetchPagedPolicyClaims(
        policyId: string, page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            LastExpenseExperience.baseUrl +
            `/claim/policy/${policyId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async createPolicyClaim(policyId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.post(
            LastExpenseExperience.baseUrl + `/claim/policy/${policyId}`
        );
    }

    async policyPaymentLink(policyId: string, data: {
        phoneNumber: string;
        description: string;
        checkoutItems: Array<{
            name: string;
            unitPrice: number;
            quantity: number;
            description: string;
        }>
    }): Promise<AxiosResponse>  {

        return await this.axiosInstance.post(
            LastExpenseExperience.baseUrl +
            `/payment/policy/${policyId}/payment-request`, data);
    }
}

export default LastExpenseExperience;