

import {defineComponent, PropType} from "vue";

export default defineComponent({

  props: {

    primaryColor: {

      type: String as PropType<string>,
      default: "#FFFFFF"
    }
  }
});

