

import {computed, defineComponent, ref} from "vue";
import {useStore} from "@/hooks/useStore";
import {useRoute, useRouter} from "vue-router";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import {LastExpensePolicy, LastExpensePolicyType} from "@/store/modules/last-expense/types/models";
import {MutationTypes} from "@/store/modules/last-expense/types/mutations";
import usePopstateNavigation from "@/hooks/usePopstateNavigation";
import BackButton from "@/views/components/BackButton.vue";
import Plus from "@/icons/solid/plus.vue";
import PoliciesTable from "@/views/pages/insurances/last-expense/policies/PoliciesTable.vue";
import PolicyCard from "@/views/pages/insurances/last-expense/policies/PolicyCard.vue";
import PoliciesHeader from "@/views/pages/insurances/last-expense/policies/PoliciesHeader.vue";
import LoadMore from "@/views/components/LoadMore.vue";
import CustomNavbar from "@/views/components/layout/navbar/CustomNavbar.vue";

export default defineComponent({
  components: {
    CustomNavbar,
    LoadMore,
    PoliciesHeader,
    PolicyCard,
    PoliciesTable,
    Plus,
    BackButton,
    Spinner
  },
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { initialize } = usePopstateNavigation();
    const dashboardId = route.params.dashboardId as string;
    const experienceId = route.params.experienceId as string;
    const USER_DASHBOARD_ID = "user";
    const isUserDashboard = dashboardId === USER_DASHBOARD_ID;
    const circleId = isUserDashboard ? "" : dashboardId;
    const isFetchingPolicies = ref(false);
    const policyType = computed (() => {

      return dashboardId === 'user'? LastExpensePolicyType.INDIVIDUAL : LastExpensePolicyType.CIRCLE
    })
    const lastExpenseView = {

      name: 'last-expense-view'
    };

    const paginatedPolicies = computed(() => {

      return store.getters.getDataForLastExpensePoliciesView({

        circleId: circleId
      });
    });

    const policies = computed(() => {

      const data = paginatedPolicies.value;

      if(data) {

        return data.items.filter(policy => {

          return (experienceId === policy.lastExpensePlan.experience.experienceId)
        })
      }

      return [] as Array<LastExpensePolicy>;
    });

    const hasFetchedPolicies = computed(() => {

      return !! paginatedPolicies.value;
    });

    const canLoadMore = computed(() => {

      const data = paginatedPolicies.value;

      if(! data) { return false; }

      return data.nextPage <= data.totalPages;
    });

    function addNewPolicy() {

      router.push({name: 'last-expense-new-policy'});
    }

    function fetchPolicies() {

      isFetchingPolicies.value = true;
      store.dispatch(ActionTypes.FETCH_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW, {

        filters: { circleId: circleId},
        successCallback: () => {

          isFetchingPolicies.value = false;
        },
        errorCallback: () => {
          isFetchingPolicies.value = false;
        }
      });
    }

    function clearData() {

      store.commit(MutationTypes.CLEAR_DATA_FOR_LAST_EXPENSE_POLICIES_VIEW, {

        filters: { circleId: circleId},
      });
    }

    initialize((isPopstateNavigation) => {

      if(isPopstateNavigation && paginatedPolicies.value?.items.length) {

        return;
      }

      isFetchingPolicies.value = true;
      clearData();
      fetchPolicies();
    });

    return {

      circleId,
      policies,
      policyType,
      canLoadMore,
      addNewPolicy,
      experienceId,
      fetchPolicies,
      lastExpenseView,
      isUserDashboard,
      isFetchingPolicies,
      hasFetchedPolicies
    };
  }
});

