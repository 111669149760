export const useDateUtilities = () => {

    function parseLocalISODateTime(ISODateTime: string) {

        const b = ISODateTime.split(/\D/)
            .map(part => parseInt(part, 10));

        return new Date(b[0],b[1]-1, b[2], b[3], b[4], b[5]);
    }

    function parseLocalISODate(ISODate: string) {

        return parseLocalISODateTime(ISODate + 'T00:00:00');
    }

    function parseISODateWithKnownOffsetToLocal(ISODateTime: string, offsetInMinutes: number) {

        const localOffsetInMinutes = -1 * new Date().getTimezoneOffset();

        const MS_PER_MINUTE = 60000;

        const date = parseLocalISODateTime(ISODateTime);

        return new Date(date.getTime() -
            (offsetInMinutes - localOffsetInMinutes) * MS_PER_MINUTE);
    }

    function parseISODateWithoutOffsetToLocal(ISODateTime: string ) {

        return parseLocalISODateTime(ISODateTime);
    }

    function parseUTCISODateTimeToLocal(ISODateTime: string) {

        return parseISODateWithKnownOffsetToLocal(ISODateTime,0);
    }

    function parseServerDateTime(dateTime: string) {

        return parseUTCISODateTimeToLocal(dateTime);
    }

    function parseServerWithNormalDateTime(dateTime: string) {

        return parseISODateWithoutOffsetToLocal(dateTime);
    }

    function stripTimezoneFromISODateTime(ISODateTime: string) {

        return ISODateTime.slice(0, 19);
    }

    function formatToLocalISODate(d: Date) {

        return d.getFullYear() + '-' +
            ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
            ('0' + d.getDate()).slice(-2);
    }

    function formatToLocalISODateTime(d: Date) {

        const z = -1 * d.getTimezoneOffset() * 60 * 1000;

        const tLocal = new Date(d.getTime() + z);

        return stripTimezoneFromISODateTime(tLocal.toISOString());
    }

    function formatToISODateTimeWithKnownOffset(d: Date, offsetInMinutes: number) {

        const localOffsetInMinutes = -1 * new Date().getTimezoneOffset();

        const MS_PER_MINUTE = 60000;

        const offsetDate = new Date(d.getTime() -
            (localOffsetInMinutes - offsetInMinutes) * MS_PER_MINUTE);

        return formatToLocalISODateTime(offsetDate);
    }

    function formatToUTCISODateTime(d: Date) {

        return formatToISODateTimeWithKnownOffset(d, 0);
    }

    function formatToServerDateTimeString(d: Date) {

        return formatToUTCISODateTime(d);
    }

    function getPreviousMonday(date: Date) {

        const d = new Date(date);

        const day = d.getDay();
        const diff = d.getDate() - day + (day == 0 ? -6 : 1);

        return new Date(d.setDate(diff));
    }

    function setToStartOfDay(d: Date) {

        const newDate = new Date(d);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    function setToEndOfDay(d: Date) {

        const newDate = new Date(d);
        newDate.setHours(23, 59, 59, 999);
        return newDate;
    }

    function getDaysInMonth (month: number, year: number) {

        return new Date(year, month, 0).getDate();
    }

    function isCurrentYear(year: number) {

        return year === (new Date()).getFullYear();
    }

    function isCurrentMonth(month: number, year: number) {

        return month === ((new Date()).getMonth() + 1) && isCurrentYear(year);
    }

    function generateYearsToPresent(startYear: number) {

        const currentYear = new Date().getFullYear();
        const years = [];

        while(startYear <= currentYear) {

            years.push(startYear);

            startYear++;
        }

        return years;
    }

    const formatToHumanFriendlyDate =
        (date: Date, commaBeforeYear = false,
         month: 'short' | 'long' = 'short', monthFirst = false,
         showYearIfOnlyNotCurrentYear = false) => {

        const days = date.getDate();

        const dayString = (days < 10 ? '0' + days : days);
        const monthString = date.toLocaleString('default', { month });
        let yearString =  (commaBeforeYear ? ', ' : ' ') + date.getFullYear();

        if(showYearIfOnlyNotCurrentYear && isCurrentYear(date.getFullYear())) {

            yearString = '';
        }

        if(monthFirst) {

            return monthString + ' ' + dayString + yearString;

        } else {

            return dayString  + ' ' + monthString + yearString;
        }
    }

    const formatToRelativeDate = (date: Date, showCurrentYear = true) => {

        const diff = Math.abs((new Date()).getTime() - date.getTime());

        const seconds = diff/1000;

        const minutes = seconds/60;

        const hours = minutes/60;

        if(seconds < 60) {

            if(seconds < 5) {

                return 'Now';

            } else {

                return Math.round(seconds) + ' seconds ago';
            }

        } else if(minutes < 60) {

            const roundedMinutes = Math.round(minutes);

            return roundedMinutes + ' minute' +
                (roundedMinutes > 1 ? 's' : '') +' ago';

        } else if(hours < 24) {

            const roundedHours = Math.round(hours);

            return roundedHours + ' hour' +
                (roundedHours > 1 ? 's' : '') + ' ago';

        } else if(hours < 48) {

            return 'Yesterday';

        } else {

            return formatToHumanFriendlyDate(
                date, true, 'short',
                true, !showCurrentYear
            );
        }
    }

    const dateToTimeInTwentyFourHourFormat = (date: Date) => {

        const hours = date.getHours();
        const mins = date.getMinutes();

        return (hours < 10 ? '0' + hours : hours) + ":" +
            (mins < 10 ? '0' + mins : mins);
    }

    const dateToTimeInTwelveHourFormat = (date: Date) => {

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return hours + ':' + (minutes < 10 ? '0'+ minutes : minutes) + ' ' + ampm;
    }

    const getTomorrowsDate = () => {

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        return tomorrow;
    }

    const isValidISODate = (dateString: string) => {
        // Validates that the input string is a valid date formatted as "yyyy-mm-dd"

        if(!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
            return false;
        }

        const parts = dateString.split("-");

        const day = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[0], 10);

        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        return day > 0 && day <= monthLength[month - 1];
    }

    const months = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December" ];

    return {

        months,
        setToEndOfDay,
        isCurrentYear,
        isValidISODate,
        isCurrentMonth,
        getDaysInMonth,
        setToStartOfDay,
        getTomorrowsDate,
        getPreviousMonday,
        parseLocalISODate,
        parseServerDateTime,
        formatToRelativeDate,
        formatToLocalISODate,
        parseLocalISODateTime,
        generateYearsToPresent,
        formatToLocalISODateTime,
        formatToHumanFriendlyDate,
        dateToTimeInTwelveHourFormat,
        formatToServerDateTimeString,
        stripTimezoneFromISODateTime,
        parseServerWithNormalDateTime,
        dateToTimeInTwentyFourHourFormat
    };
}