
import {computed, defineComponent, PropType, ref} from "vue";
import InsuranceChamaCard from "@/views/components/insurance-elements/InsuranceChamaCard.vue"
import Plus from "@/icons/solid/plus.vue"
import Spinner from "@/views/components/Spinner.vue"
import {Circle, CircleType} from "@/store/modules/circles/types/models";
import {ActionTypes} from "@/store/modules/circles/types/actions";
import {useStore} from "@/hooks/useStore";
export default defineComponent({
  components: {InsuranceChamaCard, Plus, Spinner},
  props: {

    title: {
      type: String as PropType<string>,
      default: 'Select a Group (chama)'
    }

  },
  setup(props, {emit}){

      const store = useStore();
      const selectedGroupId = ref("")
      const filters = ref({

          type: CircleType.CHAMA
      });

      const paginatedGroups = computed(() => {

          return store.getters.getDataForUserCirclesView(filters.value);
      });

      const hasFetchedGroups = computed(() => {

          return !!paginatedGroups.value;
      });

      const canLoadMore = computed(() => {

          const data = paginatedGroups.value;

          if(! data) { return false; }

          return data.nextPage <= data.totalPages;
      });

      const groups = computed(() => {

          const groups = paginatedGroups.value?.items;

          if(! groups) {

              return [];
          }

          const groupsData: Array<{
              title: string,
              subtitle: string,
              id: string}> = [];

          groups.forEach(group => {

              const circle = group.circle as Circle;

              groupsData.push({

                  title: circle.name,
                  subtitle: `Group ${circle.circleId}`,
                  id: circle.circleId
              })
          });

          return groupsData;
      });

      function createNewGroup() {

          window.open(process.env.VUE_APP_CLIENT_MAIN_WEB_APP + `/my-circles/chama`, '_blank');
      }

      function fetchUserGroups() {

          store.dispatch(ActionTypes.FETCH_DATA_FOR_USER_CIRCLES_VIEW, {

              filters: filters.value,
          });
      }

      function LoadGroups() {

          if(! hasFetchedGroups.value) {

              fetchUserGroups();
          }
      }

      function selectedGroup(value:string) {

          selectedGroupId.value = value;
          emit('group-id', value)
      }

      LoadGroups()

      return {
          groups,
          canLoadMore,
          selectedGroup,
          createNewGroup,
          selectedGroupId,
          hasFetchedGroups
      }

  }
})
