

import {computed, defineComponent, PropType} from "vue";
import {useRouter, useRoute} from "vue-router";
export default defineComponent({

  props: {

    route: {

      type: [Object, null] as PropType< Record<string, string|object>>,
      default: null
    },

    text: {

      type: String as PropType<string>,
      default: ""
    }
  },

  setup(props, {emit}) {

    const router = useRouter();
    const route = useRoute();

    function handleClick(e: Event) {

      e.preventDefault();

      if(props.route) {

        router.push(props.route);

        return;
      }

      emit('clicked');
    }

    const linkIsActive = computed( () => {

      if(props.route && route.name){

        return route.name === props.route.name
      }

      return false
    })

    return {

      handleClick,
      linkIsActive
    };
  }
});

