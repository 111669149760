import axios, {AxiosError} from "axios";

export const useErrorUtilities = () => {

    function submissionErrorToString(error: AxiosError | Error): string {

        if(axios.isAxiosError (error)) {

            if (error.response && error.response.data) {

                if (error.response.data.message) {

                    return error.response.data.message;
                }

                if (error.response.data.error) {

                    return error.response.data.error;
                }
            }
        }

        return 'Something went wrong. Please try again.';
    }

    return {

        submissionErrorToString
    };
}
