
import {computed, defineComponent, PropType, ref, watch} from "vue";
import {useStore} from "@/hooks/useStore";
import {ActionTypes} from "@/store/modules/last-expense/types/actions";
import Spinner from "@/views/components/Spinner.vue";
import {ExperienceType, InsuranceType} from "@/store/modules/last-expense/baseModels";
import Insurance from "@/views/pages/home-view/Insurance.vue";
import InsuranceDropdown from "@/views/pages/home-view/InsuranceDropdown.vue";

export default defineComponent({
  components: {InsuranceDropdown, Insurance, Spinner},
  props: {
    showTitle: {

      type: Boolean as PropType<boolean>,
      default: true
    },
    groupId: {
      type: String as PropType<string>,
      required: true
    }

  },
  setup(props) {

    const store = useStore();
    const experienceType = ref<'EVENT' | 'LAST_EXPENSE'>('LAST_EXPENSE');
    const selectedInsurance = ref("");
    const isFetchingExperiences = ref(false);
    const selectedGroupId = ref('');

    const privateExperiences = computed(() => {

      return selectedGroupId.value ? store.getters.getPrivateExperiences(props.groupId) : null;
    });

    const lastExpenseInsurances = computed(() => {

      const allExperiences = privateExperiences.value;

      if(! allExperiences) {

        return null;
      }

      const lastExpenseExperiences = allExperiences.filter(experience =>
          experience.experience.experienceType === ExperienceType.LAST_EXPENSE
      );

      if (lastExpenseExperiences.length) {

        return lastExpenseExperiences.map(experience => experience.experience)
      }

      return null
    });

    const insuranceFilters = computed(() => {

      return InsuranceType
    });

    function selectedOption(selectedInsuranceType:string) {

      selectedInsurance.value = selectedInsuranceType;
    }

    function getInsuranceRoute(experienceId: string) {

      return {
        name: 'last-expense-insurance',
        params: {experienceId: experienceId}
      };
    }

    function fetchExperiences() {

      isFetchingExperiences.value = true;
      store.dispatch(ActionTypes.FETCH_PRIVATE_EXPERIENCES, {
        circleId: props.groupId,
        successCallback:()=> {
          isFetchingExperiences.value = false;
        },
        errorCallback:() => {
          isFetchingExperiences.value = false;
        }
      });
    }

    watch( ()=> props.groupId , (value)=> {

      if(value) {
        selectedGroupId.value = props.groupId
        if(!privateExperiences.value){
          fetchExperiences();
        }
      }
    })

    return {

      InsuranceType,
      experienceType,
      selectedOption,
      selectedGroupId,
      insuranceFilters,
      selectedInsurance,
      getInsuranceRoute,
      privateExperiences,
      isFetchingExperiences,
      lastExpenseInsurances,
    }
  }
});
