import axiosInstance from "@/axios/axios";
import axios, {AxiosResponse} from "axios";

class AllExperiencesService {

    private static baseUrl = `${process.env.VUE_APP_BASE_URL}/experiences/v1/experience`;
    private static instance: AllExperiencesService;
    private readonly axiosInstance = axiosInstance;

    static singleton = (): AllExperiencesService => {

        if (!AllExperiencesService.instance) {

            AllExperiencesService.instance = new AllExperiencesService();
        }

        return AllExperiencesService.instance;
    };

    async fetchNonPagedActiveExperiences(): Promise<AxiosResponse> {

        const response = await this.fetchPagedActiveExperiences(1, 1,);

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedActiveExperiences(
            1, response.data.totalPages
        );
    }

    async fetchPagedActiveExperiences(
        page: number, size: number
    ): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllExperiencesService.baseUrl +
            `/experiences`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchNonPagedPrivateExperiences(circleId: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedPrivateExperiences(circleId, 1, 1,);

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedPrivateExperiences(circleId,
            1, response.data.totalPages
        );
    }

    async fetchPagedPrivateExperiences(circleId: string, page: number, size: number): Promise<AxiosResponse> {

        const response = await this.axiosInstance.get(
            AllExperiencesService.baseUrl + `/audience/circle/${circleId}`,

            {params: {page: page - 1, size}
            }
        );

        response.data.page++;

        return response;
    }

    async fetchExperience(experienceId: string): Promise<AxiosResponse> {

        return await this.axiosInstance.get(
            AllExperiencesService.baseUrl + `/${experienceId}`
        );
    }

    async fetchNonPagedActiveExperiencesWithoutAuth(clientAccessToken: string): Promise<AxiosResponse> {

        const response = await this.fetchPagedExperiencesWithoutAuth(1, 1, clientAccessToken );

        if(response.data.totalPages <= 1) {

            return response;
        }

        return await this.fetchPagedExperiencesWithoutAuth(
            1, response.data.totalPages, clientAccessToken
        );
    }

    async fetchPagedExperiencesWithoutAuth(page: number, size: number, clientAccessToken: string ): Promise<AxiosResponse> {


        const response =  await axios.get(
            AllExperiencesService.baseUrl + `/experiences`,
            {
                params: {
                    page: page - 1, size
                },
                headers: {Authorization: `Bearer ${clientAccessToken}`}
            });

        response.data.page++;

        return response;
    }

    async fetchExperienceWithoutAuth(experienceId: string, clientAccessToken: string): Promise<AxiosResponse> {

        return await axios.get(
            AllExperiencesService.baseUrl + `/${experienceId}`,
            {
                headers: {Authorization: `Bearer ${clientAccessToken}`}
            });
    }

}

export default AllExperiencesService;
