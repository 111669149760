import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tw-p-6" }
const _hoisted_2 = { class: "tw-font-bold" }
const _hoisted_3 = { class: "tw-pt-2" }
const _hoisted_4 = { class: "tw-text-slate-600" }
const _hoisted_5 = { class: "tw-pt-18 d-flex align-items-center justify-content-end" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_submission_error_handler = _resolveComponent("submission-error-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.body), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.secondaryAction)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["mr-2 btn btn-link align-items-center tw-text-slate-600 shadow-none", [ _ctx.isLoading ?'text-muted' : '']]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSecondaryAction && _ctx.handleSecondaryAction(...args))),
            disabled: _ctx.isLoading
          }, _toDisplayString(_ctx.isLoading ? 'Please wait..' : _ctx.secondaryAction), 11, _hoisted_6))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: _normalizeClass(["btn mx-0 btn-primary d-flex align-items-center justify-content-center", { 'spinner spinner-white': _ctx.isLoading}]),
        style: {"padding-left":"30px","padding-right":"30px","height":"46px"},
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePrimaryAction && _ctx.handlePrimaryAction(...args)))
      }, _toDisplayString(_ctx.primaryAction), 3)
    ]),
    _createVNode(_component_submission_error_handler, { error: _ctx.error }, null, 8, ["error"])
  ]))
}