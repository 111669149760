import router from "@/router";
import {Store} from "@/store";
import {ActionTypes} from "@/store/modules/authorization/types";
import axios from "axios";
import {RouteLocationNormalized} from "vue-router";

const axiosInstance = axios.create();

let currentRoute: RouteLocationNormalized | null = null;

let store: Store | null = null;
let accessToken: string | null = null;
let refreshToken: string | null = null;
let refreshTokenRequest: Promise<{
    accessToken: string;
    refreshToken: string;
}> | null = null;

export function axiosSetupVuexPlugin (vuexStore: Store):void {

    store = vuexStore;

    store.watch(state => state.authModule.authorization.accessToken,
        value => { accessToken = value; },
        {immediate: true});

    store.watch(state => state.authModule.authorization.refreshToken,
        value => {refreshToken = value; },
        {immediate: true});
}

function attemptToRefreshTokens(store: Store) {

    if(refreshTokenRequest) {

        return refreshTokenRequest;
    }

    refreshTokenRequest = new Promise((resolve, reject) => {

        store.dispatch(ActionTypes.REFRESH_AUTH_TOKENS, {

            refreshToken: refreshToken ? refreshToken : '',
            successCallback: (tokens) => {

                refreshTokenRequest = null;
                return resolve(tokens);
            },

            errorCallback: (error) => {

                refreshTokenRequest = null;
                return reject(error);
            }
        });
    });

    return refreshTokenRequest;
}

function handleAuthenticationError() {

    store?.dispatch(ActionTypes.LOGOUT_USER);

    if(currentRoute && ! currentRoute.meta.requiresGuest) {

        localStorage.setItem('previousRoute', currentRoute.fullPath)

        router.push({name: 'authenticate'});
    }
}

axiosInstance.interceptors.request.use(
    (config) => {

        // Store the current route value to retain it should
        // the route change during the request. This ensures we
        // pass the correct 'restoration url' to the authentication page on 401.
        currentRoute = router.currentRoute.value;

        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;

    }, function (error) {

        return Promise.reject(error);
    });

axiosInstance.interceptors.response.use(

    function (response) {

        return response;

    }, function (error) {

        if (error.response && error.response.status == 401) {

            if(error.response.config &&
                error.response.config.url !== process.env.VUE_APP_ACCESS_TOKEN_URI &&
                store && (refreshToken || refreshTokenRequest)) {

                return attemptToRefreshTokens(store).then(() => {

                    return axiosInstance.request(error.config);

                }).catch(error => {

                    handleAuthenticationError();
                    return Promise.reject(error);
                });
            }

            handleAuthenticationError();
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
