import {useDateUtilities} from "@/hooks/useDateUtilities";
import {Member} from "@/store/modules/circles/types/models";
import {
    BeneficiaryType, LastExpenseClaimStatus,
    LastExpenseBeneficiary, LastExpenseCircleDetails, LastExpenseClaim, LastExpenseExperience,
    LastExpensePlan,
    LastExpensePlanStatus,
    LastExpensePolicy,
    LastExpensePolicyOwner, LastExpensePolicyPayment,
    LastExpensePolicyStatus,
    LastExpensePolicyType, PolicyPaymentStatus, LastExpenseClaimPayment, ClaimPaymentStatus,
} from "@/store/modules/last-expense/types/models";
import {
    Experience,
    ExperienceStatus,
    ExperienceType,
    PrivateExperience
} from "@/store/modules/last-expense/baseModels";
import {RawOrganisation, mappers as baseMappers} from "@/store/baseMappers";

const { mapToTypedOrganisation } = baseMappers();

export type RawExperience = Omit<Experience,
    "createdOn" | "updatedOn" | "experienceStatus" |
    "experienceType"> & {

    createdOn: string;
    updatedOn: string;
    experienceStatus: string;
    experienceType: string;
}

export type RawPrivateExperience = Omit<PrivateExperience,
    "createdOn" | "updatedOn" | "experience" | "organisation"> & {

    createdOn: string;
    updatedOn: string;
    experience: RawExperience;
    organisation: RawOrganisation;
}

export type RawLastExpenseExperience = RawExperience & {

    insuranceProvider: string;
}

export type RawLastExpensePolicy = {

    createdOn: string;
    updatedOn: string;
    lastExpensePolicyId: string;
    circleId: string;
    memberId: string;
    policyStatus: string;
    policyType: string;
    lastExpensePlan: RawLastExpensePlan;
    owner: RawLastExpensePolicyOwner;
    circleDetails: RawLastExpenseCircleDetails | null;
    memberDetails: Member;
}

export type RawLastExpenseCircleDetails = LastExpenseCircleDetails;

export type RawLastExpensePlan = Omit<LastExpensePlan,
    "createdOn" | "updatedOn" | "planStatus" | "experience"> & {

    createdOn: string;
    updatedOn: string;
    planStatus: string;
    experience: RawExperience;
}

export type RawLastExpensePolicyOwner = Omit<LastExpensePolicyOwner,
    "createdOn" | "updatedOn"> & {

    createdOn: string;
    updatedOn: string;
}

export type RawLastExpenseBeneficiary = Omit<LastExpenseBeneficiary,
    "createdOn" | "updatedOn" | "beneficiaryType"> & {

    createdOn: string;
    updatedOn: string;
    beneficiaryType: string;
}

export type RawLastExpensePolicyPayment = Omit<LastExpensePolicyPayment,
    "createdOn" | "updatedOn" | "policyPaymentStatus"> & {

    createdOn: string;
    updatedOn: string;
    policyPaymentStatus: string;
}

export type RawLastExpenseClaim = {

    createdOn: string;
    updatedOn: string;
    lastExpenseClaimId: string;
    claimStatus: string;
    initiatorUserId: string;
    lastExpensePolicy: RawLastExpensePolicy;
    initiator: Member;
}

export type RawLastExpenseClaimPayment = Omit<LastExpenseClaimPayment,
    "createdOn" | "updatedOn" | "claimPaymentStatus"> & {

    createdOn: string;
    updatedOn: string;
    claimPaymentStatus: string;
}

const {
    parseServerDateTime,
    stripTimezoneFromISODateTime,
    parseServerWithNormalDateTime
} = useDateUtilities();

export const mappers = () => {


    const mapToTypedExperience = (experience: RawExperience): Experience => {

        const {
            createdOn, updatedOn, experienceStatus, experienceType, ...otherProps
        } = experience;

        return {

            createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
            updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
            experienceStatus: experienceStatus as ExperienceStatus,
            experienceType: experienceType as ExperienceType,
            ...otherProps
        };
    }
    const mapToTypedPrivateExperience = (rawExperience: RawPrivateExperience): PrivateExperience => {

        const {
            createdOn, updatedOn, experience, organisation, ...otherProps
        } = rawExperience;

        return {

            createdOn: parseServerDateTime(stripTimezoneFromISODateTime(createdOn)),
            updatedOn: parseServerDateTime(stripTimezoneFromISODateTime(updatedOn)),
            experience: mapToTypedExperience(experience),
            organisation: mapToTypedOrganisation(organisation),
            ...otherProps
        };
    }

    const mapToTypedLastExpenseExperience =
        (lastExpenseExperience: RawLastExpenseExperience): LastExpenseExperience => {

        const { insuranceProvider, ...rawExperience } = lastExpenseExperience;

        return {

            insuranceProvider,
            ...mapToTypedExperience(rawExperience)
        }
    }

    const mapToTypedLastExpensePolicy =
        (policy: RawLastExpensePolicy): LastExpensePolicy => {

        const {
            createdOn, updatedOn, policyStatus, policyType,
            lastExpensePlan, owner, circleDetails, ...otherProps
        } = policy;

        return {

            createdOn: parseServerWithNormalDateTime(
                stripTimezoneFromISODateTime(createdOn)
            ),
            updatedOn: parseServerWithNormalDateTime(
                stripTimezoneFromISODateTime(updatedOn)
            ),
            policyStatus: policyStatus as LastExpensePolicyStatus,
            policyType: policyType as LastExpensePolicyType,
            lastExpensePlan: mapToTypedLastExpensePlan(lastExpensePlan),
            owner: mapToTypedLastExpensePolicyOwner(owner),
            circleDetails: circleDetails ? circleDetails : null,
            ...otherProps
        };
    }

    const mapToTypedLastExpensePlan = (plan: RawLastExpensePlan): LastExpensePlan => {

        const { createdOn, updatedOn, planStatus, experience, ...otherProps} = plan;

        return {

            createdOn: parseServerWithNormalDateTime(
                stripTimezoneFromISODateTime(createdOn)
            ),
            updatedOn: parseServerWithNormalDateTime(
                stripTimezoneFromISODateTime(updatedOn)
            ),
            planStatus: planStatus as LastExpensePlanStatus,
            experience: mapToTypedExperience(experience),
            ...otherProps
        }
    }

    const mapToTypedLastExpensePolicyOwner =
        (policyOwner: RawLastExpensePolicyOwner): LastExpensePolicyOwner => {

        const { createdOn, updatedOn, ...otherProps} = policyOwner;

        return {

            createdOn: parseServerWithNormalDateTime(
                stripTimezoneFromISODateTime(createdOn)
            ),
            updatedOn: parseServerWithNormalDateTime(
                stripTimezoneFromISODateTime(updatedOn)
            ),
            ...otherProps
        }
    }

    const mapToTypedLastExpenseBeneficiary =
        (beneficiary: RawLastExpenseBeneficiary): LastExpenseBeneficiary => {

            const { createdOn, updatedOn, beneficiaryType, ...otherProps } = beneficiary;

            return {

                createdOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(createdOn)
                ),
                updatedOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(updatedOn)
                ),
                beneficiaryType: beneficiaryType as BeneficiaryType,
                ...otherProps
            }
        }

    const mapToTypedLastExpensePolicyPayment =
        (payment: RawLastExpensePolicyPayment): LastExpensePolicyPayment => {

            const { createdOn, updatedOn, policyPaymentStatus, ...otherProps } = payment;

            return {

                createdOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(createdOn)
                ),
                updatedOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(updatedOn)
                ),
                policyPaymentStatus: policyPaymentStatus as PolicyPaymentStatus,
                ...otherProps
            }
        }

    const mapToTypedLastExpenseClaim =
        (claim: RawLastExpenseClaim): LastExpenseClaim => {

            const {
                createdOn, updatedOn, claimStatus, lastExpensePolicy,
                ...otherProps
            } = claim;

            return {

                createdOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(createdOn)
                ),
                updatedOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(updatedOn)
                ),
                claimStatus: claimStatus as LastExpenseClaimStatus,
                lastExpensePolicy: mapToTypedLastExpensePolicy(lastExpensePolicy),
                ...otherProps
            };
        }

    const mapToTypedLastExpenseClaimPayment =
        (payment: RawLastExpenseClaimPayment): LastExpenseClaimPayment => {

            const { createdOn, updatedOn, claimPaymentStatus, ...otherProps } = payment;

            return {

                createdOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(createdOn)
                ),
                updatedOn: parseServerWithNormalDateTime(
                    stripTimezoneFromISODateTime(updatedOn)
                ),
                claimPaymentStatus: claimPaymentStatus as ClaimPaymentStatus,
                ...otherProps
            }
        }

    return {

        mapToTypedExperience,
        mapToTypedLastExpensePlan,
        mapToTypedLastExpenseClaim,
        mapToTypedLastExpensePolicy,
        mapToTypedPrivateExperience,
        mapToTypedLastExpenseExperience,
        mapToTypedLastExpenseBeneficiary,
        mapToTypedLastExpensePolicyOwner,
        mapToTypedLastExpenseClaimPayment,
        mapToTypedLastExpensePolicyPayment,
    }
}
